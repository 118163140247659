/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Box, Grid } from '@mui/material';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import chatSvg from "../../images/homepage/chatIcon.png"
import logo from "../../images/homepage/logo.png"
import RedboxLogo from './../../images/newHomePage/redbox-logo.png';
import facebookWhite from "../../images/homepage/facebook_white.png"
import twitterWhite from "../../images/homepage/twitter_white.png"
import TZUR from "../../images/homepage/TZUR.png"
import '../LandingPage/LandingPage.scss';
import mainBanner from '../../images/compareBanner.jpg';
import compare1 from '../../images/compare1.png';
import compare2 from '../../images/compare2.png';

export const Compare = ({ setIsLogin, setIsRegister }) => {
    const { userInfo } = useSelector(state => state.user);
    const navigate = useNavigate();
    const { snackbar } = useSelector(state => state.snackbar);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }
    const goTo = (e) => {
    }
    useEffect(() => {

    }, [userInfo]);

    const onSnackbarHandleClose = () => { }

    return (
        <Grid lg={12} container justifyContent="center" style={{ direction: "rtl", fontSize: "20px", fontFamily: "Rubik" }}>
            <Grid lg={12} container style={{ maxWidth: "1080px", margin: "20px" }}>
                <Grid lg={12} container item flexDirection="column" alignContent="center" style={{ color: "#d70007", marginBottom: "70px" }} >
                    <h1 style={{ fontSize:"45px" }}><b>redbox</b> p a r c e l</h1>
                    <h1 style={{ fontSize: "45px" }}>השוואת מחירים</h1>
                </Grid>
                <Grid lg={12} container item style={{}}>
                    <p>Red Box Parcel מספקת שירות משלוחים הכולל קבלת כתובות בחו"ל ושילוח עד הבית עם התחייבות למחיר הזול בישראל.&nbsp;</p>
                    <p>רק בשירות המשלוחים של רד בוקס, כל מחירי השילוח מחו"ל ידועים מראש לצרכן, בזכות מדרגות המשקל בחברה המוגדרות במאות גרם ולא בק"ג. עלות המוצרים מותאמת למשקל האמיתי של החבילה כפי שנמדדה ונשקלה במחסני החברה הפרוסים באירופה וארה"ב.</p>
                    <p>כדי לחשב את מחיר החבילה שלכם – כנסו עכשיו <a href="https://redboxparcel.com/redboxPricing">למחשבון שלנו</a>.</p>
                </Grid>
                <Grid lg={12} container item style={{}}>
                    <img src={mainBanner}  style={{ width: "100%" }} />
                </Grid>
                <Grid md={12} container item justifyContent="center" style={{}}>
                    <Grid md={12} container item justifyContent="center" style={{ maxWidth: "819px" }}>
                        <Grid md={6} container item style={{ textAlign: "center" }} alignContent="flex-start">
                            <Grid container item justifyContent="center" style={{ textAlign: "center", padding: "20px" }} >
                                <h2 style={{ color: "#d70007" }} > {`השוואת מחירי משלוח`} <br /> {`חבילות מארה"ב`}</h2>
                            </Grid>
                            <Grid container item justifyContent="center">
                                <img src={compare1} style={{ height: "280px" }} />
                            </Grid>
                        </Grid>
                        <Grid md={6} container item alignContent="flex-start">
                            <Grid container item justifyContent="center" style={{ textAlign: "center", padding: "20px" }}>
                                <h2 style={{ color: "#d70007" }}> {`היחידים ששולחים מאירופה`} <br /> {`(ממדינות האיחוד האירופי)`}</h2>
                            
                            </Grid>
                            <Grid container item justifyContent="center">
                                <img src={compare2} style={{ height: "280px" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid lg={12} container item flexDirection="column" alignContent="flex-start" >
                    <p>כדי לחשב את מחיר החבילה שלכם – כנסו עכשיו <a href="https://redboxparcel.com/redboxPricing">למחשבון שלנו</a>.<br />כדי לקבל כתובת בחו"ל ולהתחיל להזמין מוצרים בזול – <a onClick={() => {

                        if (userInfo.session) {
                            navigate("/accounts");
                        } else {
                            setIsLogin(true)
                        }

                    }}>הירשמו עכשיו</a>.
                    </p>
                </Grid>
                

            </Grid>
        </Grid>
    );
}

export default Compare;