/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid';
import { Button, InputLabel, RadioGroup, Radio, Table, TableBody, TableContainer, Paper, Grid, Checkbox, FormControlLabel, Box } from '@mui/material';
import { TextField, SelectField, Dialog } from '../../style-guide';
import './Personal.scss';
import { setCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setSelectedShipping } from '../../reducers/user-reducers/UserSlicer';
import { snackbarToggle, snackbarAutoClose } from '../../reducers/snackbarSlicer';
import { FaWindowClose } from "react-icons/fa";
import { getShipmentCost, getBase64RecieptList, deleteReciept, getCouponValue1, isValidCoupon, getCouponList, validateHebrewText, validatePhone, listWarehouse, getExchangeRate, saveProfile, cardTextFromNumber, cardFromType, checkCvvAndNumber, cardFromNumber, validateMonth, validateYear, validateCardNumber, validateDigits, validateMyId, getCityStreetNcode, getZigZagAddresses, registerUser, ping, validateEmail, copyToClipboard1, shipping_upload, shippingDelivaryRequestPay, shippingDelivaryRequestPayWithToken, setUserData, getETAStatus } from '../../reducers/requestHandler';
import { settingsRedbox, weightAndVal, cc_months, cc_years, shippingCoPoints } from '../../constants/global-constants';
import CloseIcon from '@mui/icons-material/Close';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import copy from '../../images/copy.png'
import upload from '../../images/upload.png'
import file from '../../images/file.png'
import del from '../../images/del.jpg'
import RedBoxBg from '../../images/box-background-img.png'
import CcCardImg from '../../images/cc-img-center.png'

import questionMark from '../../images/question-mark.png'
import checkMarkCopy from '../../images/check-mark-copy.png'
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import Loader from '../../style-guide/Loader';
import ActionButton from '../Common/ActionButton';
import DoneIcon from '@mui/icons-material/Done';
import { FaCheckCircle, FaCheck, FaWifi, } from "react-icons/fa";
import ClearIcon from '@mui/icons-material/Clear';
import { chWtMultiplier } from '../../constants/global-constants';
const screens = [{ id: 1, screen: 1, title: 'העלאת חשבונית' }, { id: 2, screen: 2, title: 'השלמת פרטי משלוח' }, { id: 3, screen: 3, title: 'תשלום' }];
const screensMulti = [{ id: 1, screen: 1, title: 'העלאת חשבוניות' }, { id: 2, screen: 2, title: 'השלמת פרטי משלוח' }, { id: 3, screen: 3, title: 'תשלום' }];
const months = [{ id: 1, name: "חודש" }];
const sleeps = [{ id: 1, name: "שנה" }];
const currencies = [{ id: 1, name: "$ דולר", symbol: "$" }, { id: 2, name: "€ אירו", symbol: "€" }, { id: 3, name: "£ פאונד", symbol: "£" }, { id: 4, name: "zł זלוטי", symbol: "zł" }, { id: 5, name: "C$ דולר קנדי", symbol: "C$" }];
const otpArr = [{ id: 1, val: "1" }, { id: 2, val: "2" }, { id: 3, val: "3" }, { id: 4, val: "4" }, { id: 5, val: "5" }, { id: 6, val: "6" }];
const personals = [{
    id: 1, val: "1", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874"
}, {
    id: 2, val: "2", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874"
}, { id: 3, val: "3", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" }];

const data = {
    cirtificateList: [{ id: 1, name: "כן" }, { id: 2, name:"לא"}],
    selectedCurrency: currencies[0].id,
    selectedMonth: 0,//cc_months[0].id,
    selectedYear: 0,//cc_years[0].id,
    certificateId: 1,
    costOfItem: 0,
    totalCost: 0,
    invoiceOriginal: 0,
    orgTotalCost: 0,
    totalInvoice: 0,
    weight: 0,
    transportVal: 0,
    cif: 0,
    cD: 0,
    customsCharge: 0,
    insurence: 0,
    purchaseTax: 0,
    vat: 0,
    isCertificate: true,
    isInsurence: true,
    homeDelivary: true,
    mycvv: '',
    ccno: '',
    cctype: '',
    myid: '',
    cc_name: '',
    profileId: '',
    otp: '',
    nickName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    hpAuth: '',
    isCoupon: false,
    payByToken: false,
    rememberPayment: true,
    isTnc: false,
    countryCode: '',
    addressLine_1: '',
    addressLine_2: '',
    state: '',
    city: '',
    couponText: '',
    postal: '',
    cityId: 0,
    streetId: 0,
    buildingId: 0,
    ncodeId: 0,
    addCharges: 0,
    cityNum: '',
    addName: '',
    addPhone: '',
    buildingNum: '',
    ncodeNum: '',
    streetNum: '',
}

export const Personal = (props) => {
    const { isPersonal, setIsPersonal, shipping, get_shipping_list_count, calculateValues } = props;
    const { userInfo, selectedShipping } = useSelector(state => state.user);
    const [couponList, setCouponList] = new useState([]);
    const [wareHouses, setWareHouses] = new useState([]);
    const [value, setValue] = useState(data);
    const [isInsurenceDisabled, setInsurenceDisabled] = useState(false);
    const [inValidMyId, setInValidMyId] = useState(false);
    const [inValidCard, setInValidCard] = useState(false);
    const [inValidCcName, setinValidCcName] = useState(false);
    const [activeScreen, setActiveScreen] = useState(1);
    const [uploadedPdfFileName, setUploadedPdfFileName] = useState("");
    const [uploadedPdf, setUploadedPdf] = useState("");
    const [uploadedPdfBse64, setUploadedPdfBse64] = useState({});
    const [uploadedPdfBse64Id, setUploadedPdfBse64Id] = useState({});
    const [paymentValidation, setPaymentValidation] = useState({
        bYear: true,
        bMonth: true,
        bCC: true,
        bCVV: true,
        bId: true,
    });


    const getTotalHmdCond = () => {
        
        var wt = Number(selectedShipping.params?.userPaymentData?.weight);
        var h = Number(selectedShipping.params?.userPaymentData?.height);
        var l = Number(selectedShipping.params?.userPaymentData ? selectedShipping.params?.userPaymentData["length"]:0);
        var w = Number(selectedShipping.params?.userPaymentData?.width);

        var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;

        var twt = Number(chargWt > wt ? chargWt : wt);
        if (twt > 4) return false;
        return true;
    }

    const getStatusById = (id) => {
        if (selectedShipping.parentId == 0) {
            if (id == 1) {
                return true;
            }
            if (id == 2) {

                for (var i = 0; i < selectedShipping?.params?.statusLog?.length; i++) {
                    if (selectedShipping?.params?.statusLog[i].id == id) {
                        return selectedShipping?.params?.statusLog[i].DATE ? selectedShipping?.params?.statusLog[i].DATE : false;
                    }
                }
                return true;
            }
            if (id == 3) {
                if (selectedShipping?.params?.shippingStatus)
                    return true;
                return false;
            }
        }
        for (var i = 0; i < selectedShipping?.params?.statusLog?.length; i++) {
            if (selectedShipping?.params?.statusLog[i].id == id) {
                return selectedShipping?.params?.statusLog[i].DATE;
            }
        }
        return false;
    }

    const getDateFormat = (e) => {
        var date = new Date(e);
        var dat = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
        var month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));
        var date_formated = dat + '/' + month + '/' + date.getFullYear().toString().slice(-2) + "  " + date.getHours() + ":" + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes());
        return date_formated
    }
    const [bIsra, setBIsra] = useState(false);
    const [isPreviewPdf, setIsPreviewPdf] = useState(false);
    const [personalsList, setpersonalsList] = useState(personals);
    const [isBusiness, setIsBusiness] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const onDropFiles = useCallback(acceptedFiles => {


    }, [])

    const getTotalInvoiceVal = function () {
        var totalCost = value?.totalCost;

        return totalCost ? totalCost : 0;
    }
    const areValidDimentions = function () {
        if ((Number(selectedShipping?.params?.userPaymentData.height) > 250 || Number(selectedShipping?.params?.userPaymentData["length"]) > 250 || Number(selectedShipping?.params?.userPaymentData.width) > 250)) {
            return true;
        }/**/
        return false;
    }
    const isValidTotalCost = function () {
        var totalCost = value.totalCost;
        if (value.selectedCurrency == 2) {
            //totalCost = totalCost * 1.03;
        }
        if (getConvertedCostByCurToUsd_UI(value.selectedCurrency, totalCost) > 5000) {
            return true;
        }/**/
        return false;
    }
    const checkFiles = function () {
        if (uploadedOrgFiles?.length >= selectedShipping?.trackListOfConsolidation?.length && selectedShipping?.consolidationStatus == 3) {
           // dispatch(snackbarToggle({ type: 'error', message: `All files are uploaded.` }));
            return true;
        }
        return false;
    }
    const checkWeight = function () {

        // return Number(selectedShipping?.params?.userPaymentData.weight) > 10 || (Number(selectedShipping?.params?.userPaymentData.width) * Number(selectedShipping?.params?.userPaymentData.height) * Number(selectedShipping?.params?.userPaymentData["length"]) / 1000000) > 0.02
        var chargWt = Number(selectedShipping?.params?.userPaymentData.height) * Number(selectedShipping?.params?.userPaymentData["length"]) * Number(selectedShipping?.params?.userPaymentData.width) * chWtMultiplier;

        if (Number(selectedShipping?.params?.userPaymentData.weight) > 4)
            return true;
        /*var maxH = 0, midH = 0, lowH = 0;
        if (Number(selectedShipping?.params?.userPaymentData.height) > maxH) {
            maxH = Number(selectedShipping?.params?.userPaymentData.height);
        }
        if (Number(selectedShipping?.params?.userPaymentData["length"]) > maxH) {
            maxH = Number(selectedShipping?.params?.userPaymentData["length"]);
        }
        if (Number(selectedShipping?.params?.userPaymentData.width) > maxH) {
            maxH = Number(selectedShipping?.params?.userPaymentData.width);
        }
        lowH = Number(selectedShipping?.params?.userPaymentData.height);
        var nums = [Number(selectedShipping?.params?.userPaymentData.height),
            Number(selectedShipping?.params?.userPaymentData.width),
            Number(selectedShipping?.params?.userPaymentData["length"])];
        if (Number(selectedShipping?.params?.userPaymentData["length"]) < lowH) {
            lowH = Number(selectedShipping?.params?.userPaymentData["length"]);
        }
        if (Number(selectedShipping?.params?.userPaymentData.width) < lowH) {
            lowH = Number(selectedShipping?.params?.userPaymentData.width);
        }
        const index1 = nums.indexOf(maxH);
        if (index1 > -1) {
            nums.splice(index1, 1);
        }
        const index2 = nums.indexOf(lowH);
        if (index2 > -1) {
            nums.splice(index2, 1);
        }
        midH = nums[0];

        if (maxH > 40) {
            return true;
        }
        if (midH > 30) {
            return true;
        }
        if (lowH > 20) {
            return true;
        }*/
        if (Number(selectedShipping?.params?.userPaymentData.width) > 40 ||
            Number(selectedShipping?.params?.userPaymentData["length"]) > 40 ||
            Number(selectedShipping?.params?.userPaymentData.height) > 40)
            return true;
        return false;
    }
    const onDrop = useCallback(acceptedFiles => {
        
        setIsFileUploaded(false)
        var formData = new FormData();
        var bUpload = true;
        acceptedFiles.forEach((file) => {

            var fileSize = Math.round((file.size / 1024));
           
            if (fileSize <= 2 * 1024) {
               // alert("Uploaded");
            } else {
               
               // return;
                bUpload = false;
            }

            if (bUpload) {
                formData.append("file", file, file.name);
                setUploadedPdfFileName(file.name)
                var url = URL.createObjectURL(file);
                setUploadedPdf(url)
                const reader = new FileReader()

                reader.onabort = () => {
                    dispatch(snackbarToggle({ type: 'error', message: `Not a proper file` }));
                    return;
                }
                reader.onerror = () => {
                    dispatch(snackbarToggle({ type: 'error', message: `Not a proper file` }));
                    return;
                }
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    const binaryStr = reader.result

                }
                reader.readAsArrayBuffer(file)
            }
        })

        if (bUpload) {

            formData.append("shippingReqId", parseInt(selectedShipping?.id));
            formData.append("reportProgress", true);
            shipping_upload(formData, function (response) {
                console.log(response)

                if (response?.misc?.CODE == 2) {
                    dispatch(snackbarToggle({ type: 'error', message: "Upload Proper file" }));
                    setIsPreviewPdf(false)
                    setUploadedPdfFileName("")
                    setUploadedPdf(null)
                    setIsFileUploaded(true)
                } else {
                    setIsFileUploaded(true)
                    setUploadedOrgFiles(response?.data?.params?.userData?.reciept_org_names ? response?.data?.params?.userData?.reciept_org_names?.split(",") : [])
                    getBase64Reciepts()
                }
            });
        } else {
            setIsFileUploaded(true)
            dispatch(snackbarToggle({ type: 'error', message: `ניתן להעלות קבצים מסוג JPEG, PNG, BMP, PDF עד גודל 2MB` }));
        }


    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/bmp': ['.bmp'],
            'application/pdf': ['.pdf']

        }, onDrop
    })

    const copyToClipboard = (text) => {
        copyToClipboard1(text, function (data) {
            dispatch(snackbarToggle({ type: 'success', message: data }));

            window.setTimeout(function () { dispatch(snackbarToggle(false)); }, 2000);
        })
    }

    const getDimensions = () => {
        return selectedShipping.params?.userPaymentData ? (selectedShipping.params?.userPaymentData?.height + "x" + selectedShipping.params?.userPaymentData["length"] + "x" + selectedShipping.params?.userPaymentData?.width) : "0x0x0"
    }
    const getChWeight = () => {
        var wt = Number(selectedShipping.params?.userPaymentData?.weight);
        var h = Number(selectedShipping.params?.userPaymentData?.height);
        var l = Number(selectedShipping.params?.userPaymentData ? selectedShipping.params?.userPaymentData["length"] : 0);
        var w = Number(selectedShipping.params?.userPaymentData?.width);

        var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;

        return `${Number(chargWt > wt ? chargWt : wt).toFixed(2)}KG`;
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            if (!value.totalCost) {
                dispatch(snackbarToggle({ type: 'error', message: `Please Enter Total Cost.` }));
                return
            }

            if (!value.category) {
                dispatch(snackbarToggle({ type: 'error', message: `Please Select Category.` }));
                return
            }
            if (!value.subcategory) {
                dispatch(snackbarToggle({ type: 'error', message: `Please Select Subcategory.` }));
                return
            }
            if (!value.isTnc) {
                dispatch(snackbarToggle({ type: 'error', message: `Please accept declaration.` }));
                return
            }
            calculate(value.subcategory - 1, value.couponText, couponValue);

            if (value.certificateId == 1) {
            } else {

                setValue(prev => ({ ...prev, totalInvoice: value.totalInvoice - value.fixedFee }))
                setValue(prev => ({ ...prev, fixedFee: 0 }))
            }
            setIsPreviewPdf(false)
            setActiveScreen(screens[2].screen)
        }
    }
    const handleUserData = () => {


        var u = JSON.parse(JSON.stringify(userInfo.user))
        var pickupPoint = {};
        /*var pickupPointPer = {
            Street: u.setting.register_addressLine_1,
            House: u.setting.register_addressLine_2,
            City: u.setting.register_city,
            ncode: u.setting.register_postal
        };*/
        if (value.homeDelivary) {
            pickupPoint = {
                City: value.cityNum,
                Name: value.addName,
                Phone: value.addPhone,
                Street: value.streetNum,
                House: value.buildingNum,
                ncode: value.ncodeNum,
            }
        } else {
            if (!pickupPoint) {
                pickupPoint = {}
            }
            pickupPoint = {
                City: cities.find(u => u.id === value.cityId)?.name,
                Street: streets.find(u => u.id === value.streetId)?.name,
                House: buildings.find(u => u.id === value.buildingId)?.name,
                ncode: streets.find(u => u.id === value.streetId)?.ncode,
            }
        }
        var d = {
            "deliveryMethod": "1",
            "deliveryInstruction": "del inst",
            "cost": value.totalCost,
            "cur": value.selectedCurrency,
            "opcode": "setUserData",
            "sessionId": userInfo.session,
            "cargoType": "1",
            "pickupPoint": pickupPoint,
            "shippingReqId": selectedShipping?.id
        }
        setUserData(d, function () {

        });
    }
    const saveProfileData = (screen = '') => {

        var u = JSON.parse(JSON.stringify(userInfo.user))
        u.sessionId = userInfo.session;
        u.setting.profileId = value.profileId;
        console.log(u)
        saveProfile(u, function (res) {
            //alert(res.message)
            ping(userInfo.session, function (response) {
                if (response.misc.CODE === 1) {
                    setCookie("SESSION_ID", response.session, 30);
                    dispatch(getSession(response))
                } else {
                    //
                }
            })
        });
    }
    const handleScreenData = (screen = '') => {
        if (screen === screens[0].screen) {
            // data handle for Registeration
        } else if (screen === screens[1].screen) {
            // data handle for Password            
        } else if (screen === screens[2].screen) {
            //register_user();
            // data handle for User activation 
        }
        screen && setActiveScreen(screen)
    }


    //console.log('data', value);
    const [tabValue, setTabValue] = useState(1);


    const shipping_pay = () => {
        if (!userInfo.user.setting?.profileId) {
            saveProfileData();
        }
        var mul = 3.25;
        if (exchangeRate.rates) {
            mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.USD
        }
        if (!mul) {
            mul = 3.25;
        }
        var tVal = value.transportVal;
        /*if (selectedShipping?.params?.calculatedBasePrice) {
            tVal = selectedShipping?.params?.calculatedBasePrice
        }*/
        var data1 = {
            "sessionId": userInfo.session,
            "currency": value.selectedCurrency,
            // "invoiceOriginal": value.invoiceOriginal,
            "invoiceOriginal": getConvertedCostByCurToUsd(value.selectedCurrency, value.totalCost),
            "expdate": "" + cc_months.find(u => u.id === value.selectedMonth)?.name + "" + cc_years.find(u => u.id === value.selectedYear)?.name,
            "myid": value.myid,
            "mycvv": value.mycvv,
            "cc_name": value.cc_name,
            "ccno": value.ccno,
            "amount": getTotal(),
            "amount_nis": Number(getTotal() * mul).toFixed(2),
            "couponText": value.couponText,
            "exchange_rate": mul,
            "invoiceValue": value.totalCost,
            "invoiceValue_nis": Number(value.totalCost * mul).toFixed(2),
            "costSurcharge": value.costSurcharge,
            "costSurcharge_nis": Number(value.costSurcharge * mul).toFixed(2),
            "isInsurence": value.isInsurence,
            "homeDelivary": value.homeDelivary,
            "homeDelivary_nis": Number(value.homeDelivary ? (4 * mul) : 0).toFixed(2),
            "transportVal": tVal,
            "transportVal_nis": Number(tVal * mul).toFixed(2),
            "orgTotalCost": value.orgTotalCost,
            "orgTotalCost_nis": Number(value.orgTotalCost * mul).toFixed(2),
            "totalInvoice": value.totalInvoice,
            "totalInvoice_nis": Number(value.totalInvoice * mul).toFixed(2),
            "repackCost": value.repackCost,
            "repackCost_nis": Number(value.repackCost * mul).toFixed(2),
            "couponDeduction": value.cD,
            "couponDeduction_nis": Number(value.cD * mul).toFixed(2),
            "customsCharge": value.customsCharge,
            "customsCharge_nis": Number(value.customsCharge * mul).toFixed(2),
            "vat": value.vat,
            "vat_nis": Number(value.vat * mul).toFixed(2),
            "cif": value.cif,
            "cif_nis": Number(value.cif * mul).toFixed(2),
            "cctype": value.cctype,
            "purchaseTax": value.purchaseTax,
            "purchaseTax_nis": Number(value.purchaseTax * mul).toFixed(2),
            "addCharges_nis": Number(value.addCharges * mul).toFixed(2),
            "addCharges": Number(value.addCharges).toFixed(2),
            "insurence": value.insurence,
            "insurence_nis": Number(value.insurence * mul).toFixed(2),
            "majorCategory": categories.find(u => u.id === value.category)?.name || "-",
            "minorCategory": subcategories.find(u => u.id === value.subcategory)?.name || "-",
            "cred_type": "1",
            "type": "TRANZILA",
            "opcode": "shippingDelivaryRequestPay",
            "pWt": value.pWt,
            "wS": value.wS,
            "fixedFee": value.fixedFee,
            "fixedFee_nis": Number(value.fixedFee * mul).toFixed(2),
            "cScharge": value.cS,
            "aCUsed": value.aCUsed,
            "curMul": value.curMul,
            "shippingReqId": selectedShipping?.id,
            "rememberPayment": value.rememberPayment,
        };

        if (!data1.expdate || !data1.myid || !data1.mycvv || !data1.ccno || !data1.amount || !data1.shippingReqId) {
            dispatch(snackbarToggle({ type: 'error', message: `Please enter all required fields` }));
            return;
        }
        setIsFileUploaded(false)
        shippingDelivaryRequestPay(userInfo.session, data1, function (response) {
            setIsFileUploaded(true)
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: `Payment Succesfull.` }));
                // setIsPersonal(false)
                get_shipping_list_count();
                setActiveScreen(8);
            } else {
                dispatch(snackbarToggle({ type: 'error', message: response.message }));
            }
            //setActiveScreen(6)
        })

    }
    const shipping_pay_token = (event, newValue) => {
        if (!userInfo.user.setting?.profileId) {
            saveProfileData();
        }
        var mul = 3.25;
        if (exchangeRate.rates) {
            mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.USD
        }
        if (!mul) {
            mul = 3.25;
        }
        var tVal = value.transportVal;

        var data1 = {
            "sessionId": userInfo.session,
            "currency": value.selectedCurrency,
            // "invoiceOriginal": value.invoiceOriginal,
            "invoiceOriginal": getConvertedCostByCurToUsd(value.selectedCurrency, value.totalCost),
            "opcode": "shippingDelivaryRequestPayWithToken",
            "pWt": value.pWt,
            "wS": value.wS,
            "fixedFee": value.fixedFee,
            "fixedFee_nis": Number(value.fixedFee * mul).toFixed(2),
            "cScharge": value.cS,
            "aCUsed": value.aCUsed,
            "curMul": value.curMul,
            "shippingReqId": selectedShipping?.id,
            "cc_name": value.cc_name,
            "ccno": value.ccno,
            "amount": getTotal(),
            "amount_nis": Number(getTotal() * mul).toFixed(2),
            "couponText": value.couponText,
            "exchange_rate": mul,
            "invoiceValue": value.totalCost,
            "invoiceValue_nis": Number(value.totalCost * mul).toFixed(2),
            "isInsurence": value.isInsurence,
            "homeDelivary": value.homeDelivary,
            "homeDelivary_nis": Number(value.homeDelivary ? (4 * mul) : 0).toFixed(2),
            "transportVal": tVal,
            "transportVal_nis": Number(tVal * mul).toFixed(2),
            "repackCost": value.repackCost,
            "repackCost_nis": Number(value.repackCost * mul).toFixed(2),
            "orgTotalCost": value.orgTotalCost,
            "orgTotalCost_nis": Number(value.orgTotalCost * mul).toFixed(2),
            "totalInvoice": value.totalInvoice,
            "totalInvoice_nis": Number(value.totalInvoice * mul).toFixed(2),
            "couponDeduction": value.cD,
            "couponDeduction_nis": Number(value.cD * mul).toFixed(2),
            "customsCharge": value.customsCharge,
            "customsCharge_nis": Number(value.customsCharge * mul).toFixed(2),
            "vat": value.vat,
            "vat_nis": Number(value.vat * mul).toFixed(2),
            "cif": value.cif,
            "cif_nis": Number(value.cif * mul).toFixed(2),
            "cctype": value.cctype,
            "purchaseTax": value.purchaseTax,
            "purchaseTax_nis": Number(value.purchaseTax * mul).toFixed(2),
            "addCharges_nis": Number(value.addCharges * mul).toFixed(2),
            "addCharges": Number(value.addCharges).toFixed(2),
            "insurence": value.insurence,
            "insurence_nis": Number(value.insurence * mul).toFixed(2),
            "majorCategory": categories.find(u => u.id === value.category)?.name || "-",
            "minorCategory": subcategories.find(u => u.id === value.subcategory)?.name || "-",
            "cred_type": "1",
            "type": "TRANZILA",
            "rememberPayment": value.rememberPayment ? true : false,
        };
        setIsFileUploaded(false)

        shippingDelivaryRequestPayWithToken(userInfo.session, data1, function (response) {
            setIsFileUploaded(true)
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: `Payment Succesfull` }));
                // setIsPersonal(false)
                get_shipping_list_count();
                setActiveScreen(8);
            } else {
                dispatch(snackbarToggle({ type: 'error', message: response.message }));
            }
            //setActiveScreen(6)
        })
    }
    const [cities, setCities] = useState([]);
    const [streets, setStreets] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [addressIsNotProper, setAddressIsNotProper] = useState(false);
    const [ncodes, setNcodes] = useState([]);
    const handleWarHWts = (w, len) => {

        var t = [];
        try {
            var tV = w?.params?.wtNval;
            for (var i = 0; i < len; i++) {
                if (tV[i][0] && tV[i][1]) {
                    t.push({
                        wt: Number(tV[i][0]),
                        val: Number(tV[i][1]),
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }

        // setWtAndVals(t)
        return t;
    }

    const [change, setChange] = useState(false);
    const [uploadedOrgFiles, setUploadedOrgFiles] = useState([]);
    const [uploadedOrgFilesBase64, setUploadedOrgFilesBase64] = useState([]);
    const getBase64Reciepts = () => {

        getBase64RecieptList({ id: selectedShipping.id }, function (response) {
            if (response.misc.CODE === 1) {
                setUploadedOrgFilesBase64(response.data)
                if (response?.data?.length) {
                    setUploadedPdfBse64Id(response.data[0].id)
                    setUploadedPdfBse64(response.data[0])
                }
            }
        })
    }
    const [shipmentCost1, setShipmentCost1] = useState({ id: null });

    useEffect(() => {

        getShipmentCost(function (resp) {
            if (resp?.data)
                setShipmentCost1(resp?.data)
        });

        if (selectedShipping?.shippingStatus > 2) {
            setActiveScreen(9)
        }
        setUploadedOrgFiles(selectedShipping?.params?.userData?.reciept_org_names ? selectedShipping?.params?.userData?.reciept_org_names?.split(",") : [])
        console.log(selectedShipping)
        getBase64Reciepts()
        listWarehouse(function (data) {
            var ws = JSON.parse(JSON.stringify(data.data))
            var t = [];
            ws.map((w, index) => {
                if (w.params?.active || w.name === 'טורקיה' || w.name === "Test") {
                    try {
                        w.wtNval = handleWarHWts(w, w.params?.wtNval.length);
                    } catch (e) {
                        console.log(e)
                    }
                    t.push(w);
                }
            })
            setWareHouses(t);
            for (var i = 0; i < t.length; i++) {
                if (t[i].id == selectedShipping.warehouseId) {
                    if (t[i]?.lmid && t[i]?.lmid == 2) {
                        getZigZagAddresses(function (data) {
                            var t = []
                            data.map((d, index) => {
                                t.push({
                                    id: index + 1,
                                    name: d.cityName,
                                    ncodes: d.ncodes,
                                    streetList: d.streetList,
                                });
                            })
                            t.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setCities(t);
                            var t1 = [];
                            var city = t.find(u => u.name === selectedShipping?.params?.userData.pickupPoint?.City);
                            city?.streetList.map((d, index) => {
                                t1.push({
                                    id: index + 1,
                                    name: d.streetName,
                                    ncode: d.ncode,
                                    houseList: d.houseList
                                });
                            })
                            t1.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setStreets(t1);

                            var street = t1.find(u => u.ncode === userInfo?.user?.setting?.pickupPoint?.ncode);
                            var t2 = [];
                            street?.houseList.map((d, index) => {
                                t2.push({
                                    id: index + 1,
                                    name: d
                                });
                            })
                            t2.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setBuildings(t2);

                            setValue(prev => ({
                                ...prev,
                                cityId: t.find(u => u.name === userInfo?.user?.setting?.pickupPoint?.City)?.id,
                                buildingId: t2.find(u => u.name === userInfo?.user?.setting?.pickupPoint?.House)?.id,
                                ncodeId: t1.find(u => u.ncode === userInfo?.user?.setting?.pickupPoint?.ncode)?.id,
                                streetId: t1.find(u => u.name === userInfo?.user?.setting?.pickupPoint?.Street)?.id,
                                payByToken: userInfo?.user?.setting?.TranzilaTK && (userInfo?.user?.setting?.expdate || (userInfo?.user?.setting?.expdateTK && userInfo?.user?.setting?.expdateTK !== "expdate")) ? true : false
                            }));
                        })
                    }
                    else {
                        getCityStreetNcode(function (data) {
                            var t = []
                            data.map((d, index) => {
                                t.push({
                                    id: index + 1,
                                    name: d.cityName,
                                    ncodes: d.ncodes,
                                    streetList: d.streetList,
                                });
                            })
                            setCities(t);
                            var t1 = [];
                            var city = t.find(u => u.name === selectedShipping?.params?.userData.pickupPoint?.City);
                            city?.streetList.map((d, index) => {
                                t1.push({
                                    id: index + 1,
                                    name: d.streetName,
                                    ncode: d.ncode,
                                    houseList: d.houseList
                                });
                            })
                            t1.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setStreets(t1);

                            var street = t1.find(u => u.ncode === userInfo?.user?.setting?.pickupPoint?.ncode);
                            var t2 = [];
                            street?.houseList.map((d, index) => {
                                t2.push({
                                    id: index + 1,
                                    name: d
                                });
                            })
                            t2.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setBuildings(t2);

                            setValue(prev => ({
                                ...prev,
                                cityId: t.find(u => u.name === userInfo?.user?.setting?.pickupPoint?.City)?.id,
                                buildingId: t2.find(u => u.name === userInfo?.user?.setting?.pickupPoint?.House)?.id,
                                ncodeId: t1.find(u => u.ncode === userInfo?.user?.setting?.pickupPoint?.ncode)?.id,
                                streetId: t1.find(u => u.name === userInfo?.user?.setting?.pickupPoint?.Street)?.id,
                                payByToken: userInfo?.user?.setting?.TranzilaTK && (userInfo?.user?.setting?.expdate || (userInfo?.user?.setting?.expdateTK && userInfo?.user?.setting?.expdateTK !== "expdate")) ? true : false
                            }));
                        })
                    }
                }
            }

        });
        //shippingCoPoints
        var len = shippingCoPoints.length;
        var cities = []
        var streets = []
        var buildings = []
        var ncodes = []
        for (var i = 0; i < len; i++) {
            if (cities.indexOf(shippingCoPoints[i].city) === -1) {
                cities.push({
                    id: i + 1,
                    name: shippingCoPoints[i].city
                });
            }
            if (streets.indexOf(shippingCoPoints[i].street) === -1) {
                streets.push({
                    id: i + 1,
                    name: shippingCoPoints[i].street
                });
            }
            if (buildings.indexOf(shippingCoPoints[i].name) === -1) {
                buildings.push({
                    id: i + 1,
                    name: shippingCoPoints[i].name
                });
            }
            if (ncodes.indexOf(shippingCoPoints[i].n_code) === -1) {
                ncodes.push({
                    id: i + 1,
                    name: shippingCoPoints[i].n_code
                });
            }
        }
        // setCities(cities);
        // setStreets(streets);
        //setBuildings(buildings);
        //setNcodes(ncodes);
        

        /*setValue(prev => ({
            ...prev, 
            cityNum: userInfo?.user?.setting?.register_city || '',
            buildingNum: userInfo?.user?.setting?.register_addressLine_2 || '',
            ncodeNum: userInfo?.user?.setting?.register_postal || '',
            streetNum: userInfo?.user?.setting?.register_addressLine_1 || '',
        }));*/

        var add = myAdds?.find(u => u.isDefault);
        //setValue(prev => ({ ...prev, orgTotalCost: value.totalCost }));
        if (add)
            setValue(prev => ({ ...prev, addId: add?.id, addPhone: add?.addPhone, addName: add?.addName, cityNum: add?.city, streetNum: add?.street, buildingNum: add?.building, ncodeNum: add?.zipCode }))


        getExchangeRate(function (res) {
            setExchangeRate(res);
        });

        setValue(prev => ({ ...prev, payByToken: userInfo?.user?.setting?.TranzilaTK && (userInfo?.user?.setting?.expdate || (userInfo?.user?.setting?.expdateTK && userInfo?.user?.setting?.expdateTK !== "expdate")) }))

        if (userInfo.session) {

            getCouponList(userInfo.user.token, function (response) {
                if (response.misc.CODE === 1) {
                    setCouponList(response.data)
                    if (props?.setCouponList) {
                        props?.setCouponList(response.data)
                    }
                }
            })
        }

    }, [dispatch, selectedShipping]);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const categories = settingsRedbox.map((setting, index) => ({
        id: index + 1,
        name: setting.name
    }));
    const myAdds = userInfo.user.setting?.addressList ? (userInfo.user.setting?.addressList?.map((setting, index) => ({
        id: index + 1,
        name: setting.city + ", " + setting.street + ", " + setting.building,
        addName: setting.addName ? setting.addName : userInfo.user.firstName + " " + userInfo.user.lastName,
        addPhone: setting.addPhone ? setting.addPhone : userInfo.user.phone,
        city: setting.city,
        street: setting.street,
        building: setting.building,
        zipCode: setting.zipCode,
        isDefault: setting.isDefault,
    }))) : [];
    const [subcategories, setSubcategories] = useState([]);
    const [subcategories3, setSubcategories3] = useState([]);
    const [subcategories4, setSubcategories4] = useState([]);
    const [subcategories5, setSubcategories5] = useState([]);

    const [exchangeRate, setExchangeRate] = new useState({});

    const [openInsuranceDialog, setOpenInsuranceDialog] = useState(false);
    const onHandleCancelInsuranceDialog = () => {
        setOpenInsuranceDialog(false);

    };
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = (add, ind) => {
        setOpenOrderModal(true);

    };
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const [selectedRecieptName, setSelectedRecieptName] = useState("");
    
    const [openOrderModalDelete, setOpenOrderModalDelete] = useState(false);
    const onHandleOpenModalDelete = (name) => {
        setSelectedRecieptName(name)
        setOpenOrderModalDelete(true);

    };
    const onHandleCancelModalDelete = () => setOpenOrderModalDelete(false);
    const deleteSelectdReciept = (name) => {

        deleteReciept({ id: selectedShipping.id, name: name }, function (response) {
            if (response.misc.CODE === 1) {
                getBase64Reciepts()
                dispatch(setSelectedShipping(response.data));
                onHandleCancelModalDelete();
            }
        })
    }
    const progressSteps = (currentScreen = null) => (
        <>
            {selectedShipping.consolidationStatus != 3 &&<Grid container className="screen-progress-steps">
                {screens.map((val, index) =>
                    <Box key={val.screen + index} className={`progress-step ${currentScreen === val.screen && 'current'} ${currentScreen > val.screen && 'done'}`}>
                        <Box className="step-circle">
                            {val.screen}
                        </Box>
                        <Box className="step-title">
                            {val.title}
                        </Box>
                    </Box>)}
            </Grid>}
            {selectedShipping.consolidationStatus == 3 &&<Grid container className="screen-progress-steps">
                {screensMulti.map((val, index) =>
                    <Box key={val.screen + index} className={`progress-step ${currentScreen === val.screen && 'current'} ${currentScreen > val.screen && 'done'}`}>
                        <Box className="step-circle">
                            {val.screen}
                        </Box>
                        <Box className="step-title">
                            {val.title}
                        </Box>
                    </Box>)}
            </Grid>}

        </>
    );

    const screen_A = (
        <Box className="reg_popup_data">
            
            {progressSteps(1)}

            <Grid className="popup_tittle lg" >
                {selectedShipping?.consolidationStatus == 3?'העלאת חשבוניות':'העלאת חשבונית'}   
            </Grid>
            <Grid className="popup_tittle sm" >
                חבילה מספר {selectedShipping?.id}
            </Grid>

            <Grid container className="grey-box-area">
                <Grid container className="box-row">
                    <Grid className="row-Label">
                        שם החנות:
                    </Grid>
                    <Grid className="row-Value">
                        {selectedShipping?.params?.sender?.senderName}
                    </Grid>
                </Grid>
                <Grid container className="box-row">
                    <Grid className="row-Label">
                        תאור הפריט:
                    </Grid>
                    <Grid className="row-Value">
                        {selectedShipping?.shippingDesc}
                    </Grid>
                </Grid>
            </Grid>

            <Box className="dropzone-area" >
                <Grid container className="upload-content">
                    <div {...getRootProps({
                        onClick: (event) => {
                            if (checkFiles()) {
                                event.stopPropagation();
                            }
                        }
                    })} className='upload-area'>
                        <input {...getInputProps()} />
                        {
                            <Grid container className="upload-content">
                                <img className='upload' src={upload} alt='upload' />
                                {selectedShipping.consolidationStatus == 3 && uploadedOrgFiles?.length>0 && !checkFiles() && <Box className="content-line-1">
                                    ניתן להעלות מספר חשבוניות
                                </Box>}
                                {selectedShipping.consolidationStatus == 3 && uploadedOrgFiles?.length > 0 && checkFiles() && <Box className="content-line-1">
                                    החשבוניות עלו בהצלחה
                                </Box>}
                                <Box className="content-line-1">
                                    ניתן לגרור את החשבונית לכאן או באמצעות כפתור העלאת קובץ
                                </Box>
                                <Box className="content-line-1" style={{ direction: "rtl" }}>
                                    <span>
                                        ניתן להעלות קבצים מסוג JPEG, PNG, BMP, PDF עד גודל 2MB
                                    </span>
                                </Box>
                            </Grid>
                        }
                    </div>
                </Grid>
                {uploadedOrgFiles?.length>0 && <Grid container item >
                    {uploadedOrgFiles.map((val, index) =>
                        <Grid container item justifyContent="flex-end">
                            <Grid container item lg={ 5} style={{ color:"#d70007",fontWeight:"bold"}}>{val}</Grid>
                            <Grid container item lg={2} onClick={() => {
                                

                                var filePdf = uploadedOrgFilesBase64.find(u => u.name === val);
                                setUploadedPdfBse64Id(filePdf.id);
                                setIsPreviewPdf(!isPreviewPdf)
                            }} style={{ marginLeft: "5px", backgroundImage: `url(${file})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px' }}></Grid>
                            <Grid container item lg={2} onClick={() => {
                                

                                onHandleOpenModalDelete(val)
                            }} title={ 'Delete Invoice'} style={{ marginLeft: "15px", backgroundImage: `url(${del})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px' }}></Grid>

                        </Grid>)}
                        
                   </Grid>
                }
            </Box>
            {!isFileUploaded && <Loader />}
            {Number(selectedShipping?.params?.userPaymentData.weight) > 99 && <Grid onClick={() => { window.open("/contactUs", "_blank") }} sx={{ cursor: "pointer", color: "red", textDecoration: "underline", mb: 1 }}> בקשה צור קשר עם שרות לקוחות</Grid>}
            <Grid container className='form-action grid-action'>
                <ActionButton disabled={(!uploadedOrgFiles.length || Number(selectedShipping?.params?.userPaymentData.weight) > 99) } className={'left-action-btn'} textLabel={'המשך'} onClick={() => {

                    if (!uploadedOrgFiles.length) {
                        return;
                    }
                    setActiveScreen(screens[1].screen)
                    setIsPreviewPdf(true)
                    setValue(prev => ({ ...prev, homeDelivary: checkWeight() }))
                }} />
                <ActionButton disabled={!uploadedOrgFiles.length} className={'left-action-btn'} textLabel={'תצוגה'} onClick={() => {

                    if (!uploadedOrgFiles.length) {
                        return;
                    }
                    setIsPreviewPdf(!isPreviewPdf)
                }} />
                {<ActionButton disabled={!uploadedOrgFiles.length} className={'left-action-btn'} textLabel={'מחק'} onClick={() => {
                    setIsPreviewPdf(false)
                    setUploadedPdfFileName("")
                    setUploadedPdf(null)
                }} />}

            </Grid>
            <Grid container className='form-action grid-action' justifyContent="center" >

                <Box className="right-action-text" sx={{ width: "100%", textAlign: "center !important"}}>
                    צריכים עזרה בהעלאת החשבונית <span className='link' onClick={() => {
                        window.open("https://redboxparcel.com/FAQS", "_blank")
                    }}>? למדריך המלא</span>
                </Box>
            </Grid>
            <Box className="screen-bottom-text" style={{ direction: "rtl", fontSize:"18px" }}>
                {`שימו לב,`} <br />
            </Box>
            <Box className="screen-bottom-text" style={{ direction: "rtl", textAlign: "right", marginRight:"10px" }}>
                {`1. החשבונית צריכה לכלול את השם המלא של הפריט ואת הסכום המדויק ששילמתם.`}
                <br />
            </Box>
            <Box className="screen-bottom-text" style={{ direction: "rtl", textAlign: "right", marginRight: "20px" }}>
                {`לפי תקנות המכס על החשבונית לכלול את סכום המוצר, תיאור המוצר ושם הספק.`} <br />
            </Box>
            <Box className="screen-bottom-text" style={{ direction: "rtl", textAlign: "right", marginRight: "10px" }}>
                {`2. ייבוא לישראל אינו מותר לקטינים, מתחת לגיל 18, על פי חוקי והנחיות המכס.`}<br />
            </Box>
            <Box className="screen-bottom-text" style={{ direction: "rtl", textAlign: "right", marginRight: "20px" }}>
                { `אם הנך מתחת לגיל 18 : על מנת להימנע מעיכוב בשחרור מהמכס, העשוי לגרור עלויות נוספות`} <br/>
                { `יש להעביר אלינו למייל info@redboxparcel.com  צילום תעודת זהות של אחד ההורים`} <br/>
                <a href="/parentAproval.pdf" target="_blank">{`וייפוי כח מההורה.`}</a> <br /><br /><br /><br /><br />
            </Box>
            {selectedShipping?.consolidationStatus == 3 && <Grid container justifyContent="center">
                <b> במידה והתשלום הוא על משלוח שמאחד מספר חבילות יש לצרף את כל החשבוניות <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  הרלוונטיות ולגרור אותן אחת אחר השנייה למשבצת העלאת חשבוניות</b>
            </Grid>}
            <Grid item container style={{ direction: "rtl" }}><br /><br />
            </Grid>
        </Box>);
    function getStringRule(data) {
        var arr = JSON.parse(data)
        return arr
    }
    const getCouponValue = (ct, wt, validC) => {
        if (!ct) {
            return 0;
        }
        var p1 = 0;
        if (validC) {
            couponList.map((c) => {
                if (c.couponId == ct) {
                    var mRules = getStringRule(c.multiRules);
                    mRules.map((r, i) => {
                        var w = r[0];
                        var p = r[1];
                        if (i == 0 && Number(wt) < Number(w)) {
                            p1 = p;
                        } else if (i == mRules.length - 1 && Number(wt) > Number(w)) {
                            p1 = p;
                        } else if (Number(wt) < Number(w)) {
                            p1 = mRules[i - 1][1]
                        }
                    })
                }
            })
        }
        return p1;
    }
    const getConvertedCostByCurToNIS_UI = (cur, amount) => {
        //var amount = getConvertedCostByCurToUsd_UI(cur, amount);
        if (cur == 1) { //USD
            var mul = 4.00;
            if (exchangeRate.rates) {
                mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.USD
            }
            return amount * mul;
        }
        if (cur == 2) { // EUR
            var mul = 4.24;
            if (exchangeRate.rates) {
                mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.EUR
            }
            return amount * mul;
        }
        if (cur == 3) { // GBP
            var mul = 4.87;
            if (exchangeRate.rates) {
                mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.GBP
            }
            return amount * mul;
        }
        if (cur == 4) { // TRY
            var mul = 0.14;
            if (exchangeRate.rates) {
                mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.TRY
            }
            return amount * mul;
        }
        return Number(amount)
    }
    const getConvertedCostByCurToUsd_UI = (cur, amount) => {

        if (cur == 1) {
            return amount
        }
        if (cur == 2) {
            var eurMul = 1.03;
            if (exchangeRate.rates) {
                eurMul = exchangeRate?.rates?.USD
            }
            amount = amount * eurMul;
            return amount
        }
        if (cur == 3) {
            var poundMul = 1.28;
            if (exchangeRate.rates) {
                poundMul = exchangeRate?.rates?.USD / exchangeRate?.rates?.GBP
            }
            amount = amount * poundMul;
            return amount
        }
        if (cur == 4) {
            var turMul = 0.037;
            if (exchangeRate.rates) {
                turMul = exchangeRate?.rates?.USD / exchangeRate?.rates?.TRY
            }
            amount = amount * turMul;
            return amount
        }
        return Number(amount)
    }
    const getConvertedCostByCurToUsd = (cur, amount) => {

        if (cur == 1) {
            setValue(prev => ({ ...prev, curMul: 1 }));
        }
        if (cur == 2) {
            var eurMul = 1.03;
            if (exchangeRate.rates) {
                eurMul = exchangeRate?.rates?.USD
            }
            amount = amount * eurMul;
            setValue(prev => ({ ...prev, curMul: eurMul }));
            return amount
        }
        if (cur == 3) {
            var poundMul = 1.28;
            if (exchangeRate.rates) {
                poundMul = exchangeRate?.rates?.USD / exchangeRate?.rates?.GBP
            }
            setValue(prev => ({ ...prev, curMul: poundMul }));
            amount = amount * poundMul;
            return amount
        }
        /*if (cur == 4) {
            var turMul = 0.037;
            if (exchangeRate.rates) {
                turMul = exchangeRate?.rates?.USD / exchangeRate?.rates?.TRY
            }
            setValue(prev => ({ ...prev, curMul: turMul }));
            amount = amount * turMul;
            return amount
        }*/
        if (cur == 4) {
            var polMul = 0.25;  
            if (exchangeRate.rates) {
                polMul = exchangeRate?.rates?.USD / exchangeRate?.rates?.PLN
            }
            setValue(prev => ({ ...prev, curMul: polMul }));
            amount = amount * polMul;
            return amount
        }
        if (cur == 5) {
            var cadMul = 0.73;
            if (exchangeRate.rates) {
                cadMul = exchangeRate?.rates?.USD / exchangeRate?.rates?.CAD
            }
            setValue(prev => ({ ...prev, curMul: cadMul }));
            amount = amount * cadMul;
            return amount
        }
        if (amount > 250) {
            setInsurenceDisabled(true)
        } else {
            setInsurenceDisabled(false)
        }
        return Number(amount)
    }
    const [validCoupon, setValidCoupon] = useState(false);
    const [couponValue, setCouponValue] = useState(0);
    const checkCoupon = (cpnText) => {
        var p1 = false;
        couponList.map((c) => {
            if (c.couponId == value.couponText) {
                p1 = true

            }
        })
        var chargWt = Number(selectedShipping?.params?.userPaymentData.height) * Number(selectedShipping?.params?.userPaymentData["length"]) * Number(selectedShipping?.params?.userPaymentData.width) * chWtMultiplier;

        var wt = Number(selectedShipping?.params?.userPaymentData.weight);
        var twt = chargWt > wt ? chargWt : wt;
        
        isValidCoupon(userInfo.user.token, selectedShipping.id, selectedShipping.warehouseId, cpnText, function (response) {
            //
            setValidCoupon(response)
            getCouponValue1(userInfo.user.token, selectedShipping.id, selectedShipping.warehouseId, cpnText, twt, function (response) {
                //
                setCouponValue(response)
                calculate(value.subcategory - 1, cpnText, response)
            })

            
            
        })
        

        return p1;
    }
    const calculate = (index, cp, validC) => {
        if (value.couponText.length > 0) {
            var p1 = false;
            couponList.map((c) => {
                if (c.couponId === (cp)) {
                    p1 = true
                }
            })
            if (!p1) {
                //dispatch(snackbarToggle({ type: 'error', message: `Coupon is not valid.` }));
                // return
            }

        }
        var line66 = false;
        var addCharges = 0;

        var chargWt = Number(selectedShipping?.params?.userPaymentData.height) * Number(selectedShipping?.params?.userPaymentData["length"]) * Number(selectedShipping?.params?.userPaymentData.width) * chWtMultiplier;
        var wt = Number(selectedShipping?.params?.userPaymentData.weight);
        var twt = chargWt > wt ? chargWt : wt;
        //var cPer = Number(getCouponValue(cp, twt, validC));
        var cPer = Number(validC);

        var ind = value.subCategory - 1;
        var additionalCharges = Number(selectedShipping?.params?.additionalCharges ? selectedShipping?.params?.additionalCharges : 0);
        var consolidationCharges = Number(selectedShipping?.params?.consolidationCharges ? selectedShipping?.params?.consolidationCharges : 0);
        var calculatedBasePrice = Number(selectedShipping?.params?.calculatedBasePrice ? selectedShipping?.params?.calculatedBasePrice : 0);
        var h = Number(selectedShipping?.params?.userPaymentData.height);
        var l = Number(selectedShipping?.params?.userPaymentData["length"]);
        var w = Number(selectedShipping?.params?.userPaymentData.width);
        var totalCost = Number(value.totalCost)
        setValue(prev => ({ ...prev, invoiceOriginal: totalCost }));
        //ppp
        //if (value.selectedCurrency == 2) {
        totalCost = getConvertedCostByCurToUsd(value.selectedCurrency, totalCost);
        // }

        var ind = index;
        var wh = wareHouses.find(u => u.id === selectedShipping.warehouseId);
        if (!wh) {
            dispatch(snackbarToggle({ type: 'error', message: `Service not available.` }));
            setIsPersonal(false)
            return;
        }
        var wVal = weightAndVal;
        if (wh?.wtNval) {
            wVal = wh?.wtNval;
        }
        calculateValues(h, l, w, wt, wVal, ind, subcategories3, subcategories4, subcategories5, totalCost, cPer, setValue, calculatedBasePrice, consolidationCharges, additionalCharges, shipmentCost1?.price, selectedShipping?.shippingPrice ? selectedShipping?.shippingPrice : 0, selectedShipping.params?.repackCosts ? selectedShipping.params?.repackCosts : {});
        var cD = cPer * Number(value.transportVal) / 100;
        setValue(prev => ({ ...prev, cD: cD }));
        if (totalCost > 250) {
            setValue(prev => ({ ...prev, isInsurence: true }));
        } else {
        }

        if (totalCost > 250) {
            setInsurenceDisabled(true)
        } else {
            setInsurenceDisabled(false)
        }

        console.log(value)
        /*
                calculateValues(h, l, w, wt, weightAndVal, subcategories3, subcategories4, subcategories5, value, price, 0, setValue);
        
                twt = Number(twt).toFixed(2) < 1 ? 1 : (Number(twt).toFixed(2) > 26.9 ? 26.9 : Number(twt).toFixed(2));
                var transportVal = 0;
                weightAndVal.forEach((weightVal) => {
                    if (weightVal.wt == twt) {
                        transportVal = weightVal.val;
                    }
                })
               
                var cD = cPer * Number(transportVal) / 100;
                var orgTotalCost = transportVal;
                transportVal = transportVal - cD;
                var totalCost = Number(value.totalCost)
                if (value.selectedCurrency == 2) {
                    totalCost = totalCost * 1.03;
                }
                var cif = Number(transportVal) + Number(totalCost);
        
                var customsCharge = 0;
                var purchaseTax = cif;
                //var vat = purchaseTax * 0.17;
                var vat = 0;
        
                if (Number(totalCost) > 75 && Number(totalCost) < 500) {
                    addCharges = 6;
                } else if (Number(totalCost) >= 500 && Number(totalCost) < 1000) {
                    addCharges = 20;
                } else if (Number(totalCost) >= 1000) {
                    addCharges = 75;
                }
                if (Number(selectedShipping?.params?.userPaymentData.height) > 120 || Number(selectedShipping?.params?.userPaymentData["length"]) > 120 || Number(selectedShipping?.params?.userPaymentData.width) > 120) {
                    cif = cif + 35;
                    transportVal += 35;
                }
                if (subcategories5.length) {
                    line66 = true;
                }
        
                if (totalCost > 75) {
                    customsCharge = cif * Number(subcategories3[index]) / 100;
                    purchaseTax = (cif * (1 + Number(subcategories3[index]) / 100) * (1 + Number(subcategories4[index]) / 100));
                    vat = purchaseTax * 0.17;
                    if (subcategories5.length) {
                        purchaseTax = Number(purchaseTax) + Number(subcategories5[index]);
                    }
                   
                }
                purchaseTax = purchaseTax + addCharges;
                var totalInvoice = purchaseTax - Number(totalCost) + vat;
                var insurence = (Number(transportVal) * 3 / 100) < 2 ? 2 : (Number(transportVal) * 3 / 100);
                if (Number(purchaseTax) != 0) {
                    purchaseTax = purchaseTax - cif;
                }
                customsCharge = 0;
                if (value.selectedCurrency == 2) {
                    totalInvoice = totalInvoice / 1.03;
                    transportVal = transportVal / 1.03;
                    cif = cif / 1.03;
                    //customsCharge = customsCharge / 1.03;
                    vat = vat / 1.03;
                    purchaseTax = purchaseTax / 1.03;
                    insurence = insurence / 1.03;
                    cD = cD / 1.03;
                    orgTotalCost = orgTotalCost / 1.03;
                }
        
                setValue(prev => ({ ...prev, invoiceOriginal: transportVal }));
                setValue(prev => ({ ...prev, transportVal: transportVal }));
                setValue(prev => ({ ...prev, totalInvoice: totalInvoice }));
                setValue(prev => ({ ...prev, cif: cif }));
                setValue(prev => ({ ...prev, customsCharge: customsCharge }));
                setValue(prev => ({ ...prev, vat: vat }));
                setValue(prev => ({ ...prev, purchaseTax: purchaseTax }));
                setValue(prev => ({ ...prev, insurence: insurence }));
                setValue(prev => ({ ...prev, orgTotalCost: orgTotalCost }));
                setValue(prev => ({ ...prev, cD: cD }));*/
    }
    const screen_b = (
        <Box className="reg_popup_data">
            {progressSteps(2)}

            <Grid className="popup_tittle lg" >
                פרטי ההזמנה
            </Grid>
            <Grid className="popup_tittle sm" >
                <div>{uploadedPdfFileName}</div>
                <div onClick={() => setIsPreviewPdf(!isPreviewPdf)} style={{ marginLeft: "5px", backgroundImage: `url(${file})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px', }}></div>
            </Grid>
            {/*<Grid className="popup_tittle sm" >
                <div>{selectedShipping?.params?.sender?.senderName}</div>
            </Grid>*/}
            {/*<Grid className="popup_tittle sm" style={{direction: "rtl", textAlign: "right"}} >
                { `עלות המוצר:`}<br />
                { `מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}<br/>
                { `מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}<br/>
            </Grid>*/}
            <Grid container item style={{ direction: "rtl", fontWeight: "bold" }}>
                {`עלות המוצר:`}<br />
                {`מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}<br />
                {`מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}<br /><br />
            </Grid>

            <Box>

                <Box container className='label-wrapper'>
                    <Box component={'span'} className='input-label'>
                        {/*{selectedShipping?.consolidationStatus == 3 ? 'עלות המוצרים (ללא מיסים והובלה בחו״ל)' :' עלות מוצר ( ללא מיסים והובלה בחו״ל)'}*/}
                        {selectedShipping?.consolidationStatus == 3 ? 'עלות המוצרים' : 'עלות מוצר'}
                        
                    </Box>
                    {/*<Box component={'span'} className='ques-icon'>
                        <img src={questionMark} alt="" />
                    </Box>*/}
                </Box>
                <Grid container className='input-wrapper' >
                    <TextField
                        className='text-field short'
                        required
                        value={value.totalCost}
                        type="number"
                        onChange={event => {
                            setValue(prev => ({ ...prev, totalCost: event.target.value }))
                        }}
                        label='הקלידו כאן'
                        islabelAnimate={false}
                        isPrefix
                        parentClassName=''
                        sx={{ width: "40%", mr: "5px", mt: "5px", minWidth: "200px" }}
                    />
                    <SelectField
                        className='text-field1'
                        required
                        value={value.selectedCurrency}
                        onChange={event => setValue(prev => ({ ...prev, selectedCurrency: event.target.value }))}
                        label=''
                        options={currencies}
                        islabelAnimate={false}
                        isPrefix
                        parentClassName={'select-field '}
                        dir="rtl"
                        isNumeric={true}
                        sx={{ m: 0, mr: "10px", mt: "10px" }}
                    />

                </Grid>
                <Grid container item style={{direction:"rtl", fontWeight:"bold"}}>
                    <p style={{ marginBottom:"0", marginTop:"10px"}}>{`במידה והמטבע המצוין בחשבונית שונה מ USD או EUR אנא השתמשו `}<a href="https://www.gov.il/apps/taxes/currencyGates/" target="_blank">בקישור</a></p>
                    <p style={{ marginBottom: "0", marginTop: "0px" }}>{`על מנת להמנע מהפרשי שער מול רשות המיסים.` }</p>
                </Grid>
                <br/>
                <Box container className='label-wrapper'>
                    <Box component={'span'} className='input-label'>
                        קטגוריה
                    </Box>
                    {/*<Box component={'span'} className='ques-icon'>
                        <img src={questionMark} alt="" />
                    </Box>*/}
                </Box>

                {
                    /*  <Grid container className='input-wrapper'>
                      <TextField
                          className='text-field short'
                          required
                          value={value.totalCost}
                          type="number"
                          onChange={event => setValue(prev => ({ ...prev, totalCost: event.target.value }))}
                          label='הקלידו כאן'
                          islabelAnimate={false}
                      />
                  </Grid>*/
                }

                <Grid container className='input-wrapper dir-rtl'>
                    <SelectField
                        className='text-field1'
                        required
                        value={value.category}
                        onChange={event => {
                            setValue(prev => ({ ...prev, category: event.target.value }));
                            var t = settingsRedbox[event.target.value - 1].subCategory.category1
                            var t2 = t.map((setting, index) => ({
                                id: index + 1,
                                name: setting
                            }));
                            setSubcategories(t2);
                            setSubcategories3(settingsRedbox[event.target.value - 1].subCategory.category3);
                            setSubcategories4(settingsRedbox[event.target.value - 1].subCategory.category4);
                            if (settingsRedbox[event.target.value - 1].subCategory?.category5)
                                setSubcategories5(settingsRedbox[event.target.value - 1].subCategory.category5);
                            else
                                setSubcategories5([]);
                        }}
                        dir="rtl"
                        options={categories}
                        islabelAnimate={false}
                        isNumeric={true}
                        parentClassName={'select-field width-50 mar_10'}
                        sx={{ mr: "0" }}
                    />
                    <SelectField
                        className='text-field1'
                        parentClassName={'select-field width-50 mar_10'}
                        dir="rtl"
                        disabled={!value.category}
                        required
                        value={value.subcategory}
                        onChange={event => {
                            setValue(prev => ({ ...prev, subcategory: event.target.value }));
                            //setValue(prev => ({ ...prev, orgTotalCost: value.totalCost }));

                            calculate(event.target.value - 1, value.couponText, couponValue);
                            setValue(prev => ({ ...prev, certificateId: 1 }))
                        }}
                        options={subcategories}
                        islabelAnimate={false}
                        isPrefix
                        isNumeric={true}
                        sx={{ mr: "0" }}
                    />
                </Grid>

                {/*<Box container className='label-wrapper'>
                    <Box component={'span'} className='input-label'>
                        סוג לקוח
                    </Box>
                   */}{/* <Box component={'span'} className='ques-icon'>
                        <img src={questionMark} alt="" />
                    </Box>*/}{/*
                </Box>*/}


                {/*<Box className='row flex-rtl align-items-center mb-1rem'>
                    <Box className='col-sm-12'>
                        <Button className={`btn-radio  ${!isBusiness ? 'active' : ''}`} onClick={() => setIsBusiness(false)}>
                            פרטי
                        </Button>
                        */}{/*<Button className={`btn-radio ${isBusiness ? 'active' : ''}`} onClick={() => setIsBusiness(true)}>
                            עסקי
                        </Button>*/}{/*
                    </Box>
                </Box>*/}
                {value.category == 14 && value.fixedFee>0 &&
                    
                    <Grid>
                        <Grid container justifyContent="flex-end" >
                            האם ברצונך שרד-בוקס יפיקו עבורך אישור תחבורה?
                        </Grid>
                        <Box container className='checkboxs' sx={{ mt: 2 }} style={{ alignItems: "flex-end"}}>  

                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value.certificateId}
                            onChange={(event) => {
                               
                                setValue(prev => ({ ...prev, certificateId: event.target.value }));
                                setValue(prev => ({ ...prev, isCertificate: !value.isCertificate }));
                        }}
                    >
                        {
                                    value.cirtificateList.map(qs => (
                                        <Grid><Radio color={'info'} value={qs.id} /> { qs.name}</Grid>
                                /*<FormControlLabel style={{ fontSize: '0.8rem', maxWidth: '4vw', padding: '5px' }} control={} label={qs.name} />
*/
                            ))
                        }

                            </RadioGroup></Box>
                    </Grid>
                }

                <Box container className='checkboxs' sx={{ mt: 2 }}>
                    <FormControlLabel className="check-box" key={uuidv4()} control={< Checkbox onKeyDown={e => keyPressed(e)} checked={value?.isTnc} onChange={event => setValue(prev => ({ ...prev, isTnc: event.target.checked }))} />} label=" אני מצהיר/ה בזאת כי החשבונית תקינה ותואמת לתוכן החבילה. במידה והחשבונית
אינה תואמת לתוכן החבילה בפועל, אני מודע/ת לכך שאחויב בעלות בדיקה ושחרור
פרטני מהמכס בגובה של $75" />
                </Box>
                {(isValidTotalCost() || areValidDimentions()) && <Box style={{ color: "red" }}>
                    { `אנא צרו קשר עם שרות לקוחות`}
                </Box>
                }
                <Box className='form-action'>
                    <ActionButton disabled={areValidDimentions() || isValidTotalCost() || !value.category || !value.subcategory || !value.totalCost || !value.isTnc} className={'submit-action small'} textLabel={'תשלום'} onClick={() => {

                        if (!value.totalCost) {
                            dispatch(snackbarToggle({ type: 'error', message: `Please Enter Total Cost.` }));
                            return
                        }

                        if (!value.category) {
                            dispatch(snackbarToggle({ type: 'error', message: `Please Select Category.` }));
                            return
                        }
                        if (!value.subcategory) {
                            dispatch(snackbarToggle({ type: 'error', message: `Please Select Subcategory.` }));
                            return
                        }
                        if (!value.isTnc) {
                            dispatch(snackbarToggle({ type: 'error', message: `Please accept declaration.` }));
                            return
                        }
                        calculate(value.subcategory - 1, value.couponText, couponValue);

                        if (value.certificateId == 1) {
                        } else {

                            setValue(prev => ({ ...prev, totalInvoice: value.totalInvoice - value.fixedFee }))
                            setValue(prev => ({ ...prev, fixedFee: 0 }))
                        }
                        setIsPreviewPdf(false)
                        setActiveScreen(screens[2].screen)
                    }} />
                </Box>
            </Box>
        </Box>)
    var tempFunct = function () {
        if (value.homeDelivary && getTotalHmdCond()) {
            return Number(Number(value.totalInvoice) + 4).toFixed(2)
        } else {
            return Number(value.totalInvoice).toFixed(2)
        }
    }
    const screen_c = (
        <Box className="reg_popup_data">

            {progressSteps(2)}

            {/*<Grid item lg={12} className="" alignContent="flex-start" justifyContent="flex-start" sx={{ marginTop: '-10px', fontSize: '14px !important' }}>
                <div onClick={() => setIsPreviewPdf(!isPreviewPdf)} style={{ marginLeft: "5px", backgroundImage: `url(${file})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px', }}></div>
            </Grid>*/}


            <Grid container item lg={12} >

                <Grid container item lg={12} className='label-wrapper'>
                    <Grid className="popup_tittle sm" style={{ fontSize: '23px', marginBottom: 0 }}>
                        פרטי ההזמנה
                    </Grid>
                    <Grid className="popup_tittle sm" style={{ fontSize: '16px', marginBottom: 0 }}>
                        <div onClick={() => setIsPreviewPdf(!isPreviewPdf)} style={{ marginLeft: "5px", backgroundImage: `url(${file})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px', }}></div>  {uploadedPdfFileName}
                    </Grid>

                </Grid>

                <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                    {/*<Grid container item lg={4} sm={4} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                        <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                            עלות הפריטים:
                        </Grid>
                        <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                            {value.costOfItem}$
                        </Grid>

                    </Grid>
                    */
                    }
                    <Grid container item lg={4} sm={4} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                        <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                            סה״כ חשבונית
                        </Grid>
                        <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                            {value.totalCost} {currencies.find(u => u.id === value.selectedCurrency)?.symbol}
                        </Grid>
                        {false && <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                            {getConvertedCostByCurToNIS_UI(value.selectedCurrency ,value.totalCost).toFixed(2)} ₪
                        </Grid>}
                    </Grid>
                    {/*<Grid container item lg={4} sm={4} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                        <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                            סוג לקוח:
                        </Grid>
                        <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                            {isBusiness ? '' : 'פרטי'}
                        </Grid>
                    </Grid>*/}
                </Grid>
            </Grid>
            <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl', mt: 2 }}>

                <Grid container className='label-wrapper'>
                    <Grid className="popup_tittle sm" style={{ fontSize: '23px', marginBottom: '8px' }}>
                        פרטי ההזמנה
                    </Grid>
                    <Grid container item lg={12} className="personal_popup_tittle" justifyContent="flex-start" alignContent="flex-start" style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                        בחרו את סוג המשלוח {/*<div style={{ marginRight: "5px", backgroundImage: `url(${questionMark})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '16px' }}></div>*/}
                    </Grid>
                </Grid>

                <Box className='flex-rtl align-items-center mb-1rem' style={{ width: '100%' }}>
                    <Button className={`btn-radio  ${value.homeDelivary ? 'active active_home' : ''}`} onClick={() => setValue(prev => ({ ...prev, homeDelivary: true }))}>
                        {getTotalHmdCond() ? `משלוח עד הבית + 4$` :`משלוח עד הבית`}
                    </Button>
                    <Button disabled={checkWeight()} className={`btn-radio ${!value.homeDelivary ? 'active active_home' : ''}`} onClick={() => setValue(prev => ({ ...prev, homeDelivary: false }))}>
                        משלוח לנקודת איסוף
                    </Button>

                </Box>
                {value.homeDelivary && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh' }}>
                    {myAdds.length > 0 && <Grid container item lg={12} className="personal_popup_tittle" justifyContent="flex-start" alignContent="flex-start" style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                        בחר כתובת :{/*<div style={{ marginRight: "5px", backgroundImage: `url(${questionMark})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '16px' }}></div>*/}
                    </Grid>}
                    {myAdds.length > 0 && <Grid container item lg={7.3} justifyContent="flex-start">
                        <SelectField
                            className='text-field'
                            parentClassName={'select-field width-50 mar_10'}
                            dir="rtl"
                            required
                            value={value.addId}
                            onChange={event => {
                                setValue(prev => ({ ...prev, addId: event.target.value }));
                                var add = myAdds.find(u => u.id === event.target.value);
                                //setValue(prev => ({ ...prev, orgTotalCost: value.totalCost }));
                                setValue(prev => ({ ...prev, addPhone: add?.addPhone, addName: add?.addName, cityNum: add.city, streetNum: add.street, buildingNum: add.building, ncodeNum: add.zipCode }))

                            }}
                            options={myAdds}
                            islabelAnimate={false}
                            isPrefix
                            isNumeric={true}
                            lg={7}
                            sx={{ mr: "0", maxWidth: "none !important" }}
                        />
                    </Grid>}
                    <Grid item sm={7} xs={8}>
                        <TextField
                            className='text-field short'
                            required
                            lg={6}
                            value={value.addName}
                            onChange={event => setValue(prev => ({ ...prev, addName: event.target.value }))}
                            label="שם *"
                            islabelAnimate={false}
                        />

                    </Grid>
                    <Grid item sm={7} xs={8}>
                        <TextField
                            className='text-field short'
                            required
                            lg={6}
                            value={value.addPhone}
                            onChange={event => setValue(prev => ({ ...prev, addPhone: event.target.value }))}
                            label="מספר נייד *"
                            islabelAnimate={false}
                            type="number"
                        />
                        {!validatePhone(value.addPhone) && value.addPhone && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                            מספר טלפון לא תקין
                        </Box>
                        }
                    </Grid>
                    <Grid item sm={7} xs={8}>
                        <TextField
                            className='text-field short'
                            required
                            lg={6}
                            value={value.cityNum}
                            onChange={event => setValue(prev => ({ ...prev, cityNum: event.target.value }))}
                            label="עיר *"
                            islabelAnimate={false}
                        />
                        {!validateHebrewText(value.cityNum) && value.cityNum && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                            המערכת תומכת בכתובת בעברית
                        </Box>
                        }
                    </Grid>
                    <Grid item sm={7} xs={8}>
                        <TextField
                            className='text-field short'
                            required
                            lg={6}
                            value={value.streetNum}
                            onChange={event => setValue(prev => ({ ...prev, streetNum: event.target.value }))}
                            label="רחוב *"
                            islabelAnimate={false}
                        />
                        {!validateHebrewText(value.streetNum) && value.streetNum && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                            המערכת תומכת בכתובת בעברית
                        </Box>
                        }
                    </Grid>
                    <Grid item sm={7} xs={8}>

                        <TextField
                            className='text-field short'
                            required
                            lg={6}
                            value={value.buildingNum}
                            onChange={event => setValue(prev => ({ ...prev, buildingNum: event.target.value }))}
                            label="מספר *"
                            islabelAnimate={false}
                        />
                    </Grid>
                    <Grid item sm={7} xs={8}>
                        <TextField
                            className='text-field short'
                            required
                            lg={6}
                            value={value.ncodeNum}
                            onChange={event => setValue(prev => ({ ...prev, ncodeNum: event.target.value }))}
                            label="מיקוד"
                            type="number"
                            islabelAnimate={false}
                        />
                        {value.ncodeNum.length > 7 && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                            Zip code should be less than 8 degits
                        </Box>
                        }
                    </Grid>
                </Grid>}
                {!value.homeDelivary && <Grid container item lg={12} justifyContent=" space-around" alignContent="flex-start" flexWrap="nowrap" sx={{ mt: '2vh' }}>
                    <SelectField
                        className='text-field'
                        required
                        value={value.cityId}
                        lg={3}
                        sx={{ margin: "2px" }}
                        style={{ direction: "rtl" }}
                        onChange={event => {
                            var city = cities.find(u => u.id === event.target.value);
                            if (!city) {
                                setAddressIsNotProper(true);
                                setValue(prev => ({ ...prev, cityId: 0, streetId: 0, buildingId: 0 }))
                                return;
                            }
                            setAddressIsNotProper(false);
                            var t = [];
                            city?.streetList.map((d, index) => {
                                t.push({
                                    id: index + 1,
                                    name: d.streetName,
                                    ncode: d.ncode,
                                    houseList: d.houseList
                                });
                            })
                            t.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setStreets(t);
                            setValue(prev => ({ ...prev, cityId: event.target.value, streetId: t[0].id, buildingId: 0 }))
                            //var street = t.find(u => u.id === t[0].id);
                            var t2 = [];
                            t[0]?.houseList.map((d, index) => {
                                t2.push({
                                    id: index + 1,
                                    name: d
                                });
                            })
                            t2.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setBuildings(t2);
                            setValue(prev => ({ ...prev, buildingId: t2[0]?.id }))
                        }}
                        options={cities}
                        islabelAnimate={false}
                        parentClassName={'select-field'}
                        label="עיר"
                    />
                    <SelectField
                        className='text-field'
                        required
                        value={value.streetId}
                        lg={3}
                        sx={{ margin: "2px" }}
                        onChange={event => {
                            var street = streets.find(u => u.id === event.target.value);
                            if (!street) {
                                setAddressIsNotProper(true);
                                setValue(prev => ({ ...prev, cityId: 0, streetId: 0, buildingId: 0 }))
                                return;
                            }
                            setAddressIsNotProper(false);
                            var t = [];
                            street?.houseList.map((d, index) => {
                                t.push({
                                    id: index + 1,
                                    name: d
                                });
                            })
                            t.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });
                            setBuildings(t);

                            setValue(prev => ({ ...prev, streetId: event.target.value, buildingId: t[0]?.id }))
                        }}
                        options={streets}
                        islabelAnimate={false}
                        parentClassName={'select-field'}
                        label="רחוב"
                    />
                    <SelectField
                        className='text-field'
                        required
                        value={value.buildingId}
                        lg={3}
                        sx={{ margin: "2px" }}
                        onChange={event => {
                            var building = buildings.find(u => u.id === event.target.value);
                            if (!building) {
                                setAddressIsNotProper(true);
                                setValue(prev => ({ ...prev, cityId: 0, streetId: 0, buildingId: 0 }))
                                return;
                            }
                            setAddressIsNotProper(false);
                            setValue(prev => ({ ...prev, buildingId: event.target.value }));
                        }}
                        options={buildings}
                        islabelAnimate={false}
                        parentClassName={'select-field'}
                        label="חנות"
                    />
                </Grid>}
                {addressIsNotProper && <Grid container item lg={12} justifyContent="space-evenly" alignContent="flex-start" sx={{ mt: '2vh', color: "#d70007" }}>
                    { `הפרטים של נקודת החלוקה לא תקינים`}
                </Grid>}
                {false && <Grid container item lg={12} justifyContent="space-evenly" alignContent="flex-start" sx={{ mt: '2vh' }}>


                    {/*<SelectField
                        className='text-field'
                        required
                        value={value.ncodeId}
                        lg={6}
                        onChange={event => setValue(prev => ({ ...prev, ncodeId: event.target.value }))}
                        options={ncodes}
                        islabelAnimate={false}
                        parentClassName={'prefix-select width-30'}
                    />*/}
                </Grid>}
                {<Grid container item lg={12} flexWrap="nowrap" justifyContent="space-evenly" alignContent="center" alignItems="center" sx={{ mt: '2vh' }}>
                    <Grid item sm={8.2} xs={8}>
                        <TextField
                            className='text-field short'
                            required
                            lg={8}
                            value={value.couponText}
                            onChange={event => {
                                setValue(prev => ({ ...prev, couponText: event.target.value }));
                                checkCoupon(event.target.value)
                                // calculate((value.subcategory - 1), event.target.value);
                            }}
                            //onBlur={() => { calculate(value.subcategory - 1, value.couponText); }}
                            onBlur={() => {
                               // checkCoupon(value.couponText)
                            }}
                            label="קופון :"
                            islabelAnimate={false}
                        />
                    </Grid>
                    {<Grid item sm={6} xs={4} className="coupen_action">
                        {/*{value.couponText &&<Button variant='contained' sx={{ background: !checkCoupon() ? '#d70007 !important' : '#00d707 !important' }} onClick={() => {*/}
                        {value.couponText && <Button variant='contained' sx={{ background: !validCoupon ? '#d70007 !important' : '#00d707 !important' }} onClick={() => {
                            calculate(value.subcategory - 1, value.couponText, couponValue);
                        }} >
                            {!validCoupon ? <ClearIcon /> : <DoneIcon />}
                        </Button>
                        }
                    </Grid>
                    }
                </Grid>}
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start">

                    <Grid container className='label-wrapper' >
                        <Grid className="popup_tittle sm" style={{ fontSize: '23px', marginBottom: '8px' }}>
                            סיכום
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh !important' }}>
                        <Grid container item xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            { `מידות ס״מ`}
                        </Grid>
                        <Grid container item xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            {getDimensions()}
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ }}>
                        <Grid container item xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            { `משקל לחיוב`}
                        </Grid>
                        <Grid container item xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            {getChWeight()}
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ }}>
                        <Grid container item xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            סה״כ עלויות שילוח
                        </Grid>
                        <Grid container item xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            $  {Number(value.orgTotalCost).toFixed(2)}
                        </Grid>
                    </Grid>
                    {value.cD != 0 && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ }}>
                        <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            קופון
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            $ {Number(value.cD).toFixed(2)} -
                        </Grid>
                    </Grid>
                    }
                    {value.homeDelivary && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh !important' }}>
                        <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            משלוח עד הבית
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            $ {getTotalHmdCond()?4:0}
                        </Grid>
                    </Grid>}
                    {/*<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh !important' }}>
                        <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            מע״מ
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            $ {Number(value.vat).toFixed(2)}
                        </Grid>
                    </Grid>*/}
                    {/*<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh !important' }}>
                        <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            עמלת שרות ואגרת מכס
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" >
                            $ {Number(Number(value.purchaseTax) + Number(value.customsCharge) + Number(value.addCharges) + Number(value.fixedFee ? value.fixedFee:0)).toFixed(2)}
                        </Grid>
                        {(Number(selectedShipping?.params?.userPaymentData.height) > 120 || Number(selectedShipping?.params?.userPaymentData["length"]) > 120 || Number(selectedShipping?.params?.userPaymentData.width) > 120)&&<Grid container item lg={12} xs={12} justifyContent="flex-start" alignContent="flex-start" >
                            מידות חריגות של אחת מצלעות החבילה (מעל 120 ס"מ) תתווסף עלות של 35$.
                        </Grid>}
                    </Grid>*/}
                    <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start">
                        <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            מע"מ , עמלת שחרור ואגרת מכס
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" >

                            $ {Number(Number(value.vat ? value.vat : 0) + Number(value.purchaseTax ? value.purchaseTax : 0) + Number(value.fixedFee ? value.fixedFee : 0) + Number(value.customsCharge ? value.customsCharge : 0)).toFixed(2)}
                        </Grid><Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                            שירותים נוספים
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" >

                            $ {Number(value.addCharges ? value.addCharges : 0).toFixed(2)}
                        </Grid>
                        {(Number(selectedShipping?.params?.userPaymentData.height) > 110 || Number(selectedShipping?.params?.userPaymentData["length"]) > 110 || Number(selectedShipping?.params?.userPaymentData.width) > 110) && <Grid container item lg={12} xs={12} justifyContent="flex-start" alignContent="flex-start" >
                            מידות חריגות של אחת מצלעות החבילה (מעל 110 ס"מ) תתווסף עלות של 40$.
                        </Grid>}
                        
                    </Grid>
                    <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderTop: '1px solid gray', mt: '2vh !important' }}>
                        <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                            סה״כ שילוח ומיסים
                        </Grid>
                        <Grid container item lg={5} xs={5} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                            $ {
                                tempFunct()

                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box className='form-action'>
                {value.homeDelivary && <ActionButton disabled={!value.buildingNum || !value.cityNum || !validateHebrewText(value.cityNum) || !value.streetNum || !validateHebrewText(value.streetNum) || !value.addName || !value.addPhone || !validatePhone(value.addPhone)} className={'submit-action'} textLabel={'אישור והמשך תשלום'} onClick={() => {
                    if (value.homeDelivary) {
                        /*if (validateHebrewText(value.cityNum) || validateHebrewText(value.streetNum) || validateHebrewText(value.streetNum)) {
                            dispatch(snackbarToggle({ type: 'error', message: "  רק תווים עבריים מותרים" }));
                            return;
                        }*/
                        if (!value.buildingNum || !value.cityNum || !value.streetNum || !value.addName || !value.addPhone) {
                            dispatch(snackbarToggle({ type: 'error', message: "Please enter Address" }));
                            return;
                        } else if (value.ncodeNum.length > 7) {
                            dispatch(snackbarToggle({ type: 'error', message: "Please check zip code" }));
                            return;
                        }
                        handleUserData()
                    }
                    if (!value.homeDelivary) {
                        if (!value.cityId || !value.buildingId || !value.streetId) {
                            dispatch(snackbarToggle({ type: 'error', message: "Please enter Address" }));
                            return;
                        }
                        handleUserData()
                    }
                    setActiveScreen(4);

                }} />}
                {!value.homeDelivary && <ActionButton disabled={!value.cityId || !value.buildingId || !value.streetId} className={'submit-action'} textLabel={'אישור והמשך תשלום'} onClick={() => {
                    if (value.homeDelivary) {
                        if (!value.buildingNum || !value.cityNum || !value.streetNum || !value.addName || !value.addPhone) {
                            dispatch(snackbarToggle({ type: 'error', message: "Please enter Address" }));
                            return;
                        } else if (value.ncodeNum.length > 7) {
                            dispatch(snackbarToggle({ type: 'error', message: "Please check zip code" }));
                            return;
                        }
                        handleUserData()
                    }
                    if (!value.homeDelivary) {
                        if (!value.cityId || !value.buildingId || !value.streetId) {
                            dispatch(snackbarToggle({ type: 'error', message: "Please enter Address" }));
                            return;
                        }
                        handleUserData()
                    }
                    setActiveScreen(4);

                }} />}
            </Box>
            {/*<Grid container item lg={12} style={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }} justifyContent="center" alignContent="center" className="screen-footer scarlet" onClick={() => { }}>
                שמירת פרטים וביצוע תשלום מאוחר יותר
            </Grid>*/}
        </Box>)

    const screen_d = (
        <Box className="reg_popup_data" >

            <Grid item lg={12} className="popup_tittle" justifyContent="center" sx={{ marginTop: '20px', direction: "rtl", padding: 0 }}>
                <span className="\-">
                    רגע לפני התשלום, שירותים
                    מומלצים שאסור לפספס!
                </span>
            </Grid>
            {/*<Grid item lg={12} className="personal_popup_tittle" justifyContent="center" sx={{ marginTop: '5vh !important', fontSize: '1.7vh !important', fontWeight: 'bold !important' }}>
                שירות אריזה מחדש- חיסכון בעלות המשלוח
            </Grid>*/}
            {/*<Grid item lg={12} className="personal_popup_tittle" justifyContent="center" sx={{ marginTop: '0.2vh !important', fontSize: '1.7vh !important' }}>
                על ידי אריזה של המשלוח באופן יעיל יותר, וצמצום נפח האריזה ניתן לחסוך
                בעלויות השילוח. האריזה נעשית בצורה מקצועית אשר תשמור על המוצרים
                שלכם בעת השילוח. השירות ניתן בעלות של 4$.
            </Grid>*/}
            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderRadius: "20px", backgroundColor: "#d7000742", mt: '2vh !important', direction: 'rtl' }}>
                <Grid container item lg={7} xs={7} alignItems="center" justifyContent="flex-start" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingRight: '25px' }}>
                    <Grid container item lg={12} style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', lineHeight: '24px' }}>
                        <DoneIcon className='red-tick' style={{ color: '#d70007', paddingLeft: '5px' }} /> <span> מחיר המשלוח מיסים ואגרות :</span>
                    </Grid>
                </Grid>
                <Grid container item lg={5} xs={5} alignItems="center" justifyContent="flex-end" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingLeft: '25px' }}>
                    $ {Number(value.transportVal).toFixed(2)}
                </Grid>
            </Grid>
            {false && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderRadius: "20px", mt: '2vh !important', direction: 'rtl', border: '1px solid #e9e9e9' }}>
                <Grid container item lg={7} xs={7} alignItems="center" justifyContent="flex-start" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingRight: '25px' }}>
                    מחיר המשלוח  לאחר אריזה מחדש:
                </Grid>
                <Grid container item lg={5} xs={5} alignItems="center" justifyContent="flex-end" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingLeft: '25px' }}>
                    0 $
                </Grid>
            </Grid>
            }
            {false && <Grid container item lg={12} justifyContent="flex-end" alignContent="flex-end" className="check-box" sx={{ mb: '3.64vh' }}>
                <Button variant='contained' sx={{ mt: 2, width: '30%', background: '#d70007 !important', borderRadius: '25.5px', height: '4.72vh', border: 'none' }} onClick={() => { }} className="screen-footer-btn">
                    הוספה
                </Button>
            </Grid>
            }
            {/* <Grid item lg={12} className="personal_popup_tittle" justifyContent="center" sx={{ marginTop: '0.2vh !important',direction:"rtl", fontSize: '1.7vh !important', fontWeight: 'bold !important' }}>
                ביטוח מוצר + {Number(0).toFixed(2)} $
             </Grid>*/}
            <Grid item lg={12} className="personal_popup_tittle" justifyContent="center" sx={{ marginTop: '0.2vh !important' }}>
                <span className="\-">
                    מומלץ לבטח מוצרים לכיסוי נזק פיזי שעלול להיגרם לחבילה או אובדן במהלך השילוח.
                </span>
            </Grid>
            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderRadius: "20px", backgroundColor: "#d7000742", mt: '2vh !important', direction: 'rtl' }}>
                <Grid container item lg={7} xs={7} alignItems="center" justifyContent="flex-start" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingRight: '25px' }}>
                    <Grid container item lg={12} style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', lineHeight: '24px' }}>
                        {value.isInsurence && <DoneIcon className='red-tick' style={{ color: '#d70007', paddingLeft: '5px' }} />}<span>ביטוח מוצר</span>
                    </Grid>
                </Grid>

                <Grid container item lg={5} xs={5} alignItems="center" justifyContent="flex-end" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingLeft: '25px' }}>
                    $ {Number(value.insurence).toFixed(2)}
                </Grid>
            </Grid>
            
            <Grid container item lg={12} justifyContent="flex-end" alignContent="flex-end" className="check-box" sx={{ mb: '3.64vh' }}>
               {/* disabled={isInsurenceDisabled}*/}
                {(value.isInsurence && !isInsurenceDisabled) && <Button variant='contained' sx={{ mt: 2, width: '30%', background: 'white !important', borderRadius: '25.5px', height: '4.72vh', border: '1px solid #d70007', color: "#d70007" }} onClick={() => {
                    /*Insurance button*/
                    setOpenInsuranceDialog(true);
                    /*setValue(prev => ({ ...prev, isInsurence: false }));*/
                }} className="screen-footer-btn">
                    הסרה
                </Button>
                }
                {!value.isInsurence && <Button variant='contained' sx={{ mt: 2, width: '30%', background: '#d70007 !important', borderRadius: '25.5px', height: '4.72vh', border: '1px solid #d70007' }} onClick={() => {
                    setOpenInsuranceDialog(true);
                    /*setValue(prev => ({ ...prev, isInsurence: true }));*/
                }} className="screen-footer-btn">
                    הוספה
                </Button>
                }
            </Grid>
            {/*<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderRadius: "20px", backgroundColor: "#d7000742", mt: '2vh !important', direction: 'rtl' }}>
                <Grid container item lg={7} xs={7} alignItems="center" justifyContent="flex-start" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingRight: '25px' }}>
                    <Grid container item lg={12} style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', lineHeight: '24px' }}>
                        {value.isCertificate && <DoneIcon className='red-tick' style={{ color: '#d70007', paddingLeft: '5px' }} />}<span> האם ברצונך שרד-בוקס יפיקו עבורך אישור תחבורה ?</span>
                    </Grid>
                </Grid>

                <Grid container item lg={5} xs={5} alignItems="center" justifyContent="flex-end" alignContent="center" sx={{ fontWeight: 'bold', height: '50px', paddingLeft: '25px' }}>
                    {Number(value.fixedFee ? value.fixedFee : 0).toFixed(2)} $
                </Grid>
            </Grid>
            <Grid container item lg={12} justifyContent="flex-end" alignContent="flex-end" className="check-box" sx={{ mb: '3.64vh' }}>
                */}{/* disabled={isInsurenceDisabled}*/}{/*
                {value.isCertificate && <Button variant='contained' sx={{ mt: 2, width: '30%', background: 'white !important', borderRadius: '25.5px', height: '4.72vh', border: '1px solid #d70007', color: "#d70007" }} onClick={() => {

                    setValue(prev => ({ ...prev, isCertificate: false }));
                }} className="screen-footer-btn">
                    לא
                </Button>
                }
                {!value.isCertificate && <Button variant='contained' sx={{ mt: 2, width: '30%', background: '#d70007 !important', borderRadius: '25.5px', height: '4.72vh', border: '1px solid #d70007' }} onClick={() => {

                    setValue(prev => ({ ...prev, isCertificate: true }));
                }} className="screen-footer-btn">
                    כן
                    
                </Button>
                }
            </Grid>*/}

            <Box className='form-action' style={{ marginTop: '40px' }}>
                <ActionButton className={'submit-action'} textLabel={'אישור והמשך תשלום'} onClick={() => { setActiveScreen(5) }} />
            </Box>
        </Box>);
    var getTotal = function () {
        var wt = Number(selectedShipping.params?.userPaymentData?.weight);
        var h = Number(selectedShipping.params?.userPaymentData?.height);
        var l = Number(selectedShipping.params?.userPaymentData ? selectedShipping.params?.userPaymentData["length"] : 0);
        var w = Number(selectedShipping.params?.userPaymentData?.width);

        var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;

        var twt = Number(chargWt > wt ? chargWt : wt);
        var ins = value.isInsurence ? Number(value.insurence) : 0;
        var hmD = value.homeDelivary ? 4 : 0;
        if (twt > 4) hmD = 0;
        return Number(Number(value.totalInvoice) + ins + (hmD * 1.17)).toFixed(2);
    }
    const addStringDataFormanifest = (str1, str2) => {
        var n1 = str1 ? str1 : 0;
        var n2 = str2 ? str2 : 0;
        var num1 = Number(n1) ? Number(n1) : 0;
        var num2 = Number(n2) ? Number(n2) : 0;
        return Number(num1 + num2).toFixed(2);
    }
    const getExchangeRateByTrans = (nisVal, val) => {
        var v = Number(nisVal) / Number(val);
        if (!v) {
            v = 3.5
        }
        return Number(v).toFixed(2)
    }
    var getTotalNIS = function () {
        /*value.fixedFee_nis = "0.00";
        value.transportVal_nis = "437.55";
        value.transportVal = 110.16;
        value.consolidationCharges = 39;
        value.addCharges_nis = "178.74";
        var num1 = addStringDataFormanifest(value.fixedFee_nis, value.addCharges_nis);
        var num2 = getExchangeRateByTrans(Number(value.transportVal_nis), Number(value.transportVal))
        var num3 = Number(value.consolidationCharges) * Number(num2);
        var num = Number(Number(num1) + Number(num3)).toFixed(2)*/
        var total = getTotal();
        var mul = 3.25;
        if (exchangeRate.rates) {
            mul = exchangeRate?.rates?.ILS / exchangeRate?.rates?.USD
        }
        //return num;
        return Number(total * mul).toFixed(2)
    }

    function insert(main_string, ins_string, pos) {
        if (typeof (pos) == "undefined") {
            pos = 0;
        }
        if (typeof (ins_string) == "undefined") {
            ins_string = '';
        }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    }
    const screen_e = (
        <Box className="reg_popup_data">
            {progressSteps(3)}


            <Grid container item lg={12} className='label-wrapper' style={{ marginTop: '24px' }}>
                <Grid className="popup_tittle sm" style={{ fontSize: '23px', marginBottom: 0 }}>
                    תשלום
                </Grid>
                <Grid></Grid>
            </Grid>
            <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>

                {(userInfo?.user?.setting?.TranzilaTK && (userInfo?.user?.setting?.expdate || (userInfo?.user?.setting?.expdateTK && userInfo?.user?.setting?.expdateTK !== "expdate"))) && <Box style={{ marginRight: "10px", height: '51px', padding: '18px 32px 20px', border: 'solid 1px #f0f0f2', backgroundColor: '#f6f6f6' }} className={value.payByToken ? 'Business selected_acc' : 'Business'} onClick={() => { setValue(prev => ({ ...prev, payByToken: true })) }}>
                    פרטי אשראי
                </Box>
                }
                <Box style={{ height: '51px', padding: '18px 32px 20px', border: 'solid 1px #f0f0f2', backgroundColor: '#f6f6f6' }} className={!value.payByToken ? 'Business selected_acc' : 'Business'} onClick={() => { setValue(prev => ({ ...prev, payByToken: false })) }}>
                    {!(userInfo?.user?.setting?.TranzilaTK && (userInfo?.user?.setting?.expdate || (userInfo?.user?.setting?.expdateTK && userInfo?.user?.setting?.expdateTK !== "expdate"))) ? 'תשלום באשראי' : "כרטיס אשראי"}
                </Box>
            </Grid>

            {value.payByToken && <Grid container justifyContent="space-between" sx={{ mt: 2 }}>

                <Grid container justifyContent="space-between" flexWrap={'nowrap'} sx={{ mt: 0 }}>
                    <img alt="" src={cardFromType(userInfo?.user?.setting?.TranzilaTK)} className="flag_img" style={{ paddingRight: '5px', height: "56px" }} />
                    <TextField
                        className='text-field-disabled'
                        required
                        value={`visa: ${insert(userInfo?.user?.setting?.ccno, "-", userInfo?.user?.setting?.ccno.length - 4)}`}
                        onChange={() => { }}
                        label="מספר כרטיס אשראי"
                        readOnly={true}
                    />

                </Grid>
                {!userInfo.user.setting?.profileId && <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                    <TextField
                        className='text-field'
                        required
                        value={value.profileId}
                        onChange={event => setValue(prev => ({ ...prev, profileId: event.target.value }))}
                        label='תעודת זהות של בעל החשבון'
                    />
                </Grid>
                }
            </Grid>}
            {!value.payByToken && <><Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <TextField
                    className='text-field'
                    required
                    value={value.cc_name}
                    onChange={event => {
                        setValue(prev => ({ ...prev, cc_name: event.target.value }));
                        setinValidCcName(true)
                    }}
                    onBlur={() => { setinValidCcName(true) }}
                    label='שם בעל הכרטיס'
                />
                {inValidCcName && !value.cc_name && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                    Invalid Name
                </Box>
                }
                <TextField
                    className='text-field'
                    required
                    value={value.myid}
                    onChange={event => {
                        setValue(prev => ({ ...prev, myid: event.target.value }));
                        if (!validateMyId(event.target.value)) {
                            setPaymentValidation(prev => ({ ...prev, bId: true }));
                            // if (value.myid)
                            // dispatch(snackbarToggle({ type: 'error', message: "Invalid Id." }));

                            return;
                        } else {
                            setPaymentValidation(prev => ({ ...prev, bId: false }));
                        }
                    }}
                    onBlur={event => {
                        if (!validateMyId(value.myid)) {
                            setPaymentValidation(prev => ({ ...prev, bId: true }));
                            // if (value.myid)
                            // dispatch(snackbarToggle({ type: 'error', message: "Invalid Id." }));

                            return;
                        } else {
                            setPaymentValidation(prev => ({ ...prev, bId: false }));
                        }
                    }}
                    label="תעודת זהות של בעל הכרטיס"
                    type="number"
                />
                {!validateMyId(value.myid) && value.myid && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                    Invalid ID
                </Box>
                }
                <Grid container justifyContent="space-between" flexWrap={'nowrap'} sx={{ mt: 0 }}>

                    <img alt="" src={cardFromNumber(value.ccno)} className="flag_img" style={{ paddingRight: '5px', height: "56px" }} />
                    <TextField
                        className='text-field'
                        required
                        value={value.ccno}
                        onChange={event => {
                            setValue(prev => ({ ...prev, ccno: event.target.value }));
                            setInValidCard(false);
                            setValue(prev => ({ ...prev, cctype: cardTextFromNumber(event.target.value) }))
                            validateCardNumber(event.target.value, function (bSucc, bIsra) {
                                if (!bSucc) {
                                    if (!bIsra)
                                        setPaymentValidation(prev => ({ ...prev, bCC: true }));
                                    else
                                        setPaymentValidation(prev => ({ ...prev, bCC: false }));
                                    if (value.ccno) {
                                        //dispatch(snackbarToggle({ type: 'error', message: "Invalid card." }));
                                        if (!bIsra)
                                            setInValidCard(true)
                                        return;
                                    }
                                } else {
                                    setPaymentValidation(prev => ({ ...prev, bCC: false }));
                                }
                                setBIsra(bIsra);
                            })
                        }}
                        label="מספר כרטיס אשראי"
                        type="number"
                        onBlur={event => {

                            setValue(prev => ({ ...prev, cctype: cardTextFromNumber(value.ccno) }))
                            validateCardNumber(value.ccno, function (bSucc, bIsra) {
                                if (!bSucc) {
                                    if (!bIsra)
                                        setPaymentValidation(prev => ({ ...prev, bCC: true }));
                                    else
                                        setPaymentValidation(prev => ({ ...prev, bCC: false }));
                                    if (value.ccno) {
                                        //dispatch(snackbarToggle({ type: 'error', message: "Invalid card." }));
                                        if (!bIsra)
                                            setInValidCard(true)
                                        return;
                                    }
                                } else {
                                    setPaymentValidation(prev => ({ ...prev, bCC: false }));
                                }
                                setBIsra(bIsra);
                            })
                        }}
                    />

                </Grid>
                {inValidCard && value.ccno && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                    Invalid card
                </Box>
                }
            </Grid>

                <Grid container justifyContent="space-between" flexWrap={'nowrap'} sx={{ mt: 2 }}>

                    <TextField
                        className='text-field'
                        required
                        value={value.mycvv}
                        onChange={event => {
                            setValue(prev => ({ ...prev, mycvv: event.target.value }));
                            if (event.target.value.length > 5) {
                                setPaymentValidation(prev => ({ ...prev, bCVV: true }));
                                return;
                            }
                            if (!checkCvvAndNumber(event.target.value, value.ccno)) {
                                setPaymentValidation(prev => ({ ...prev, bCVV: true }));
                                //dispatch(snackbarToggle({ type: 'error', message: "Invalid CVV." }));
                                return;
                            } else {
                                setPaymentValidation(prev => ({ ...prev, bCVV: false }));
                            }
                        }}
                        label="CVV"
                        parentClassName={'prefix-select width-30 no-input-pad'}
                        type="number"
                        onBlur={event => {
                            if (!checkCvvAndNumber(value.mycvv, value.ccno)) {
                                setPaymentValidation(prev => ({ ...prev, bCVV: true }));
                                //dispatch(snackbarToggle({ type: 'error', message: "Invalid CVV." }));
                                return;
                            } else {
                                setPaymentValidation(prev => ({ ...prev, bCVV: false }));
                            }
                        }}
                    />

                    <Box sx={{ direction: "rtl" }}>
                        <InputLabel sx={{ mr: 1.5 }}>
                            שנה
                        </InputLabel>
                        <SelectField
                            className='text-field padding_17'
                            required
                            value={value.selectedYear}
                            lg={12}
                            isNumeric={true}
                            onChange={event => {
                                var y = cc_years.find(u => u.id === event.target.value)?.name || "22";
                                var m = cc_months.find(u => u.id === value.selectedMonth)?.name || false;

                                if (!validateYear(y)) {
                                    setPaymentValidation(prev => ({ ...prev, bYear: true }));
                                    // dispatch(snackbarToggle({ type: 'error', message: "Invalid Year." }));
                                    return;
                                } else {
                                    setPaymentValidation(prev => ({ ...prev, bYear: false }));
                                }
                                setValue(prev => ({ ...prev, selectedYear: event.target.value }))
                                if (m) {
                                    if (!validateMonth(y, m)) {
                                        setPaymentValidation(prev => ({ ...prev, bMonth: true }));
                                        // dispatch(snackbarToggle({ type: 'error', message: "Invalid Month." }));
                                        return;
                                    } else {
                                        setPaymentValidation(prev => ({ ...prev, bMonth: false }));
                                    }
                                } else {
                                    setPaymentValidation(prev => ({ ...prev, bMonth: true }));
                                }

                            }}
                            sx1={{ padding: "17px" }}
                            sx={{ mt: -3.5 }}
                            options={cc_years}
                            islabelAnimate={false}
                            label="שנה"
                            parentClassName={'prefix-select width-100'}
                        />
                    </Box>
                    <Box sx={{ direction: "rtl" }}>
                        <InputLabel sx={{ mr: 1.5 }}>
                            חודש
                        </InputLabel>
                        <SelectField
                            className='text-field padding_17'
                            required
                            value={value.selectedMonth}
                            lg={3}
                            sx1={{ padding: "17px" }}
                            sx={{ mt: -3.5 }}
                            isNumeric={true}
                            onChange={event => {

                                setValue(prev => ({ ...prev, selectedMonth: event.target.value }))
                                var y = cc_years.find(u => u.id === value.selectedYear)?.name || "22";
                                var m = cc_months.find(u => u.id === event.target.value)?.name || "01";
                                if (!validateMonth(y, m)) {
                                    setPaymentValidation(prev => ({ ...prev, bMonth: true }));
                                    // dispatch(snackbarToggle({ type: 'error', message: "Invalid Month." }));
                                    return;
                                } else {
                                    setPaymentValidation(prev => ({ ...prev, bMonth: false }));
                                }
                            }}
                            options={cc_months}
                            islabelAnimate={false}
                            label="חודש"
                            parentClassName={'prefix-select width-100'}
                        />
                    </Box>
                </Grid>
                {!userInfo.user.setting?.profileId && <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                    <TextField
                        className='text-field'
                        required
                        value={value.profileId}
                        onChange={event => setValue(prev => ({ ...prev, profileId: event.target.value }))}
                        label='תעודת זהות של בעל החשבון'
                    />
                </Grid>
                }
                {(!checkCvvAndNumber(value.mycvv, value.ccno) || value.mycvv.length > 5) ? <Box className='col-md-12' style={{ direction: "ltr", color: "red", top: "-20px", width: "30%" }}>
                    Invalid CVV
                </Box> : <Box className='col-md-12' style={{ direction: "ltr", color: "red", top: "-20px", width: "30%" }}>

                </Box>
                }
                {paymentValidation.bYear ? <Box className='col-md-12' style={{ direction: "ltr", color: "red", top: "-20px", width: "30%", marginLeft: "12%" }}>
                    Invalid Year
                </Box> : <Box className='col-md-12' style={{ direction: "ltr", color: "red", top: "-20px", width: "30%", marginLeft: "12%" }}>

                </Box>
                }
                {paymentValidation.bMonth ? <Box className='col-md-12' style={{ direction: "ltr", color: "red", top: "-20px", width: "22%", marginLeft: "5%" }}>
                    Invalid Month
                </Box> : <Box className='col-md-12' style={{ direction: "ltr", color: "red", top: "-20px", width: "30%" }}>

                </Box>
                }
                <Grid container justifyContent="space-between" flexDirection="row-reverse" flexWrap={'nowrap'} sx={{ mt: 2 }}>
                    <Box container className='checkboxs'>
                        <FormControlLabel dir='rtl' key={uuidv4()} control={< Checkbox checked={value?.rememberPayment} onChange={event => setValue(prev => ({ ...prev, rememberPayment: event.target.checked }))} />} label={"שמור פרטי כרטיס"} />
                    </Box>
                </Grid>
            </>}
            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh', direction: 'rtl' }}>
                {selectedShipping?.parentId != 0 ? <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        סה״כ עלויות שילוח (כולל עלויות מיסי מדינה ואגרות )
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                        $ {Number(Number(value.totalInvoice ? value.totalInvoice : 0) - Number(value.addCharges ? value.addCharges : 0)).toFixed(2)}
                    </Grid>
                </Grid>:<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        סה״כ עלויות שילוח (כולל עלויות מיסי מדינה ואגרות )
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                            $ {Number(value.totalInvoice ? value.totalInvoice : 0).toFixed(2)}
                    </Grid>
                </Grid>}
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        שירותים נוספים
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                        $ {Number(value.addCharges ? value.addCharges : 0).toFixed(2)}
                    </Grid>
                </Grid>
                {/*<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        סה״כ עלויות שילוח (כולל עלויות מיסי מדינה ואגרות )
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                        $  {Number(value.totalInvoice).toFixed(2)}
                    </Grid>
                </Grid>
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        שירותים נוספים
                    </Grid>
                </Grid>*/}
                {value.isInsurence && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                        ביטוח מוצר
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                        $ {Number(value.insurence).toFixed(2)} 
                    </Grid>
                </Grid>
                }
                {value.homeDelivary && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                        שילוח עד הבית + מע״מ
                    </Grid>
                    {getTotalHmdCond() ? <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                        $ {4.68}
                    </Grid> : <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                        $ {0}
                    </Grid>}
                </Grid>
                }
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderTop: '1px solid gray', mt: '2vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        סה״כ לתשלום
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        $ {getTotal()} 
                    </Grid>
                </Grid>
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', borderTop: '1px solid gray' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        { `תשלום בשקלים` }
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        ₪ {getTotalNIS()}
                    </Grid>
                </Grid>
                {/*<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderTop: '1px solid gray', mt: '2vh !important' }}>
                    <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                       Certificate
                    </Grid>
                    <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        { }
                    </Grid>
                </Grid>*/}
            </Grid>
            {/* <Grid item lg={12} className="personal_popup_tittle" justifyContent="flex-end" sx={{ color: '#d70007 !important', marginTop: '3.2vh !important', fontSize: '1.7vh !important' }}>
                יש לך קוד קופון?
            </Grid>*/}
            {false && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-end" className="check-box" sx={{ mb: 2 }}>
                <FormControlLabel className="check-box" key={uuidv4()} control={< Checkbox checked={value?.isCashBack} onChange={event => setValue(prev => ({ ...prev, isCashBack: event.target.checked }))} />} label={"רוצה לשלם עם ה Cash Back שלי (יתרה נוכחית 12$)"} />
            </Grid>
            }
            {!isFileUploaded && <Loader />}
            <Box className='form-action' style={{ marginTop: '40px' }}>
                {/*{paymentValidation.bMonth.toString()} {paymentValidation.bYear.toString()} {paymentValidation.bCC.toString()} {paymentValidation.bCVV.toString()} {paymentValidation.bId.toString()}*/}
                {value.payByToken && <ActionButton disabled={!userInfo.user?.setting?.TranzilaTK || !isFileUploaded} className={'submit-action'} textLabel={'תשלום'} onClick={() => {
                    shipping_pay_token();
                }} />
                }
                {/*{paymentValidation.bMonth.toString()} {paymentValidation.bYear.toString()} {paymentValidation.bCC.toString()} {paymentValidation.bCVV.toString()} {paymentValidation.bId.toString()}*/}
                {!value.payByToken && <ActionButton disabled={!value.cc_name || paymentValidation.bMonth || paymentValidation.bYear || paymentValidation.bCC || paymentValidation.bCVV || paymentValidation.bId || !isFileUploaded} className={'submit-action'} textLabel={'תשלום'} onClick={() => {
                    shipping_pay();
                }} />}
            </Box>
        </Box>);

    const screen_f = (
        <Box className="reg_popup_data">
            <Grid container item lg={12} justifyContent="flex-end" alignContent="flex-end" className="personal_popup_tittle" sx={{ direction: 'rtl', mt: "8.7vh !important" }}>
                <Grid container item lg={4} className="" justifyContent="center">
                    <Grid item lg={12} className="" justifyContent="center">
                        <Button variant='contained' sx={{ border: "1px solid gray", color: "black", background: 'white !important', borderRadius: '25.5px', minWidth: '4.72vh', width: '4.72vh', minHeight: '4.72vh', height: '4.72vh' }} onClick={() => { setActiveScreen(screens[1].screen) }} className="screen-footer-btn">
                            <div style={{ marginTop: "25px", backgroundImage: `url(${checkMarkCopy})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '100%', height: '100%', }}></div>
                        </Button>
                    </Grid>
                    <Grid item lg={12} className="" justifyContent="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                        {selectedShipping?.consolidationStatus == 3 ? 'העלאת חשבוניות' : 'העלאת חשבונית'}
                    </Grid>

                </Grid>
                <Grid container item lg={4} className="" justifyContent="center">
                    <Grid item lg={12} className="" justifyContent="center">
                        <Button variant='contained' sx={{ border: "1px solid gray", color: "black", background: 'white !important', borderRadius: '25.5px', minWidth: '4.72vh', width: '4.72vh', minHeight: '4.72vh', height: '4.72vh' }} onClick={() => { setActiveScreen(screens[1].screen) }} className="screen-footer-btn">
                            <div style={{ marginTop: "25px", backgroundImage: `url(${checkMarkCopy})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '100%', height: '100%', }}></div>
                        </Button>
                    </Grid>
                    <Grid item lg={12} className="" justifyContent="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                        השלמת פרטי משלוח
                    </Grid>

                </Grid>
                <Grid container item lg={4} className="" justifyContent="center">
                    <Grid item lg={12} className="" justifyContent="center">
                        <Button variant='contained' sx={{ border: "1px solid gray", color: "black", background: 'white !important', borderRadius: '25.5px', minWidth: '4.72vh', width: '4.72vh', minHeight: '4.72vh', height: '4.72vh' }} onClick={() => { setActiveScreen(screens[2].screen) }} className="screen-footer-btn">
                            {screens[2].screen}
                        </Button>
                    </Grid>
                    <Grid item lg={12} className="" justifyContent="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                        תשלום
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={12} className="popup_tittle" justifyContent="flex-end" sx={{ marginTop: '5vh !important' }}>
                תשלום
            </Grid>
            <Grid container item lg={12} className="" justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
                <Grid item lg={6} className={true ? 'Business selected_acc' : 'Business'} onClick={() => { }}>
                    תשלום באשראי
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <Grid item lg={12} >
                    <TextField
                        className='text-field'
                        required
                        lg={12}
                        value={value.cardHolder}
                        onChange={event => setValue(prev => ({ ...prev, cardHolder: event.target.value }))}
                        label="שם מלא של בעל הכרטיס"
                    />
                </Grid>
                <Grid item lg={12} >
                    <TextField
                        className='text-field'
                        required
                        lg={12}
                        value={value.cardHolderId}
                        onChange={event => setValue(prev => ({ ...prev, cardHolderId: event.target.value }))}
                        label="תעודת זהות של בעל הכרטיס"
                    />
                </Grid>
                <Grid item lg={12} >
                    <TextField
                        className='text-field'
                        required
                        lg={12}
                        value={value.creditCardNum}
                        onChange={event => setValue(prev => ({ ...prev, creditCardNum: event.target.value }))}
                        label="מספר כרטיס אשראי"
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <Grid item lg={3} >
                    <TextField
                        className='text-field'
                        required
                        lg={12}
                        value={value.cvv}
                        onChange={event => setValue(prev => ({ ...prev, cvv: event.target.value }))}
                        label="CVV"
                    />
                </Grid>
                <SelectField
                    required
                    value={value.selectedYear}
                    lg={3}
                    onChange={event => setValue(prev => ({ ...prev, selectedYear: event.target.value }))}
                    label=''
                    options={sleeps}
                />
                <SelectField
                    required
                    value={value.selectedMonth}
                    lg={3}
                    onChange={event => setValue(prev => ({ ...prev, selectedMonth: event.target.value }))}
                    label=''
                    options={months}
                />
            </Grid>

            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh', direction: 'rtl' }}>

                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        סה״כ עלויות שילוח
                    </Grid>
                    <Grid container item lg={5} justifyContent="flex-end" alignContent="flex-start" >
                        81.90 $
                    </Grid>
                </Grid>
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        שירותים נוספים
                    </Grid>
                </Grid>
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} justifyContent="flex-start" alignContent="flex-start" >
                        משלוח עד הבית
                    </Grid>
                    <Grid container item lg={5} justifyContent="flex-end" alignContent="flex-start" >
                        4 $
                    </Grid>
                </Grid>
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                    <Grid container item lg={7} justifyContent="flex-start" alignContent="flex-start" >
                        חסכון
                    </Grid>
                    <Grid container item lg={5} justifyContent="flex-end" alignContent="flex-start" >
                        8-$
                    </Grid>
                </Grid>
                <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderTop: '1px solid gray', mt: '2vh !important' }}>
                    <Grid container item lg={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        סה״כ לתשלום
                    </Grid>
                    <Grid container item lg={5} justifyContent="flex-end" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                        77.90$
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" className="check-box" sx={{ mb: '3.64vh' }}>
                <Button variant='contained' sx={{ marginLeft: 2, fontWeight: 'normal !important', mt: 2, width: '50%', background: 'white !important', borderRadius: '25.5px', height: '4.72vh', border: '1px solid #80808029', color: "black" }} onClick={() => { }} className="screen-footer-btn">
                    הזן קוד קופון
                </Button>
                <Button variant='contained' sx={{ fontWeight: 'normal !important', mt: 2, width: '30%', background: 'white !important', borderRadius: '25.5px', height: '4.72vh', border: '1px solid #d70007', color: "#d70007" }} onClick={() => { }} className="screen-footer-btn">
                    הפעלה
                </Button>
            </Grid>
            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-end" className="check-box" sx={{ mb: 2 }}>
                <FormControlLabel className="check-box" key={uuidv4()} control={< Checkbox checked={value?.isCashBack} onChange={event => setValue(prev => ({ ...prev, isCashBack: event.target.checked }))} />} label={"רוצה לשלם עם ה Cash Back שלי (יתרה נוכחית 12$)"} />
            </Grid>
            <Grid container item lg={12} justifyContent="center" alignContent="center" className="check-box" >
                <Button variant='contained' sx={{ mt: 2, width: '90%', background: '#d70007 !important', borderRadius: '25.5px', height: '4.72vh', border: 'none' }} onClick={() => { setActiveScreen(screens[2].screen) }} className="screen-footer-btn">
                    תשלום
                </Button>
            </Grid>
        </Box>);

    const screen_H = (
        <Box className="reg_popup_data">
            
            <Grid container item lg={12} className='label-wrapper' style={{ marginTop: '24px', alignItems: 'center', flexDirection: 'column' }}>
                <Grid><DoneIcon style={{ fontSize: '24px', fontWeight: 'bolder', color: '#d70007' }} /></Grid>
                <Grid className="popup_tittle lg" >
                    {'התשלום בוצע בהצלחה!'} <br /> {`עוד קצת והחבילה אצלך :)`}
                </Grid>
                <Grid style={{ fontSize: '18px', lineHeight: '1.2', textAlign: 'center', marginTop: '10px' }}>
                    {`ניתן בכל עת לעקוב אחר סטטוס המשלוח `} <br /> {`באזור האישי`}
                </Grid>
            </Grid>


            <Box className='form-action' style={{ marginTop: '80px' }}>
                <ActionButton className={'submit-action'} textLabel={`חזרה לאיזור האישי`} onClick={() => { setIsPersonal(false) }} />
            </Box>
        </Box>);

    const screen_e_preview = (
        <Box className="reg_popup_data">
            {selectedShipping?.shippingStatus == 201 ?
                <Grid className="popup_tittle lg" style={{ marginTop: "250px" }} >{ `.צור קשר עם שרות לקוחות`}</Grid>
                :
                <Grid container itme style={{}}>
                    {!selectedShipping?.params?.statusLog && <Grid container className="screen-progress-steps">
                        {screens.map((val, index) =>
                            <Box key={val.screen + index} className={`progress-step done`}>
                                <Box className="step-circle">
                                    {val.screen}
                                </Box>
                                <Box className="step-title">
                                    {val.title}
                                </Box>
                            </Box>)}
                    </Grid>
                    }


                    <Grid container item lg={12} className='label-wrapper' style={{ marginTop: '24px' }}>
                        <Grid className="popup_tittle sm" style={{ fontSize: '23px', marginBottom: 0 }}>
                            תשלום
                        </Grid>
                    </Grid>
                    {selectedShipping?.params?.couponText && <Grid container item lg={12} className='label-wrapper' >
                        <Grid>Coupon: {selectedShipping?.params?.couponText || ""} </Grid>
                    </Grid>
                    }

                    <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh', direction: 'rtl' }}>
                        {selectedShipping?.parentId != 0 ? <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                            <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                סה״כ עלויות שילוח (כולל עלויות מיסי מדינה ואגרות )
                            </Grid>
                            <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                                {/* <> {Number(selectedShipping?.params?.totalInvoice || 0).toFixed(2)} $ </> */}
                                {Number(Number(selectedShipping?.params?.totalInvoice ? selectedShipping?.params?.totalInvoice : 0) - Number(selectedShipping?.params?.addCharges ? selectedShipping?.params?.addCharges : 0) - Number(selectedShipping?.params?.fixedFee ? selectedShipping?.params?.fixedFee : 0)).toFixed(2)} $
                            </Grid>
                        </Grid> : <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                            <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                סה״כ עלויות שילוח (כולל עלויות מיסי מדינה ואגרות )
                            </Grid>
                            <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                                    {Number(selectedShipping?.params?.finalAmount ? selectedShipping?.params?.finalAmount : 0).toFixed(2)} $
                            </Grid>
                        </Grid>}
                        {selectedShipping?.parentId != 0 && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                            <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                שירותים נוספים
                            </Grid>
                            <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                                {Number(selectedShipping?.params?.addCharges ? selectedShipping?.params?.addCharges : 0).toFixed(2)} $
                            </Grid>
                        </Grid>}
                        {selectedShipping?.params.isInsurence && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                            <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                                ביטוח מוצר
                            </Grid>
                            <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                                {Number(selectedShipping?.params?.insurence || 0).toFixed(2)} $
                            </Grid>
                        </Grid>
                        }
                        {(selectedShipping?.params.homeDelivary && selectedShipping?.parentId != 0) && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '1vh !important' }}>
                            <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" >
                                משלוח עד הבית
                            </Grid>
                            <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" >
                                {getTotalHmdCond() ? 4 : 0} $
                            </Grid>
                        </Grid>
                        }
                        <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderTop: '1px solid gray', mt: '2vh !important' }}>
                            <Grid container item lg={7} xs={7} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                סה״כ לתשלום
                            </Grid>
                            <Grid container item lg={5} xs={5} justifyContent="flex-end" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                {Number(selectedShipping?.params?.finalAmount).toFixed(2) || 0} $
                            </Grid>
                        </Grid>
                        {selectedShipping?.params?.statusLog && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '4vh !important' }}>
                            <Grid container item lg={12} className='label-wrapper' style={{ marginTop: '24px' }}>
                                <Grid className="popup_tittle sm" style={{ fontSize: '23px', marginBottom: 0 }}>
                                    פרטים
                                </Grid>
                            </Grid>
                            {true && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '3vh !important' }}>
                                <Grid flexWrap="nowrap" container className="screen-progress-steps" style={{ justifyContent: 'flex-start' }}>
                                    <Box className={`progress-step1 ${(getStatusById(selectedShipping.parentId == 0 ? 1 : 2) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
                                        <Box className={`step-circle ${(getStatusById(selectedShipping.parentId == 0 ? 1 : 2) ? 'red_back' : '')}`}>
                                            {getStatusById(selectedShipping.parentId == 0 ? 1 : 2) ? <FaCheck /> : 1}
                                        </Box>
                                        <Box className="step-title" style={{ textAlign: "inherit" }}>
                                            תשלום
                                        </Box>

                                    </Box>
                                    {/*<hr class="divider" />*/}
                                    <Box className={`progress-step1 ${(getStatusById(2) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
                                        <Box className={`step-circle ${(getStatusById(2) ? 'red_back' : '')}`} >
                                            {getStatusById(2) ? <FaCheck /> : 2}
                                        </Box>
                                        <Box className="step-title" style={{ textAlign: "inherit" }}>
                                            המשלוח בטיפול
                                        </Box>
                                    </Box>
                                    {/* <hr class="divider" />*/}
                                    <Box className={`progress-step1 ${(getStatusById(3) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
                                        <Box className={`step-circle ${(getStatusById(3) ? 'red_back' : '')}`}>
                                            {getStatusById(3) ? <FaCheck /> : 3}
                                        </Box>
                                        <Box className="step-title" style={{ textAlign: "inherit" }}>
                                            {/*{`בדרך לארץ` }*/}
                                            {/*{`בדרך לטיסה` }*/}
                                            {`בדרך לארץ`}
                                        </Box>
                                    </Box>
                                    {/*<hr class="divider" />*/}
                                    {/*<Box className={`progress-step1 ${(getStatusById(4) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
                                <Box className={`step-circle ${(getStatusById(4) ? 'red_back' : '')}`}>
                                    {getStatusById(4) ? <FaCheck /> : 4}
                                </Box>
                                <Box className="step-title" style={{ textAlign: "inherit" }}>
                                    */}{/*בתהליך מכס*/}{/*
                                    {`בדרך לארץ`}
                                </Box>
                            </Box>*/}
                                    {/*<hr class="divider" />*/}
                                    <Box className={`progress-step1 ${((getStatusById(5) || getStatusById(6)) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
                                        <Box className={`step-circle ${((getStatusById(5) || getStatusById(6)) ? 'red_back' : '')}`}>
                                            {((getStatusById(5) || getStatusById(6))) ? <FaCheck /> : 4} {/*5*/}
                                        </Box>
                                        <Box className="step-title" style={{ textAlign: "inherit" }}>
                                            נמסר להפצה
                                        </Box>
                                    </Box>
                                    {/*<hr class="divider" />*/}
                                    <Box className={`progress-step1 ${(getStatusById(7) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
                                        <Box className={`step-circle ${(getStatusById(7) ? 'red_back' : '')}`}>
                                            {getStatusById(7) ? <FaCheck /> : 5} {/*6*/}
                                        </Box>
                                        <Box className="step-title" style={{ textAlign: "inherit" }}>
                                            משלוח נמסר
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>}
                            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
                                <Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    תאריך
                                </Grid>
                                <Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    תיאור
                                </Grid>
                            </Grid>
                            {selectedShipping?.params?.statusLog?.map((val, index) => <>
                                {(val.DESC && (val.id == 3 && selectedShipping?.parentId == 0 ? selectedShipping?.params?.shippingStatus : true)) && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
                                    <Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                        {getDateFormat(val.DATE)}
                                    </Grid>
                                    <Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                        {val.DESC} 
                                    </Grid>
                                </Grid>
                                }
                            </>)}
                            {selectedShipping.parentId == 0 && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
                                <Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    {getDateFormat(selectedShipping.create_date)}
                                </Grid>
                                <Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    {`ההזמנה נקלטה במערכת`} {selectedShipping?.parentId == 0 ? " - ORCA Market" : ""}
                                </Grid>
                            </Grid>}
                        </Grid>}
                        {selectedShipping.consolidationStatus == 3 &&

                            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
                                <Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    איחוד חבילות:
                                </Grid>
                                <Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    {selectedShipping?.params?.notes?.replace("Repack:", "")}
                                </Grid>
                            </Grid>
                        }
                        {selectedShipping.repackStatus == 104 &&

                            <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
                                <Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    איחוד חבילות:
                                </Grid>
                                <Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
                                    {selectedShipping?.params?.notes?.replace("Repack:", "")}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>}
        </Box>);
    return (

        <DialogWithSideBar
            open={isPersonal}

            onClose={() => {
                //if (activeScreen != 8) {
                //    onHandleOpenModal()
                //} else {
                    setIsPersonal(false)
                //}
            }}
            isBackButton={activeScreen == 4 || activeScreen == 5 || activeScreen == 6}
            onClickBack={() => setActiveScreen(activeScreen - 1)}
        >
            {openOrderModal && (
                <Dialog maxWidth='sm' open={openOrderModal} title={``} onHandleCancel={onHandleCancelModal}>

                    <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                        ? האם להמשיך
                    </Grid>
                    <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                        setIsPersonal(false)
                    }}>המשך</Button>
                    <Button sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} variant='contained' onClick={onHandleCancelModal}> בטל</Button>
                </Dialog>
            )}
            {openInsuranceDialog &&
                <Dialog maxWidth='sm' open={openInsuranceDialog} title={`הסרת ביטוח`} onHandleCancel={onHandleCancelInsuranceDialog}>
                    <Grid container item justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }}>
                        { `שימו לב, ללא הביטוח לא תהיו זכאים לפיצוי במקרה של אבדן או נזק לחבילה בתהליך השילוח או ההפצה בארץ על ידי חברת ההפצה.`}
                    </Grid>
                    <Grid item container justifyContent= "center">
                        <Button sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px 10px" }} variant='contained' onClick={() => {
                            setValue(prev => ({ ...prev, isInsurence: false }));
                            onHandleCancelInsuranceDialog();
                        }}>
                            {`לא רוצה ביטוח`}
                        </Button>
                        <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px 10px" }} onClick={() => {
                            setValue(prev => ({ ...prev, isInsurence: true }));
                            onHandleCancelInsuranceDialog();
                        }}>{`רוצה ביטוח`}</Button>
                    </Grid>
                </Dialog>
            }
            {openOrderModalDelete && (
                <Dialog maxWidth='sm' open={openOrderModalDelete} title={``} onHandleCancel={onHandleCancelModalDelete}>

                    <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                        ? האם להמשיך
                    </Grid>
                    <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                        deleteSelectdReciept(selectedRecieptName)
                    }}>המשך</Button>
                    <Button sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} variant='contained' onClick={onHandleCancelModalDelete}> בטל</Button>
                </Dialog>
            )}

            {activeScreen == 1 && <Fragment>
                {screen_A}
            </Fragment>
            }
            {activeScreen == 2 && <Fragment>
                {screen_b}
            </Fragment>
            }
            {activeScreen == 3 && <Fragment>
                {screen_c}
            </Fragment>
            }
            {activeScreen == 4 && <Fragment>
                {screen_d}
            </Fragment>
            }
            {activeScreen == 5 && <Fragment>
                {screen_e}
            </Fragment>
            }
            {false && <Fragment>
                {screen_f}
            </Fragment>
            }
            {activeScreen === 8 && <Fragment>
                {screen_H}
            </Fragment>
            }
            {activeScreen === 9 && <Fragment>
                {screen_e_preview}
            </Fragment>
            }

            {isPreviewPdf && <Box className="reg_popup_data left-side">
                <Grid container item lg={1} justifyContent="flex-end" alignContent="flex-end" sx={{ mt: '2vh !important', fontSize: "24px", fontWeight: "bold", color: "#d70007", cursor: "pointer" }} onClick={() => { setIsPreviewPdf(false) }}>
                    X
                </Grid>
                <Grid container item lg={12} justifyContent="center" alignContent="center" sx={{ mt: '2vh !important', fontSize: "24px", fontWeight: "bold", color: "#d70007" }}>
                    חשבונית הקנייה
                </Grid>

                <Grid container item lg={11} justifyContent="flex-start" alignContent="flex-start" sx={{ m: '2vh !important', color: '#d70007', fontSize: "14px", fontWeight: "bold" }} alignItems="center">
                    <Grid onClick={() => {
                        setActiveScreen(screens[0].screen); setIsPreviewPdf(false)
                        setValue(prev => ({ ...prev, category: 0 }))
                        setValue(prev => ({ ...prev, subcategory: 0 }))
                    }} container item lg={4} justifyContent="flex-start" alignContent="flex-start" sx={{ color: '#d70007', fontSize: "14px", fontWeight: "bold" }}>
                        {/* */}  העלאת קובץ חדש
                        <div style={{ paddingLeft: "10px" }}>
                            <img className='upload' src={upload} alt='upload' />
                        </div>
                    </Grid>

                    {selectedShipping.consolidationStatus == 3 &&<Grid container item lg={4} justifyContent="flex-end" alignContent="flex-end" sx={{ fontSize: "14px" }} className="personalFileReciept">
                        <SelectField
                            className='text-field1'
                            required
                            value={uploadedPdfBse64Id}
                            onChange={event => {
                                setUploadedPdfBse64Id(event.target.value)

                                var filePdf = uploadedOrgFilesBase64.find(u => u.id === event.target.value);
                                setUploadedPdfBse64(filePdf)
                            }}
                            label=''
                            options={uploadedOrgFilesBase64}
                            islabelAnimate={false}
                            isPrefix
                            parentClassName={'select-field '}
                            dir="rtl"
                            isNumeric={true}
                            sx={{ m: 0, mr: "10px", height: "30px" }}
                        />
                    </Grid>
                    }

                    <Grid container item lg={4} justifyContent="flex-end" alignContent="flex-end" sx={{ fontSize: "14px" }}>
                        <span sx={{ fontSize: "14px", fontWeight: "bold" }}>{uploadedPdfFileName}</span>:
                        <span>שם הקובץ</span>
                    </Grid>
                </Grid>

                {/*{uploadedOrgFilesBase64.map((val, index) =>
                    <iframe src={`data:application/pdf;base64,${val.base64}`}
                        frameBorder="0" style={{ "height": "calc(100% - 300px)", "width": "100%", "top": "0px", "left": "0px", "right": "0px", "bottom": "0px" }} />

                )}*/}
                <iframe src={`data:application/pdf;base64,${uploadedPdfBse64?.base64}#toolbar=0&navpanes=0&statusbar=0&view=Fit;readonly=true;disableprint=true;`}
                    frameBorder="0" style={{ "minHeight": "calc(100% - 225px)", "width": "100%", "top": "0px", "left": "0px", "right": "0px", "bottom": "0px" }} />
                {/*<iframe src={uploadedPdf}
                    frameBorder="0" style={{ "height": "calc(100% - 155px)", "width": "100%", "top": "0px", "left": "0px", "right": "0px", "bottom": "0px" }} />*/}

                <Grid item container justifyContent="center">
                    <Button textLabel={`המשך`} style={{ background: "#d70007", color: "white", fontWeight: "bold", fontSize: "16px", borderRadius: "30px",height: "50px", padding:"0 100px"}} onClick={() => {
                        setIsPreviewPdf(!isPreviewPdf)
                    }} >{`המשך`} </Button>
                </Grid>
            </Box>}
        </DialogWithSideBar>
    );
}

export default Personal;