/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, Grid, Checkbox, FormControlLabel, Box } from '@mui/material';
import { SelectField, TextField, MultilineField } from '../../style-guide';
import './Login.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage, setShipping_list, setIsShippingLoading, setSelectedShipping } from '../../reducers/user-reducers/UserSlicer';
import { login, get_params, getShippingRequestList } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import logo from '../../images/logo.png'
import background from '../../images/title/contact-title.jpg'
import loginImage from '../../images/title/login.jpg'
import CloseIcon from '@mui/icons-material/Close';
import ActionButton from '../Common/ActionButton';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const Login = (props) => {
    const { tIsActivateUser, setIsChangePass, isLogin, setIsLogin, setIsRegister, setIsForgot, get_shipping_list_count, setIsPersonal } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));


    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [rememberMe, setRememberMe] = new useState(true);
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [isEye, setIsEye] = new useState(true);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const goTo = (e) => {
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            doLogin();
        }
    }
    const doLogin = () => {
        if (rememberMe) {
            setCookie("REMEMBER_USER_NAME", userName, 30);
            setCookie("REMEMBER_USER_PASSWORD", "", 30);
        } else {
            setCookie("REMEMBER_USER_NAME", "", 30);
            setCookie("REMEMBER_USER_PASSWORD", "", 30);
        } 
        login(userName, password, function (response) {
            if (response?.misc?.CODE === 1) {
                setCookie("SESSION_ID", response.session, 30);
                dispatch(getSession(response))
                var shId = get_params("shId");
                if (shId) {
                    var statuses = [1, 2, 3, 4, 41, 601, 104, 100, 201]
                    getShippingRequestList(response.session, statuses, function (response) {
                        if (response.misc.CODE === 1) {
                            response.data.sort(function (a, b) {
                                return new Date(b.date) - new Date(a.date);
                            });
                            dispatch(setShipping_list(response.data))
                            dispatch(setIsShippingLoading(false))
                            var sh = response.data.find(u => u.id === Number(shId))
                            dispatch(setSelectedShipping(sh));
                            setIsPersonal(true);
                            window.location.reload(true);
                        }
                    })
                } else {
                    navigate("/loginHome?target=login")
                    window.location.reload(true);
                }

                get_shipping_list_count();
                setIsLogin(false)
                //  ping();
            } else {
                var msg = 'פרטי משתמש לא נכונים';
                
                dispatch(snackbarToggle({ type: 'error', message: msg }));
                window.setTimeout(function () {
                   // window.location.reload();
                }, 20000)
            }
        });

    }
    useEffect(() => {
        //var userNa = getCookie("REMEMBER_USER_NAME")
        //var pass = getCookie("REMEMBER_USER_PASSWORD")
        //if (userNa) {
            //setUserName(userNa);
            //setPassword(pass);
        //} else {
        //    setUserName("");
        //    setPassword("");
        //}
    }, [userInfo]);
    return (
        <DialogWithSideBar
            open={isLogin}
            onClose={setIsLogin}
            isOutClose={true}
        >

            <Fragment>
                <Box container className="reg_popup_data">
                    <Box item lg={12} className="popup_tittle" justifyContent="center">
                        {'התחברות'} 
                    </Box>
                    <Box>
                        <TextField
                            className='text-field'
                            required
                            dir="rtl"
                            value={userName}
                            onChange={event => setUserName(event.target.value)}
                            onBlur={() => { setUserName(userName?.trim()) }}
                            label='שם משתמש (מייל)'
                        />
                        <Grid container className='position-reletive'>
                            <Grid onClick={() => { setIsEye(!isEye) }} className="prefix-field">
                                {isEye 
                                    ? <FaEye style={{ fill: "#625252", width: "100%", height: "30%" }} /> 
                                    : <FaEyeSlash style={{ fill: "#625252", width: "100%", height: "30%" }} />}
                            </Grid>
                            <TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                label='סיסמה'
                                type={`${isEye ? 'password' : 'text'}`}
                                onKeyDown={e => keyPressed(e)}
                                parentClassName={'prefix-password'}
                            />
                            
                        </Grid>
                        <Box container className='checkboxs'>
                            <FormControlLabel dir='rtl' key={uuidv4()} control={< Checkbox onKeyDown={e => keyPressed(e)} checked={rememberMe} onChange={event => {
                                if (event.target.checked) {
                                    setCookie("REMEMBER_USER_NAME", userName, 30);
                                    setCookie("REMEMBER_USER_PASSWORD", "", 30);
                                } else {
                                    setCookie("REMEMBER_USER_NAME", "", 30);
                                    setCookie("REMEMBER_USER_PASSWORD", "", 30);
                                }                         
                                setRememberMe(event.target.checked)
                            }} />} label={"זכור אותי"} />
                        </Box>
                    </Box>
                    <Box className='form-action'>
                        <ActionButton className={'submit-action mt-24'} textLabel={"התחברות"} onClick={() => doLogin()} />
                    </Box>
                    <Box className="screen-links ">
                        <Box container item lg={12} justifyContent="center" alignContent="center" className="screen-footer scarlet" onClick={() => { setIsRegister(true); setIsChangePass(false); setIsLogin(false); setIsForgot(false) }} sx={{ mt: 2, cursor: 'pointer' }}>
                            הירשם

                        </Box>

                        <Box container item lg={12} justifyContent="center" alignContent="center" className="screen-footer scarlet" onClick={() => { setIsRegister(false); setIsLogin(false); setIsChangePass(false); setIsForgot(true); }} sx={{ mt:2, cursor: 'pointer' }}>
                            שכחת את הסיסמא
                        </Box>
                        {/*<Box container item lg={12} justifyContent="center" alignContent="center" className="screen-footer scarlet" onClick={() => { setIsRegister(false); setIsLogin(false); setIsChangePass(false); setIsForgot(false); setIsActivateUser(true); }} sx={{ mt:2, cursor: 'pointer' }}>
                            User Activation
                        </Box>*/}
                    </Box>
                </Box>
            </Fragment>
        </DialogWithSideBar>
    );
}

export default Login;