/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Paper, Grid, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './FAQS.scss';
import { getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import account_banner from '../../images/homepage/account_banner.png';
import userPng from '../../images/homepage/user.png';
import ActionButton from '../Common/ActionButton';
import address from '../../images/homepage/address.png'
import tracking from '../../images/homepage/tracking.png'
import calculator from '../../images/homepage/calculator.png'
import ShopingOne from '../../images/faq/one-man.png';
import ShopingTwo from '../../images/faq/man-woman-shipping.png';
import BlueBoxGirl from '../../images/faq/blueBoxGirl.png';
import Stopwatch from '../../images/faq/stopwatch.svg';
import Signup_1 from '../../images/faq/signup-1.png';
import ShopingStart_2 from '../../images/faq/shopingStart-2.png';
import  ShipmentOnWay_4 from '../../images/faq/payment-3.png';
import Payment_3 from '../../images/faq/shipmentOnWay-4.png';
import pdf from '../../files/approval.pdf';
import queryString from 'query-string';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { get_params } from '../../reducers/requestHandler';
import MetaTags from 'react-meta-tags';

export const FAQS = (props) => {
    const { setActiveScreenInreg, setIsRegister, setIsLogin } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    //const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));

    const vnavigate = useNavigate();
    const navigate = function (url) {
        vnavigate(url);
        window.scrollTo(0,0);
    }
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isSubFilter, setIsSubFilter] = useState(false);
    
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (isExpanded) => {
        if (expanded === panel) {
            setExpanded(false);
        }
        else {
            setExpanded(isExpanded ? panel : false);
        }

    };
    const location = useLocation();
    useEffect(() => {
        
        if (location?.state?.name == "from_registration") {
            document.getElementById('from_registration').scrollIntoView();
        }
        if (location?.state?.name == "fromRightContentBelowPricing") {
            document.getElementById('more_info_41').scrollIntoView(); 
            //document.getElementById('fromRightContentBelowPricing').scrollIntoView();
        }
        if (location?.state?.name == "faqList") {
            document.getElementById('faqList').scrollIntoView();
        }
        var action = get_params("action")
        if (action === "from_registration") {
            document.getElementById('from_registration').scrollIntoView();
        }
        let searchParams = queryString.parse(location.search)
        try {
            console.log(searchParams.id)
            document.getElementById(searchParams.id).scrollIntoView();
            window.setTimeout(function () {
                document.getElementById(searchParams.id).scrollIntoView();
            }, 500)
           
            if (searchParams.id == "consolidationlinkTarget")
                setExpanded('246');
        } catch (e) { }
        // }
    }, [userInfo]);
    return (
        <>
            <MetaTags id="metaFAQ">
                <title>הכל על השירות שלנו</title>
                <meta id="meta-description" name="FAQS" content='הכל על השירות שלנו' />
                <meta id="meta-description-title" property="og:title" content="Redbox parcel" />
            </MetaTags>
            <Box>
                <Box component={'section'} className="page-title dark_image_filter" >
                    <Box className="title-section">
                        <Box className="container">
                            <Typography component={'h1'} className="page-main-title center_text rtl_text bold_text">הכל על השירות שלנו</Typography>
                            <Typography className='page_sub_title'> כאן תוכלו למצוא את כל המידע אודות השירות שלנו, תהליך ההרשמה, הרכישה ועוד </Typography>
                        </Box>
                    </Box>
                </Box>
                <Grid container item sm={12} md={12} justifyContent="center">
                {isMobile() && <>
                    <Grid container item sm={12} md={4} justifyContent="center" alignItems="center" flexDirection="column">
                        <iframe src="https://www.youtube.com/embed/EveyEgF7ItI?autoplay=0&mute=0&loop=1&color=white&controls=0&iv_load_policy=3&modestbranding=0&playsinline=0&showinfo=0&rel=0&enablejsapi=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="top_space_20" />
                        <p className="page_title_flags_content_flag_div_p">   {'איך זה עובד'}</p>
                    </Grid>
                    <Grid container item sm={12} md={4} justifyContent="center" alignItems="center" flexDirection="column">
                        <iframe src="https://www.youtube.com/embed/IDveC0Vw45Q?autoplay=0&mute=0&loop=1&color=white&controls=0&iv_load_policy=3&modestbranding=0&playsinline=0&showinfo=0&rel=0&enablejsapi=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="top_space_20" />
                        <p className="page_title_flags_content_flag_div_p">העלאת חשבונית</p>
                    </Grid>
                </>
                }
                {!isMobile() && <>
                    <Grid container item sm={12} md={4} justifyContent="center" alignItems="center" flexDirection="column">
                        <iframe src="https://www.youtube.com/embed/IDveC0Vw45Q?autoplay=0&mute=0&loop=1&color=white&controls=0&iv_load_policy=3&modestbranding=0&playsinline=0&showinfo=0&rel=0&enablejsapi=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="top_space_20" />
                        <p className="page_title_flags_content_flag_div_p">העלאת חשבונית</p>
                    </Grid>
                    <Grid container item sm={12} md={4} justifyContent="center" alignItems="center" flexDirection="column">
                        <iframe src="https://www.youtube.com/embed/EveyEgF7ItI?autoplay=0&mute=0&loop=1&color=white&controls=0&iv_load_policy=3&modestbranding=0&playsinline=0&showinfo=0&rel=0&enablejsapi=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="top_space_20" />
                        <p className="page_title_flags_content_flag_div_p">   {'איך זה עובד'}</p>
                    </Grid>
                </>
                    }
                </Grid>
                <Box component={'section'} className="faq-section" style={{ padding: '30px 0' }}>
                    {!isMobile() && <Box className="container">
                        
                        <Grid container className='fillter-tabs'>
                            <ul className='tabs-list'>
                                <li className={!isSubFilter ? 'active':''} ><a href="#">איך זה עובד?</a></li>
                                <li onClick={() => {
                                    document.getElementById('faqList').scrollIntoView();
                                }}><a >שאלות ותשובות</a></li>
                                <li className={isSubFilter ? 'active' : ''} onClick={() => {  document.getElementById('more_info').scrollIntoView(); }}><a >מדריכים</a></li>
                            </ul>
                        </Grid>
                        

                        <Typography component={'h1'} className='section-title mb-60'>איך עובד תהליך ההרשמה והרכישה ב- RedBox?</Typography>
                        {/* Step 1 */}
                        <Grid container className='box-wrapper'>
                            <Grid item sm={5} className='blue-rounded-box redbox-one'>
                                <img src={Signup_1} alt='' />
                            </Grid>
                            <Grid container item sm={8} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={2} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>1</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>נרשמים</Typography>
                                    <Typography className='step_desc'>{`בתהליך הרשמה קצר מצטרפים לשירות ומקבלים כתובת אישית בחו"ל בחינם.`}</Typography>
                                    <ActionButton textLabel={`הירשמו בחינם`} className='underline-btn red_text' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setActiveScreenInreg('Registeration'); setIsRegister(true);
                                        }
                                    }} />
                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`3 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                        {/* Step 2 */}
                        <Grid container className='box-wrapper dir-rtl'>
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>2</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>{`מתחילים בקניות`}</Typography>
                                    <Typography className='step_desc'>{`קונים בחנות המועדפת עליכם ובהשלמת הרכישה מזינים את הכתובת האישית שקיבלתם ככתובת המשלוח.`}</Typography>
                                    <Typography className='anchor link-text cursor_pointer' onClick={() => { navigate("/store")}}>{`לרשימת החנויות המומלצות`}{` >`}</Typography>
                                </Grid>
                                {/*<Grid item sm={3}>
                                    <Grid container className='stopwatch align-right'>
                                        <img src={Stopwatch} alt='' />
                                        {`תלוי בכם`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} className='blue-rounded-box redbox-two'>
                                <img src={ShopingStart_2} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 3 */}
                        <Grid container className='box-wrapper'>
                            <Grid item sm={5} className='blue-rounded-box redbox-three'>
                                <img src={Payment_3} alt='' />
                            </Grid>
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={3} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>3</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>{`משלמים`}</Typography>
                                    <Typography className='step_desc'>{`כשהחבילה מגיעה אלינו תקבלו הודעה ובכמה שלבים מהירים תבחרו את אופן המשלוח המתאים לכם ותשלימו את התשלום.`}</Typography>
                                    
                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`4 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                        {/* Step 4 */}
                        <Grid container className='box-wrapper dir-rtl'>
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>4</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>{`המשלוח בדרך אליכם`}</Typography>
                                    <Typography className='step_desc'>{`אנו נדאג לקליטת החבילה בארץ ואתם תהנו ממשלוח שיגיע אליכם עד הבית!`}</Typography>
                                    
                                </Grid>
                                {/*<Grid item sm={3}>
                                    <Grid container className='stopwatch align-right'>
                                        <img src={Stopwatch} alt='' />
                                        {`עד 5 ימי עסקים`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} className='blue-rounded-box redbox-four' >
                                <img src={ShipmentOnWay_4} alt=''  />
                            </Grid>
                            
                        </Grid>
                        <span id="faqList"></span>
                    </Box>}
                    {isMobile() && < Box className="container">
                        
                        <Grid container className='fillter-tabs'>
                            <ul className='tabs-list'>
                                <li className={!isSubFilter ? 'active' : ''} ><a href="#">איך זה עובד?</a></li>
                                <li onClick={() => {
                                    document.getElementById('faqList').scrollIntoView();
                                }}><a >שאלות ותשובות</a></li>
                                <li className={isSubFilter ? 'active' : ''} onClick={() => { document.getElementById('more_info').scrollIntoView(); }}><a >מדריכים</a></li>
                            </ul>
                        </Grid>


                        <Typography component={'h1'} className='section-title mb-60'>איך עובד תהליך ההרשמה והרכישה ב- RedBox?</Typography>
                        {/* Step 1 */}
                        <Grid container className='box-wrapper' flexDirection="column" alignItems="center">
                           
                            <Grid container item sm={8} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={2} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>1</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>נרשמים</Typography>
                                    <Typography className='step_desc'>{`בתהליך הרשמה קצר מצטרפים לשירות ומקבלים כתובת אישית בחו"ל בחינם.`}</Typography>
                                    <ActionButton textLabel={`הירשמו בחינם`} className='underline-btn red_text' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setActiveScreenInreg('Registeration'); setIsRegister(true);
                                        }
                                    }} />
                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`3 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} >
                                <img src={Signup_1} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 2 */}
                        <Grid container className='box-wrapper dir-rtl' flexDirection="column" alignItems="center">
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>2</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>{`מתחילים בקניות`}</Typography>
                                    <Typography className='step_desc'>{`קונים בחנות המועדפת עליכם ובהשלמת הרכישה מזינים את הכתובת האישית שקיבלתם ככתובת המשלוח.`}</Typography>
                                    <Typography className='anchor link-text cursor_pointer' onClick={() => { navigate("/store") }}>{`לרשימת החנויות המומלצות`}{` >`}</Typography>
                                </Grid>
                                {/*<Grid item sm={3}>
                                    <Grid container className='stopwatch align-right'>
                                        <img src={Stopwatch} alt='' />
                                        {`תלוי בכם`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} >
                                <img src={ShopingStart_2} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 3 */}
                        <Grid container className='box-wrapper'  alignItems="center">
                            
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={3} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>3</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>{`משלמים`}</Typography>
                                    <Typography className='step_desc'>{`כשהחבילה מגיעה אלינו תקבלו הודעה ובכמה שלבים מהירים תבחרו את אופן המשלוח המתאים לכם ותשלימו את התשלום.`}</Typography>

                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`4 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} >
                                <img src={Payment_3} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 4 */}
                        <Grid container className='box-wrapper dir-rtl' flexDirection="column" alignItems="center">
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>4</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>{`המשלוח בדרך אליכם`}</Typography>
                                    <Typography className='step_desc'>{`אנו נדאג לקליטת החבילה בארץ ואתם תהנו ממשלוח שיגיע אליכם עד הבית!`}</Typography>

                                </Grid>
                            </Grid>
                            <Grid item sm={5}  >
                                <img src={ShipmentOnWay_4} alt='' />
                            </Grid>

                        </Grid>
                        <span id="faqList"></span>
                    </Box>}
                </Box>

                <Box component={'section'} className="faq-section" style={{ padding: '30px 0' }} >
                    <Box className="container">
                        <Typography  component={'h1'} className='section-title text-right'>שאלות ותשובות נפוצות</Typography>
                        <Grid container className='faq-wrapper' sx={{ justifyContent: 'space-between' }}>
                            <Grid container item sm={2.2}>
                                <Grid container className="row flex-rtl" sx={{ justifyContent: 'space-between', maxWidth: { sm: '90%' }, margin: { xs: '0' } }}>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setActiveScreenInreg('Registeration'); setIsRegister(true);
                                        }
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={userPng} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title' >
                                            <Button className="with_arrow_icon">{`להרשמה מהירה`}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setIsLogin(true);
                                        }
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={tracking} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title'>
                                            <Button className="with_arrow_icon">{`מעקב משלוחים`}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setIsLogin(true);
                                        }
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={address} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title'>
                                            <Button className="with_arrow_icon">{`הכתובות שלי`}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        navigate("/redboxPricing")
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={calculator} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title'>
                                            <Button className="with_arrow_icon">{`מחשבון משלוחים`}</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item sm={9.8} className="shadow_box" >
                                <Grid container item flexDirection="row-reverse">
                                    <Grid item container flexDirection="column">
                                        {/*<h2 style={{ direction: "rtl", fontSize: "28px", fontWeight: "bold", color: "black" }}>{`שאלות ותשובות נפוצות`}</h2>*/}
                                        <h2 style={{ direction: "rtl", fontSize: "28px", fontWeight: "bold", color: "black" }}>{`שעות הפעילות שלנו:`}</h2>
                                    </Grid>
                                    <Accordion className='accordion' expanded={expanded === '1'} onChange={handleChange('1')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '1' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מה שעות פעילות של המחסנים בחו"ל ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המחסנים בארה"ב ובהולנד פתוחים בימים ב' עד ו' בין השעות 9:00-16:00 (זמן מקומי).  מומלץ לתאם את המסירה לזמנים אלה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '2'} onChange={handleChange('2')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '2' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `מה שעות הפעילות של שירות הלקוחות בארץ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `שירות הלקוחות שלנו זמין בימים א'-ה' בין השעות בין השעות 9:00-16:00.  פניות מחוץ לשעות הפעילות מומלץ לעשות באמצעות המייל בלבד.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{`מחירי שילוח, מיסים ואגרות מדינה:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '3'} onChange={handleChange('3')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '3' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `כיצד מחושב התשלום עבור משלוח החבילה והשחרור מהמכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    <b>{ `מחירי המשלוח נקבעים על פי המשקל הפיזי או המשקל הנפחי (נפח החבילה),  הגבוה מבין השניים.`}</b><br/>
                                                    { `משקל נפחי מחושב על פי הנוסחא הבא: אורך (בס"מ)X רוחב (בס"מ)X גובה (בס"מ) חלקי 5000 = המשקל הנפחי (נפח מתורגם לק"ג). שימו לב כי המידות הן המידות החיצוניות של החבילה ששלח המוכר, ולא מידות המוצר.`} <br/>
                                                    {`בנוסף, המחיר הסופי מושפע ממסים ואגרות מדינה. תוכלו לקבל הצעת מחיר מפורטת `}<span ><a href="/redboxPricing">{ `במחירון`}</a></span> { `שבאתר. יש לבחור במדינה ממנה מתבצע השילוח, ובמחשבון באופציה של חישוב מפורט.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '301'} onChange={handleChange('301')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '301' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך מחושב המע"מ עבור מספר חבילות מאותו ספק ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>

                                                    {`רשויות המס מתייחסות לכל החבילות שלך, המגיעות לארץ יחד מאותו ספק, כרכישה אחת ויגבו מס על פי הסכום הכולל.  כלומר: אם סכום המוצרים בכל בחבילות שלך מאותו ספק  יעלה על 75$ יגבה עבור החבילות מע"מ, גם אם כל חבילה בנפרד אינה מגיעה ל75$.`}<br />
                                                    {`על מנת לוודא שכל חבילה מגיעה בטיסה נפרדת ניתן לשלם עבור שילוח החבילות בהפרש של שבוע בין חבילה לחבילה.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '4'} onChange={handleChange('4')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '4' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם הצהרה על עלות המוצר כוללת הובלה ומסים מקומיים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כשמצהירים למכס הישראלי על ערך המוצרים בעת התשלום, או כאשר בודקים את עלות השילוח והמסים במחשבון שבאתר מכניסים ל"ערך המוצר" את עלות המוצר בלבד לא כולל את המס המקומיים ולא כולל את ההובלה בתוך ארה"ב.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '5'} onChange={handleChange('5')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '5' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`עד איזה סכום לא משלמים מע"מ ומכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `על פי חוקי המכס בישראל, חבילות בעלות של עד 75$ (אינן חייבת במע"מ, מכס או`}<br/>
                                                    { `מסי קניה (למעט מוצרי טבק ואלכוהול המחוייבים במכס בכל סכום).`}<br/>
                                                    { `מעל 75$ יחוייב מע"מ על הערך הכולל של המוצרים והשילוח (כולל ערך השילוח הפנימי בחו"ל כפי שמופיע בחשבונית). עבור מוצרים מעל 100$ יש אגרות מדינה נוספות (ניתן לראות את הפירוט בדף המחירון באתר). `}<br/>
                                                    { `למידע נוסף לגבי מיסוי בייבוא אישי לחצו כאן.`}<br/>
                                                    { `כאשר מצהירים למכס הישראלי על ערך המוצרים בעת התשלום, או כאשר בודקים את עלות השילוח והמסים במחשבון שבאתר מצהירים על "ערך המוצר" על פי הפירוט הבא:`}<br/>
                                                    
                                                    <ul>
                                                        <li>{ `•	מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}</li>
                                                        <li>{ `•	מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '6'} onChange={handleChange('6')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '6' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `איך אפשר לקבל קופוני הנחה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `אנחנו מעדכנים על הקופונים בדיוורים שלנו במייל. מומלץ לבדוק האם אישרת דיוורים (אם לא ניתן לשנות זאת באזור האישי שלך באתר), או האם המיילים שלנו הגיעו לתיבת ספאם או לתיבת קידום מכירות.`}<br/>
                                                    { `אם המיילים הגיעו לשם, מומלץ להעביר את המייל לדואר נכנס ולשמור אותנו באנשי הקשר שלך.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '7'} onChange={handleChange('7')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '7' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מי מבצע את שחרור החבילה מהמכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`רד-בוקס פרסל הינה סוכן מכס מורשה ומבצעת גם את שחרור החבילה מהמכס עבור לקוחותיה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '8'} onChange={handleChange('8')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '8' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אוכל לשלוח דרככם חבילה אם אין לי חשבונית?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ביבוא לארץ, נדרש להציג למכס חשבונית קנייה. עם זאת, יש מקרים בהם הלקוחות מעוניינים להביא מוצרים יד שניה, מתנות, או פיצוי מהמוכר על קנייה קודמת, וכד'. במקרה כזה יש למלא ולצרף בעת התשלום `}<span><a href="/affidavit.pdf">{ `תצהיר יבואן .`}</a></span><br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '9'} onChange={handleChange('9')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '9' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מהם המסים המקומיים בניו ג'רזי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המס המקומי בניו ג''רזי עומד על  6.625%`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '10'} onChange={handleChange('10')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '10' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש עמלת המרה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `מחירי השירותים שרד בוקס מציגה באתר נקובים בדולר ארה"ב.`}<br />
                                                    { `ההמרה לש"ח מבוצעת בהתאם לשער הדולר היציג שנקבע על ידי בנק ישראל ביום העסקים שקדם ליום ההזמנה בתוספת שלושה וחצי אחוזים לכיסוי עמלות ההמרה והעברות בין הבנקים ועלויות העמסה על שער המכס ברשימון.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>

                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{`תהליך השילוח של החבילה מחו"ל לארץ:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '11'} onChange={handleChange('11')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '11' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`בתוך כמה זמן מרגע ששילמתי אוכל לקבל את החבילה? `}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`זמן ההגעה בימי שיגרה הוא כ 10-14 ימי עסקים מרגע התשלום על החבילה. כיום עקב המלחמה ומיעוט חברות התעופה הטסות לארץ זמן ההגעה הוא כ21-28 ימי עסקים. יש לקחת בחשבון את זמן המשלוח מאתר הרכישה למחסנים העומד לרוב על 2-5 ימים.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '12'} onChange={handleChange('12')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '12' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך המוצרים נשלחים לישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`שילוח החבילה נעשה באמצעות הטסה אווירית בלבד.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '13'} onChange={handleChange('13')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '13' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`תוך כמה זמן אוכל לראות את החבילה באזור האישי שלי באתר? `}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`חבילה נקלטת תוך 2-3 ימי עסקים מרגע קבלת הודעת המסירה מהספק על הגעתה למרכז הלוגיסטי שלנו. ברגע שהחבילה תקלט, תקבל הודעת מייל עם קישור לאזור האישי באתר, שם תתבקש להעלות חשבונית, לבצע תשלום ולבחור האם אתה רוצה משלוח עד אלייך או לנק' איסוף לבחירתך.`}<br />
                                                    {`אם לאחר 3 ימי עסקים החבילה שלך לא נקלטה, יש לפנות לשירות לקוחות בצירוף צילום מסך של הודעת המסירה מחברת ההובלה המקומית, כולל מספר המעקב המופיע אצלם.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '13'} onChange={handleChange('13')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '13' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך ניתן לעקוב אחר החבילה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`עם קליטת החבילה במחסן ישלח לך מייל עם מספר המעקב של החבילה אצלנו. מרגע זה ניתן אחר החבילה באזור האישי שלך באתר של רד-בוקס פרסל. בנוסף נשלח לך מייל עם התקדמות החבילה בכל אחד מהשלבים. כאשר החבילה תועבר לחברת ההפצה ישלחו לך עדכונים בSMS מחברת ההפצה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '14'} onChange={handleChange('14')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '14' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אוכל לעקוב אחר החבילה שלי באתרים גלובאליים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`אנחנו חברת שילוח ועמילות מכס, ומביאים את החבילות שלך ישירות דרך חברות התעופה והמכס ולא דרך גורם שלישי.`}<br />
                                                    {`לכן המעקב על החבילה יופיע רק באזור האישי שלך באתר שלנו. ברגע נחיתת החבילה בארץ ושחרורה מהמכס, ישלח לך עדכון כי החבילה הועברה לחברת בר הפצה,להפצה מקומית.`}<br />
                                                    {`מרגע קבלת הודעת קליטה מחברת בר הפצה, ניתן יהיה לעקוב אחרי החבילה מול בר הפצה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '15'} onChange={handleChange('15')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '15' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מי חברת ההפצה שלכם בישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`לאחר שהחבילה הגיעה לישראל ושוחררה מהמכס, היא מועברת למרכז הפצה של חברת בר הפצה ממנו תשלח במהירות ליעדה בנקודת האיסוף או בבית הלקוח.`}<br />
                                                    {`מרגע זה העדכון יתבצע ע"י מסרונים מחברת ההפצה. תהליך ההפצה נמשך מספר ימים.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '16'} onChange={handleChange('16')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '16' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לאחסן חבילות במחסני רד-בוקס פרסל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, אחסון חבילה במחסנים שלנו הינו ללא עלות ב-21 הימים הראשונים, ולאחר מכן בעלות של 4$ לשבוע (7 ימים) או לחלק מהשבוע. חבילה שתמצא במחסנים באירופה/בארה"ב למעלה מ-60 ימים תוגדר כ"חבילה נטושה" ותימסר לתרומה.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '17'} onChange={handleChange('17')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '17' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן להחזיר חבילה לשולח?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`אנו מבצעים החזרה מהמחסן בחו"ל למוכר בלבד (לא מהארץ לחו"ל). עלות ההחזרה הינה באחריות המוכר. `}<br />
                                                    {`במידה והחבילה הגיעה למרכז הלוגיסטי,נקלטה ועדיין לא שולמה, ניתן לבקש label להחזרה מהמוכר, `}<br />
                                                    {`לשלוח אלינו למייל ואנו נדאג לטיפול בהחזרה מול חברות הובלה המקומיות.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '18'} onChange={handleChange('18')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '18' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לייבוא דרכם חבילות במשקלים ו/או אורכים חריגים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, בוודאי. במידה שאחת מצלעות החבילה ארוכה מ-110 ס"מ ו/או החבילה שוקלת 30 ק"ג ויותר, הייבוא האישי יהיה כרוך בתשלום נוסף בסך 40$. לא ניתן לייבא חבילות אשר אחת מצלעותיהן ארוכות מ-250 ס"מ`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '19'} onChange={handleChange('19')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '19' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש הגבלה על מספר המוצרים שניתן לייבא בייבוא אישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ייבוא אישי מיועד לצריכה אישית וקיימת הגבלה על מספר המוצרים שניתן לייבא באופן פרטי.`}<br />
                                                    {`לרוב היקף המוצרים המותר הינו בין 3-10 מוצרים, תלוי בסוג המוצר. `}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{ ` שאלות לגבי שירותים נוספים`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '20'} onChange={handleChange('20')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '20' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אתם עושים איחוד חבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן. ניתן להגיש בקשה לאיחוד חבילות עבור חבילות המופיעות באזור האישי שלך באתר ואשר לא שולמו עדיין. בשל מגבלות משקל וגודל , ישנם מקרים שבהם צוות המחסן לא יוכל לבצע איחוד חבילות ועל כך תימסר הודעה ללקוח. לא ניתן להוסיף לחבילה מאוחדת חבילה נוספת לאחר שכבר בוצע האיחוד,  וכמו כן לא ניתן לפצל חבילות בחזרה לאחר שהאיחוד בוצע על ידי המחסן.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '21'} onChange={handleChange('21')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '21' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם שירות איחוד חבילות כרוך בתשלום?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`השירות כרוך בתשלום. עלות איחוד חבילות הינה 6$ עבור שתי החבילות הראשונות, ותוספת של 3$ עבור כל חבילה נוספת. עבור הזמנה שפוצלה על ידי הספק יינתן זיכוי לאחר פנייה לשירות לקוחות (במקרה זה האיחוד לא כרוך בתשלום).`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '22'} onChange={handleChange('22')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '22' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`כיצד מתבצע איחוד החבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כאשר כל החבילות שברצונך לאחד יופיעו באזור האישי שלך, ניתן יהיה ללחוץ על כפתור "איחוד חבילות", לסמן את החבילות אותן ברצונך לאחד, ולאשר את הבקשה באמצעות לחיצה על כפתור OK. ניתן לאחד רק חבילות שעדיין לא שולמו. בסיום התהליך תישלח הודעת תשלום חדשה עבור החבילה המאוחדת.`}<br />
                                                    {`בשל מגבלות משקל וגודל , ישנם מקרים שבהם צוות המחסן לא יוכל לבצע איחוד חבילות ועל כך תימסר הודעה ללקוח. לא ניתן להוסיף לחבילה מאוחדת חבילה נוספת לאחר שכבר בוצע האיחוד,  וכמו כן לא ניתן לפצל חבילות בחזרה לאחר שהאיחוד בוצע על ידי המחסן.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '23'} onChange={handleChange('23')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '23' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`תוך כמה זמן אראה את החבילה המאוחדת שלי באזור האישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המרכז הלוגיסטי שלנו מקבל את הבקשה, מאחד את החבילות פיזית ומעדכן באזור האישי את החבילה המאוחדת עם המידות החדשות ומס' החבילה החדש הכולל בתוכו את החבילות האחרות ,תהליך זה לרוב אורך עד כ3 ימי עסקים מרגע הבקשה באתר`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '24'} onChange={handleChange('24')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '24' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם איחוד חבילות מוזיל את מחיר השילוח?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ברוב המכריע של המקרים, תהליך איחוד החבילות אינו משתלם מבחינת מחיר. הסיבות העיקריות לכך:`}<br />
                                                    <li>
                                                        <ul>{`איחוד החבילות עלול ליצור חיוב נפחי גדול מסה"כ הנפח של כל חבילה בנפרד משום שחישוב הנפח של החבילה המאוחדת נעשה לפי הפאות הארוכות ביותר. כמעט ללא יוצא מהכלל, נפח החבילה המאוחדת גדול בצורה משמעותית מסך החבילות המאוחדות.`}</ul>
                                                        <ul>{ `מדיניות התמחור הזולה של רדבוקס מייתרת את הצורך באיחוד, כיוון שאצלנו בניגוד למתחרים אתם לא משלמים על נפח מיותר`}</ul>
                                                        <ul>{ `ברד בוקס חיוב השילוח מדוייק ונאמן לגודל האמיתי של החבילה וזאת בזכות מדרגות משקל של 100 גר' כלומר, אין הפסד נפח בחבילה הבודדת.`}</ul>
                                                        <ul>{ `איחוד חבילות שסך הערך שלהן עולה על 75 דולר יחוייבו מיסים ומע"מ כחבילה אחת.`}</ul>
                                                        <ul>{ `איחוד של חבילות שלא מאותה חשבונית מחוייב עלות נוספת של 3$ עבור כל חבילה.`}</ul>
                                                    </li>
                                                    <br />{ `בשל כך, אנו ממליצים להמנע מאיחוד.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '25'} onChange={handleChange('25')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '25' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לבטל איחוד חבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ניתן לבקש משירות הלקוחות לבטל בקשה לאיחוד חבילות,  רק אם טרם בוצע האיחוד פיזית והבקשה ממתינה לאיחוד. לא ניתן לבטל איחוד חבילות לאחר שהאיחוד בוצע על ידי המחסן.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '26'} onChange={handleChange('26')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '26' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לצמצם את נפח החבילות המכילות מוצרי אופנה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`במידה וחבילות המכילות פריטי אופנה הגיעו בקרטון ולא בשקיות שילוח, נוכל להציא את הפריטים מהאריזה המקורית ולארוז אותם מחדש בשקיות השילוח שלנו. השירות אינו כרוך בתשלום, אך אינו ניתן באופן אוטומטי אלא רק לאחר פנייה לשירות לקוחות. יש לציין את מספר החבילה כפי שהוא מופיע באזור האישי בעת הפנייה לשירות הלקוחות.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{ `שאלות לגבי מוצרים שונים`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '27'} onChange={handleChange('27')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '27' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לייבא מוצרי מזון?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`מוצרי מזון ביבוא אישי - מותר לייבא עד 15 ק"ג למשלוח .`}<br />
                                                    {`מוצרי מזון ביבוא אישי - מותר לייבא עד 15 ק"ג למשלוח .`}<br />
                                                    {`אסור יבוא של מזונות רגישים לדוגמא: דברים חיים, גבינות , דגים , בשר אבקות חלב`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '28'} onChange={handleChange('28')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '28' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ייבוא טלפונים סלולריים או מחשבים אישיים כפוף לאישור משרד התקשורת?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`משרד התקשורת מחייב אישור ייבוא אישי רק בייבוא של 6 טלפונים סלולריים ומעלה או 3 יחידות מחשב ויותר.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '29'} onChange={handleChange('29')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '29' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ישנם מוצרים אשר אסורים בייבוא לישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ישנה רשימה מפורטת של מוצרים וחומרים אשר אסורים לייבוא לישראל.`}<br />
                                                    {`לרשימה המלאה לחצו `}<span><a onClick={() => { document.getElementById('more_info_3').scrollIntoView(); }}>{`כאן`}</a></span><br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '30'} onChange={handleChange('30')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '30' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ישנם מוצרים אשר לא ניתנים להטסה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`רשימת החומרים  vאסורים להטסה:`}<br />
                                                    {`בטריות Li-on (ליתיום איאון) ,שאינן מותקנות במכשיר.`}<br />
                                                    {`מוצרים דליקים (שאינם עומדים בנהלי התעופה של מוצרים דליקים ומסוכנים).`}<br />
                                                    {`גזים דחוסים (מוקפאים, דליקים, בלתי דליקים ורעילים).`}<br />
                                                    {`חומרים מאכלים, כגון: חומצות, חומרי בסיס, סוללות מופעלות בתא רטוב ופריטים הכוללים כספית.`}<br />
                                                    {`חומרי נפץ, תחמושת (כולל כדורים ריקים), אקדחים, זיקוקין, פיקות כדורי אקדח.`}<br />
                                                    {`נוזלים וחומרים מוצקים דליקים, כגון בשמים, לקים, מצתים שיש להטות לפני שמציתים אותם, דלק למציתים, גפרורים, מציתים, צבעים וחומרים מדללים.`}<br />
                                                    {`חומרים רדיו-אקטיביים.`}<br />
                                                    {`תיקי מנהלים עם מנגנוני אזעקה.`}<br />
                                                    {`חומרים מחמצנים, כגון אבקות הלבנה ומי חמצן.`}<br />
                                                    {`רעלים וחומרים מדבקים, כגון קוטלי חרקים, קוטלי עשבים וחומרים המכילים וירוסים.`}<br />
                                                    {`בהתאם לנהלי התעופה, עבור שילוח מוצרים דליקים נדרשים אישורי הטסה מיוחדים ואריזות בעלות תקן מיוחד, וגם במידה ויתקמו התנאים, לא ודאי שחברות התעופה יאשרו הטסה. לפיכך, יש להמנע מיבוא אישי של פרטים אלה האסורים לשילוח בינלאומי והמוזכרים לעיל, כחומרים אשר אסורים לשילוח..`}<br />
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '0301'} onChange={handleChange('0301')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '0301' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לייבא תרופה ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`במידה ומדובר בתרופה שלא דרוש לה מרשם רופא וכל עוד היא עונה על הגדרות ייבוא אישי, יש לחתום על טופס הצהרה למשרד הבריאות (הצהרה על ייבוא תכשיר לצרכיו האישיים של אדם).`}<br />
                                                    {`במידה ודרוש מרשם רופא לצורך קניית התרופה, יש לצרף לטופס ההצהרה גם מרשם רופא.`}<br />
                                                    <a href="https://www.health.gov.il/DocLib/a3071_29a1.docx" target="_blank">https://www.health.gov.il/DocLib/a3071_29a1.docx</a>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '0302'} onChange={handleChange('0302')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '0302' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `מהן הגדרות היבוא האישי לתרופה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `1.	התרופה אינה מכילה סם מסוכן או חומר פסיכוטרופי.`}<br />
                                                    { `2.	בידיי מרשם רופא לשימוש בתכשיר, אם התכשיר מחייב מרשם רופא.`}<br />
                                                    { `3.	התכשיר מיועד לצריכה אישית לתקופה שאינה עולה על 90 ימים. `}<br />
                                                    { `4.	התכשיר נרכש מבית מרקחת מורשה במדינת המקור.`}<br />
                                                    { `5.	התכשיר לא יועבר לצד ג', בין בתמורה ובין שלא בתמורה.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '31'} onChange={handleChange('31')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '31' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לייבא אביזרים לנשק ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `יבוא אביזרים וחלקים לכלי נשק מסווג בפרט 9305 ודורש רישיון יבוא: רשיון ממשרד הכלכלה ( מנהל יבוא ) / רישיון ממשרד לביטחון פנים ( אגף לפיקוח ורישוי כלי יריה ).`}<br />
                                                    { `רישיון היבוא הוא עניין מסחרי ולא ניתן להוציאו באופן אישי ( צריך להתבצע ע"י ארגונים מורשים הפועלים תחת רישיון ורגולציה של האגף לרישוי ופיקוח כלי ירייה ).`}<br />
                                                    <b>{ `המשמעות המעשית היא שיבוא אביזרים וחלקים לכלי נשק, אינו אפשרי ביבוא אישי.`}</b>
                                                    <b>{ `נרתיקים לנשק מותרים ביבוא.`}</b>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{ `שאלות לגבי חנויות:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '32'} onChange={handleChange('32')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '32' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר להזמין גם מחנויות שאינן מופיעות כחנויות מומלצות באתר?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, בוודאי. ניתן לעשות הזמנה מכל אתר באיחוד האירופי ששולח עד לדלת המחסן שלנו בהולנד, או מכל אתר בארצות הברית שעושה משלוחים עד לדלת המחסן בניו ג'רזי.`}<br />
                                                    {`בהזמנה מאנגליה, שוויץ או  מכל מדינה אחרת שאינה שייכת לארצות האיחוד האירופי עלולות להיות עלויות נוספות של מסים מקומיים ושיחרור מהמכס ההולנדי),  חשוב לבחור באפשרות של הובלה עד הבית ולא לנקודת הפצה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '33'} onChange={handleChange('33')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '33' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך מזמינים מזארה הולנד ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`נרשמים לשירות באתר Redbox parcel ומקבלים כתובת בהולנד.`}<br />
                                                    <ul>
                                                        <li style={{ listStyle: "decimal"}}>{ `נכנסים לאתר זארה דרך הדפדפן (לא דרך האפליקציה), במדינה בוחריםNederland .`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `מוסיפים לעגלת הקניות את המוצרים אותם תרצו להזמין`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `בסיום השופינג,יש ללחוץ על תשלום ולהתחבר כאורח.`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `Adress2: הינו מספר הלקוח שלך אצלנו. יש לרשום אותו בצמוד לשם כדי שיופיע על החבילה - ממלאים בפרטי הכתובת את הכתובת באירופה שקיבלתם מאיתנו`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `מוסיפים את המחוז Noord Holand.`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `לטובת אימות, רושמים את הטלפון הישראלי שלכם עם קידומת בינלאומית +972.`}</li>
                                                    </ul>
                                                    {`והנה ההסבר גם בהיילייטס בדף האינסטגרם שלנו: `}<br />
                                                    <a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDI0NTg2NTQzODgxMjkx?story_media_id=3260387128980327668_56318942893&igsh=MTc4MmM1YmI2Ng==">{ `https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDI0NTg2NTQzODgxMjkx?story_media_id=3260387128980327668_56318942893&igsh=MTc4MmM1YmI2Ng==`}</a><br />
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{ `ביטוח:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '34'} onChange={handleChange('34')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '34' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `האם ניתן לבטח את המטענים אשר נשלחים דרכינו?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `ברד בוקס ניתן ומומלץ לבטח את המוצרים בעת התשלום עבור המשלוח.`}<br/>
                                                    { `בסכומים של עד 250 $ ביטוח החבילות אינו חובה וניתן להסירו. בסכומים הגבוהים מ 250$ חלה חובת ביטוח.`}<br/>
                                                    { `הביטוח מכסה נזק פיזי למוצר / אובדן בתהליך השילוח והשחרור מכס.`}<br/>
                                                    { `הפיצוי כולל את הערך של המוצרים בחשבונית, עלויות השילוח ומיסי הייבוא. שירות הביטוח נכנס לתוקף ברגע שבו שולם הביטוח עבור החבילה ומסתיים עם מסירתה ללקוח ו/או עם חתימתו על תעודת המשלוח.`}<br/>
                                                    { `בקשה לכיסוי ביטוחי נדרשת עד ליום עסקים הבא לאחר קבלת המשלוח, לאחר מכן רד בוקס פארסל לא תהיה אחראית לכיסוי של נזק או אובדן.`}<br/>
                                                    { `הערה: הביטוח אינו מכסה את המוצרים הבאים:`}<br/>
                                                    <ul>
                                                        <li>{ `חפצים שבירים – כלי פורצלן, זכוכית וקרמיקה.`}</li>
                                                        <li>{ `יצירות וחפצי אומנות.`}</li>
                                                        <li>{ `תכשיטים וחפצים יקרי ערך.`}</li>
                                                        <li>{ `מוצרים נוזליים כגון: שמנים למיניהם, בשמים, ג'ל, חומרי ניקוי וכיוצ"ב.`}</li>
                                                        <li>{ `מוצרי יד שניה (אובדן בלבד).`}</li>
                                                        <li>{ `חבילות אשר עברו אריזה מחדש/ איחוד (אובדן בלבד).`}</li>
                                                        <li>{ `טלוויזיות ומסכים.`}</li>
                                                        <li>{ `מוצרי קוסמטיקה כגון – פודרה, סומק, טינט, קרמים, אודמים וכיוצ"ב (אובדן בלבד).`}</li>
                                                        <li>{ `מוצרים אסורים לשילוח עפ"י חוקי המכס הישראלי.`}</li>
                                                    </ul>
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '35'} onChange={handleChange('35')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '35' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `עדכון על נזק להפעלת פוליסת ביטוח`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `בכל מקרה של נזק ובקשה לכיסוי הביטוחי יש לפנות לכל המאוחר לשירות הלקוחות במהלך יום העסקים שלאחר קבלת החבילה.`}<br />
                                                    { `יש לתאר את הנזק ולהוסיף תמונות אשר ממחישות את תיאור הנזק.`}<br />
                                                    { `במקרה של הפעלת הביטוח לכיסוי הנדרש יחולו דמי השתתפות עצמית לפי המדרגות הבאות:`}<br />
                                                    { `עד 99 דולר – אין השתתפות עצמית`}<br />
                                                    { `100 דולר ועד 149 דולר - השתתפות עצמית בסך 25 דולר`}<br />
                                                    { `150 דולר ועד 449 דולר – השתתפות עצמית בסך של 40 דולר`}<br />
                                                    { `450 דולר ועד 1199 דולר – השתתפות עצמית בסך של 60 דולר`}<br />
                                                    { `מעל 1200 דולר – השתתפות עצמית בסך של 240 דולר`}<br />
                                                    { `פרוט כיסוי ביטוחי`}<br />
                                                    <ul>
                                                        <li>{ `במקרה של מטען אשר הוכרז כאובדן – יזוכה הלקוח על עלות השילוח + עלות המוצר`}</li>
                                                        <li>{ `במקרה של נזק מהותי – יזוכה הלקוח על עלות השילוח +עלות המוצר רק לאחר שהמוצר ייאסף על ידי חברת`} <br /> { `רדבוקס בע"מ.`}</li>
                                                        <li>{ `במקרה של חבילה המורכבת ממספר פריטים המופיעים בחשבונית ופריט אחד ניזוק, יזוכה`}<br />{ `הלקוח זיכוי יחסי בהתאם לעלות הפריט בחשבונית . רק לאחר שהמוצר ייאסף על ידי חברת רדבוקס בע"מ.`}</li>
                                                        <li>{ `תשלום הביטוח יבוצע במהלך 14 ימי עסקים, מרגע אישור התביעה ע"י רדבוקס בע"מ.`}</li>
                                                        <li>{ `הכיסוי הביטוחי תקף אך ורק למטענים אשר נרכשה עבורם פוליסת הביטוח בשלב תשלום השילוח.`}</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '36'} onChange={handleChange('36')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '36' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `באילו מקרים אין כיסוי ביטוחי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    <ul>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרה של אובדן/נזק של חבילה שאינה מבוטחת, לא יקבל הלקוח זיכוי כלל.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרים בהם רק האריזה נפגמה, אך המוצר ללא פגע, אין כיסוי ביטוחי.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרה של פגם חלקי במוצר, לא יהיה ניתן לבצע זיכוי חלקי אלא, יזוכה זיכוי מלא לאחר שהמוצר ייאסף על ידנו.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרים בה החבילה מגיעה מהספק פגומה או פתוחה, לא יבוצע זיכוי כלל.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `חברת רד בוקס לא תהיה אחראית למשלוחים אשר הונחו מחוץ למחסן במהלך שבת או ראשון, או שנמסרו`}<br />{ `לכתובת שגויה.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `הביטוח אינו מכסה את המוצרים הבאים:`}</li>
                                                        <li>
                                                            <ul>
                                                                <li style={{ listStyle: "circle" }}>{ `חפצים שבירים – כלי פורצלן, זכוכית וקרמיקה.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `יצירות וחפצי אומנות.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `תכשיטים וחפצים יקרי ערך.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `מוצרים נוזליים כגון: שמנים למיניהם, בשמים, ג'ל, חומרי ניקוי וכיוצ"ב.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `מוצרי יד שניה (אובדן בלבד).`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `חבילות אשר עברו אריזה מחדש/ איחוד (אובדן בלבד).`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `טלוויזיות ומסכים.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `מוצרי קוסמטיקה כגון – פודרה, סומק, טינט, קרמים, אודמים וכיוצ"ב (אובדן בלבד).`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `מוצרים אסורים לשילוח עפ"י חוקי המכס הישראלי.`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    {`היה והתגלה כי הלקוח ביטח אחד מהמוצרים אותם הביטוח אינו מכסה, יהיה הלקוח זכאי להחזר דמי הביטוח `}<br />
                                                    {`אותם שילם.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                            {/*<Grid container item sm={9.8} className="shadow_box" >
                                <Grid container item>
                                    <Accordion className='accordion' expanded={expanded === '1'} onChange={handleChange('1')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '1' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`בתוך כמה זמן אוכל לקבל את המוצרים שקניתי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`השירות שלמרגע קליטת המוצר במחסני החברה רד-בוקס פרסל ולאחר הסדרת התשלום לשילוח, זמן
ההגעה ללקוח בישראל הוא כ 21-28 ימי עסקים. יש לקחת בחשבון את זמן המשלוח מאתר הרכישה למחסנים העומד לרוב על 2-5 ימים.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 2 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '2'} onChange={handleChange('2')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '2' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך המוצרים נשלחים לישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`שילוח החבילה נקבע בהתאם למחירון המבוסס על המשקל הפיסי או הנפחי של החבילה (לפי.
הגבוה מבניהם). רד-פרסל מאפשרת לכם לקבל מחיר סופי שכולל את מלוא עלויות המשלוח.
ברגע העלאת החשבונית יידרש תשלום עבור המשלוח ולאחר כ-21-28 ימי עסקים המשלוח יגיע אליכם עד לנקודת מסירה הקרובה לביתכם או עד פתח הבית.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 31 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '31'} onChange={handleChange('31')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '31' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`כיצד להעלות חשבונית?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`לאחר הגעת המשלוח למחסן שלנו בחו"ל, יישלח אליכם מייל עדכון על קליטת החבילה במחסן.במייל זהיצורף קישור להעלאת חשבונית.לחיצה על הקישור תחבר אתכם לאזור האישי שם ניתן להעלות את החשבונית
הערה: החשבונית צריכה להשמר בפורמ PDF’`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 32 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '32'} onChange={handleChange('32')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '32' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`לאחר ביצוע הקנייה באתר בחו"ל לא קיבלתם חשבונית?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    <p> {``}</p>
                                                    <p> {`אין בעיה, בפעולות הבאות תוכלו להפיק חשבונית אותה תדרשו להעלות לאתר רדבוקס עם הודעת הקליטה במחסן שלנו בחו"ל`}</p>
                                                    <ol>
                                                        <li style={{ listStyle: "auto" }}>
                                                            {`לאחר ביצוע הקנייה החנות שולחת מייל עם אישור Order Confirmation`}
                                                        </li>
                                                        <li style={{ listStyle: "auto" }}>
                                                            {`יש להעתיק את המייל בדרך הבאה`}
                                                            <ol>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {`הכנסו למייל`}
                                                                </li>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {`לחצו על A + Ctrl  על מנת לבחור את כל התוכן (ייצבע בכחול)`}
                                                                </li>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {`לחצו על  C + Ctrl על מנת להעתיק את התוכן.`}
                                                                </li>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {`אנא פתחו מסמך Word  חדש`}
                                                                </li>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {` לחצו  V + Ctrl  וכל התוכן יועתק לWord`}
                                                                </li>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {` לחצולסיום, שמרו את הקובץ בדרך הבאה: Save As  ואז לחצו על Save as Type  ובחרו PDF`}
                                                                </li>
                                                                <li style={{ listStyle: "lower-roman" }}>
                                                                    {` זהו, סיימתם להפיק את החשבונית. את הקובץ הזה יש להעלות כאשר תגיע ההודעה על קבלת החבילה במחסן.`}
                                                                </li>
                                                            </ol>
                                                        </li>
                                                    </ol>
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 3 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '3'} onChange={handleChange('3')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '3' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לעקוב אחר החבילה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, בוודאי. באזור האישי באתר של רד-בוקס פרסל תוכלו לעקוב בכל רגע נתון אחר החבילה `}<br />
                                                    {`מרגע הגעתה למחסנים הלוגיסטיים שלנו. לפני הגעת החבילה למחסנים שלנו ניתן לעקוב אחר `}<br />
                                                    {`המוצר באמצעות שירותי המעקב של אתר המסחר הספציפי. רד-בוקס פרסל שולחת דואר `}<br />
                                                    {`אלקטרוני עם חיווי התקדמות של החבילה על שלבי השילוח השונים.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 4 */}{/*
                                    */}{/*<Accordion className='accordion' expanded={expanded === '4'} onChange={handleChange('4')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '4' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`כיצד מחושב התשלום לשחרור החבילה והמשלוח?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`שילוח החבילה נקבע בהתאם למחירון המבוסס על המשקל הפיסי או הנפחי של החבילה (לפי`}. <br />
                                                    {`הגבוה מבניהם). רד-פרסל מאפשרת לכם לקבל מחיר סופי שכולל את מלוא עלויות המשלוח `}<br />
                                                    {`למחסנים יידרש תשלום עבור המשלוח ולאחר כ-5 ימי עסקים המשלוח יגיע אליכם עד פתח הבית!`}<br />
                                                    {`והמיסים. ללא הפתעות וללא אותיות קטנות. היכנסו להכיר את מדיניות המחירים ולוחות`}<br />
                                                    {`הזמנים.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>*/}{/*
                                    */}{/* 5 */}{/*
                                    */}{/*<Accordion className='accordion' expanded={expanded === '5'} onChange={handleChange('5')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '5' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מהו איחוד חבילות והאם אתם מספקים את השירות הזה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`איחוד חבילות מאפשר חיסכון משמעותי בהזמנת מספר מוצרים בחו"ל מאתרי מסחר שונים.`}. <br />
                                                    {`המוצרים מגיעים במקביל למרכז הלוגיסטי ונשלחים כחבילה אחת בתוספת של כ-__ דולרים `}<br />
                                                    {`בגין כל חבילה מצורפת.`}<br /><br />
                                                    {`מדובר בשירות שהעיקרון שלו הוא תשלום על משלוח אחד בעבור כמה מוצרים ביחד, ולא `}<br />
                                                    {`תשלום נפרד של משלוח לכל חבילה וחבילה. החבילות נארזות באופן אופטימלי על ידי הצוות`}<br />
                                                    {`המיומן במחסני רד-בוקס פרסל. `}<br /><br />
                                                    {`במידה שעלות החבילות הכוללת תהיה גבוהה מ-75$, החיוב במס בגין הטובין יהיה בהתאם`}<br />
                                                    {`לסיווג והערך. אם לא ניתן לבצע איחוד לחבילות רד-בוקס פרסל תיצור קשר עם הלקוח ולא`}<br />
                                                    {`יתבצע חיוב. את הבקשה לאיחוד חבילות אפשר להגיש בבחירת ההזמנות הרלבנטיות באזור`}<br />
                                                    {`האישי.`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>*/}{/*
                                    */}{/* 6 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '6'} onChange={handleChange('6')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '6' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`כיצד מתקיימת ההפצה בישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`לאחר שהחבילה הגיעה לישראל ושוחררה מהמכס, היא מועברת למרכז הפצה מקומי בארץ,`}. <br />
                                                    {`ממנו תשלח במהירות ליעדה בנקודת האיסוף או בבית הלקוח. הלקוח מקבל הודעת SMS ביום`}<br />
                                                    {`ההפצה עם מספר הטלפון של השליח ופרטי המשלוח המיועד. השירות מגיע לכל יישוב בישראל,`}<br />
                                                    {`כולל יהודה ושומרון.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 7 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '7'} onChange={handleChange('7')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '7' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מהי מדיניות ההחזרות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`שימו לב כי ייתכן שאתם זכאים להחזר מס בהחזרת חבילה לחו"ל. ניתן לעשות זאת על ידי ביצוע
ייצוא מסודר הכולל בקשה רשימון ייצוא מהחברה ממנה תהיו מעוניינים לשלוח את החבילה. עם
קבלת הרשימון עליכם להגיש בקשה מסודרת למחלקת תב"ג בבית המכס בנמל התעופה בן
גוריון.`}

                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 8 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '8'} onChange={handleChange('8')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '8' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך משנים את כתובת המשלוח?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`בעת הסדרת התשלום לשליחת החבילה ממחסני רד-בוקס פרסל לישראל, ניתן לשנות את`}<br />
                                                    {`כתובת המשלוח בקלות וללא עלות נוספת. שינוי כתובת לפני התשלום יכול להתבצע באזור`}<br />
                                                    {`האישי. שינוי הכתובת לאחר הגעת החבילה לישראל או לאחר ביצוע התשלום יהיה כרוך`}<br />
                                                    {`שקלים ועלול לגרור עיכוב מסוים בזמני האספקה. שקלים ועלול לגרור עיכוב מסוים בזמני האספקה.`}<br />

                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 9 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '9'} onChange={handleChange('9')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '9' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לשלוח באמצעות רד-בוקס פרסל בוקס חבילה שלא שילמתי עבורה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, בוודאי. עם זאת זכרו כי על מנת לשלוח את החבילה לישראל יש להזין חשבונית קנייה לטובת `}<br />
                                                    {`חישוב עלויות המס. יש למלא טופס הצהרה לייבוא טובין.`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 10 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '10'} onChange={handleChange('10')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '10' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לאחסן חבילות במחסני רד-בוקס פרסל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, בשמחה. אחסון חבילה במחסנים שלנו הינו ללא עלות בעשרת הימים הראשונים ובעלות של`}<br />
                                                    {`דולר לכל יום מעבר. חבילה שתמצא במחסנים באירופה/בארה"ב למעלה מ-30 ימים תוגדר`}<br />
                                                    {`כ"חבילה נטושה".`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 11 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '11'} onChange={handleChange('11')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '11' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לייבוא דרכם חבילות במשקלים ו/או אורכים חריגים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, בוודאי, אם כי לעתים בעלות נוספת. במידה שאחת מצלעות החבילה ארוכה מ-1.2 מ' ו/או`}<br />
                                                    {`החבילה שוקלת 30 ק"ג ויותר, הייבוא האישי יהיה כרוך בתשלום נוסף בסך 35$. לא ניתן לייבא`}<br />
                                                    {`חבילות אשר אחת מצלעותיהן ארוכות מ-2.7 מ'.`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 12 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '12'} onChange={handleChange('12')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '12' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מי אחראי על שחרור החבילה מהמכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`רד-בוקס פרסל מספקת שירות מקיף ומלא הכולל גם אחריות על שחרור החבילה מהמכס`}<br />
                                                    {`והעברתה להפצה על ידי שליח.`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 13 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '13'} onChange={handleChange('13')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '13' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ישנו רף מקסימלי לפטור ממכס בייבוא אישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן. על פי דיני המכס בישראל, חבילות בעלות של עד 75$ (לא כולל) אינן חייבת במע"מ, מכס או `}<br />
                                                    {`מסי קניה (למעט מוצרי טבק ואלכוהול). למידע נוסף לגבי מיסוי בייבוא אישי `} <a onClick={() => { window.open("https://www.gov.il/he/departments/topics/customs-personal-import/govil-landing-page", "_blank") }}>{`לחצו כאן.`}</a><br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 14 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '14'} onChange={handleChange('14')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '14' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`אלו מיסים חלים על ייבוא אישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ייבוא אישי יכול להיות כרוך במיסוי בגין מע"מ (מס ערך מוסף), מכס ומס קנייה. מס קנייה חל על`}<br />
                                                    {`מוצרים מסוימים בהתאם להגדרות החוק הישראלי. המס מחושב בשיעור משתנה בהתאם לסוג`}<br />
                                                    {`המוצר ולפי המחיר הסיטוני של המוצר. תשלום מכס הוא בגין ייבוא טובין וסחורות לישראל.`}<br />
                                                    {`למידע נוסף לגבי מיסוי בייבוא אישי .`} <a onClick={() => { window.open("https://www.gov.il/he/departments/topics/customs-personal-import/govil-landing-page", "_blank") }}>{`לחצו כאן.`}</a><br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 15 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '15'} onChange={handleChange('15')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '15' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש פטורים והנחות במיסוי בייבוא אישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`במידה שאתם מייבאים חבילה שמחירה איננו גבוה מ-75 דולרים, למעט טבק ואלכוהול, לא`}<br />
                                                    {`תחויבו במיסוי נוסף. יודגש כי עלות הביטוח וההובלה לא נכללים בשקלול המס ככל שהם`}<br />
                                                    {`מחושבים בנפרד מהעלות של המוצר. `}<br /><br />
                                                    {`מוצרים שעלותם הינה בין 75$ ל-500$ יכולים להימצא חייבים במע"מ ומס קנייה. הקביעה נעשית`}<br />
                                                    {`בהתאם לערך הטובין ככל שהוא נפרד מעלויות הביטוח וההובלה. במידה שישנה תחולת מס,`}<br />
                                                    {`המס מחושב לפי ערך המוצר בתוספת דמי ביטוח והובלה.`}<br />

                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 16 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '16'} onChange={handleChange('16')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '16' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מי מבצע את חישוב עלויות המס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`חישוב עלויות המס בישראל נעשה לפי נוהל שחרור טובין באמצעות נציגי חברת רד-בוקס פרסל.`}<br />
                                                    {`החישוב מבוצע כנגזרת מהסכום הכולל שבחשבונית הספק, בתוספת של 5$ כנגד כל ק"ג ולא`}<br />
                                                    {`פחות מרף מינימאלי של 15$. להרחבה `}<a onClick={() => { window.open("https://www.gov.il/he/departments/topics/customs-personal-import/govil-landing-page", "_blank") }}>{`לחצו כאן.`}</a>{` להסבר באתר רשות המיסים.`}<br />

                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 17 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '17'} onChange={handleChange('17')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '17' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש הגבלה על מספר המוצרים שניתן לייבא בייבוא אישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ייבוא אישי מיועד לצריכה אישית וקיימת הגבלה על מספר המוצרים שניתן לייבא באופן פרטי.`}<br />
                                                    {`היקף המוצרים המותר משתנה בין הקטגוריות כאשר הממוצע עומד על כ-3 מוצרים בלבד.`}<br />
                                                    {`במידה שתרצו לייבא מספר גדול יותר של מוצרים תידרשו לשאת בעלויות מס נוספות ו/או בגין`}<br />
                                                    {`כל הוצאה נוספת אשר תידרש ע"י הרשויות בישראל.`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 18 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '18'} onChange={handleChange('18')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '18' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מהו ערך טובין ריאלי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המכס רשאי לבדוק לגבי מוצרים מסוימים אם קיימת תאימות בין הערך בחשבונית לערך הטובין`}<br />
                                                    {`הריאלי (ביחס לטובין זהים אשר יובאו בעבר). אם המכס סבור כי קיים פער בין השניים הוא יכול`}<br />
                                                    {`להוציא "גירעון" ולחייב תשלום מיסוי בגין ההפרש.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 19 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '19'} onChange={handleChange('19')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '19' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מהי עמילות מכס ומתי משלמים אותה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`עמילות מכס הינה שירות של גורם מקצועי אשר מייצג את היבואן מול הנמלים ורשויות המכס.`}<br />
                                                    {`להלן המדרגות העיקריות של עמלות שחרור מכס נכון לאוגוסט 2021:`}<br />
                                                    <li className="right_mark_bullet">
                                                        {` עד 75$ – ללא עמלה.`}
                                                    </li>
                                                    <li className="right_mark_bullet">
                                                        {` 999$ - ללא עמלה`}
                                                    </li>
                                                    <li className="right_mark_bullet">
                                                        {` 5000$ - 1,000$ - 75$.`}
                                                    </li>
                                                    {`לנוחיותכם, המחירים המוצגים אתר רד-בוקס פרסל כוללים מראש את כל הוצאות השחרור של `}<br />
                                                    {`החבילה, לרבות עמילות מכס.`}<br />
                                                    {`משלוחי חלקי חילוף לרכב ששויים מעל 75$ יחויבו בעמלה`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 21 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '21'} onChange={handleChange('21')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '21' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם גם מתנות או פיצויים מספקים בחו"ל כפופים למיסוי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן, כל מוצר המגיע לישראל מחויב ברשויות המס לפי ערכו.`}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 22 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '22'} onChange={handleChange('22')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '22' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ייבוא טלפונים סלולריים או מחשבים אישיים כפוף לאישור משרד התקשורת?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`משרד התקשורת מחייב אישור ייבוא אישי רק בייבוא של 6 טלפונים סלולריים ומעלה או 3 יחידות`}<br />
                                                    {`מחשב ויותר.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 23 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '23'} onChange={handleChange('23')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '23' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש מוצרים אשר המיסוי בגינם גבוה יותר או שיש להנפיק עבורם אישור ייבוא?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`התשובה חיובית. קטגוריות מסוימות כוללות מוצרים שחיובי המיסוי בגינם גבוהים מהרגיל וישנם גם מוצרים אשר מותרים בייבוא רק בכפוף לאישור פרטני.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    */}{/* 24 */}{/*
                                    <Accordion className='accordion' expanded={expanded === '24'} onChange={handleChange('24')} id="more_info">
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '24' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ישנם מוצרים אשר אסורים בייבוא לישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כמובן. ישנה רשימה ומפורטת של מוצרים וחומרים אסורים לייבוא לישראל. לרשימה המלאה `}<a onClick={() => { window.open("https://www.gov.il/he/departments/topics/customs-personal-import/govil-landing-page", "_blank") }}>{`לחצו כאן.`}</a>{`. `}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '241'} onChange={handleChange('241')} >
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '241' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`בתוך כמה זמן אקבל הודעה על הגעת המשלוח שלי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כןהמשלוחים נקלטים אצלנו במחסנים עד 48 שעות מרגע מסירתם. `}<br />
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '242'} onChange={handleChange('242')} >
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '242' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לבטח את המטענים אשר נשלחים דרכינו?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`כן. `}<br />
                                                    {`בתהליך העלאת החשבונית ותשלום עבור השילוח אנו מציעים את שרות הביטוח.`}<br />
                                                    {`יתרה מזאת, אנחנו ממליצים לבטח את החבילות.`}<br />
                                                    {`*הביטוח מכסה נזק פיזי למוצר / אובדן בתהליך שילוח ושחרור מכס.`}<br />
                                                    {`הפיצוי כולל את הערך של המוצרים בחשבונית, עלויות השילוח ומיסי הייבוא.`}<br />
                                                    {`שירות הביטוח נכנס לתוקף ברגע שבו שולם הביטוח עבור החבילה ומסתיים במסירתה ללקוח ו/או עם חתימתו על תעודת המשלוח.`}<br />
                                                    {`בקשה לכיסוי ביטוחי נדרשת עד ליום עסקים הבא לאחר קבלת המשלוח.`}<br />
                                                    {`ביטוח החבילות אינו חובה וניתן להסירו. במקרה כזה, לא תהיה רד בוקס פארסל אחראית לכיסוי של נזק או אובדן.`}<br />
                                                    {`הערה: הביטוח אינו מכסה את המוצרים הבאים:`}<br />
                                                    */}{/*<b>{`הערה: `}</b>{`הביטוח אינו מכסה את המוצרים הבאים:`}<br />*/}{/*

                                                    <li className="">
                                                        {`- שבירים – כלי פורצלן, זכוכית וקרמיקה.`}
                                                    </li>
                                                    <li className="">
                                                        {`- יצירות וחפצי אומנות`}
                                                    </li>
                                                    <li className="">
                                                        {`- יצירות וחפצי אומנות`}
                                                    </li>
                                                    <li className="">
                                                        {`- מוצרי יד שניה. (אובדן בלבד).`}
                                                    </li>
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '243'} onChange={handleChange('243')} >
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '243' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`עדכון על נזק להפעלת פוליסת ביטוח `}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`בכל מקרה של נזק ובקשה לכיסוי הביטוחי יש לפנות לכל המאוחר לשירות הלקוחות במהלך יום העסקים שלאחר קבלת החבילה.`}<br />
                                                    {`יש לתאר את הנזק ולהוסיף תמונות אשר ממחישות את תיאור הנזק.`}<br />

                                                    {`במקרה של הפעלת הביטוח לכיסוי הנדרש יחולו דמי השתתפות עצמית לפי המדרגות הבאות:`}<br />


                                                    <li className="">
                                                        {`•	עד 99 דולר – אין השתתפות עצמית`}
                                                    </li>
                                                    <li className="">
                                                        {`•	100 דולר ועד 149 דולר - השתתפות עצמית בסך 25 דולר`}
                                                    </li>
                                                    <li className="">
                                                        {`•	150 דולר ועד 449 דולר – השתתפות עצמית בסך של 40 דולר`}
                                                    </li>
                                                    <li className="">
                                                        {`•	450 דולר ועד 1199 דולר – השתתפות עצמית בסך של 60 דולר`}
                                                    </li>
                                                    <li className="">
                                                        {`מעל 1200 דולר – השתתפות עצמית בסך של 240 דולר•	`}
                                                    </li>
                                                    <b className="">
                                                        {`פרוט כיסוי ביטוחי`}
                                                    </b>
                                                    <li className="">
                                                        {`•	במקרה של מטען אשר הוכרז כאובדן – יזוכה הלקוח על  עלות השילוח + עלות המוצר `}
                                                    </li>
                                                    <li className="">
                                                        {`•	במקרה של נזק מהותי  – יזוכה הלקוח על עלות השילוח +עלות המוצר רק לאחר שהמוצר ייאסף על ידי חברת רדבוקס בע"מ.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	במקרה של חבילה המורכבת ממספר פריטים המופיעים בחשבונית ופריט אחד ניזוק, יזוכה`}
                                                    </li>
                                                    <li className="">
                                                        {`•	הלקוח זיכוי יחסי בהתאם לעלות הפריט בחשבונית . רק לאחר שהמוצר ייאסף על ידי חברת רדבוקס בע"מ.`}
                                                    </li>
                                                    <b className="">
                                                        {`-  תשלום הביטוח יבוצע במהלך 14 ימי עסקים, מרגע אישור התביעה ע"י רדבוקס בע"מ.`}
                                                    </b>
                                                    <br />
                                                    <b className="">
                                                        {`- הכיסוי הביטוחי תקף אך ורק למטענים אשר נרכשה עבורם פוליסת הביטוח בשלב תשלום השילוח.`}
                                                    </b>
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion id="consolidationlinkTarget" className='accordion' expanded={expanded === '244'} onChange={handleChange('244')} >
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '244' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`באילו מקרים אין כיסוי ביטוחי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>

                                                    <li className="">
                                                        {`•	במקרה של אובדן/נזק של חבילה שאינה מבוטחת, לא יקבל הלקוח זיכוי כלל.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	במקרים בהם רק האריזה נפגמה, אך המוצר ללא פגע, אין כיסוי ביטוחי.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	במקרה של פגם חלקי במוצר , לא יהיה ניתן לבצע זיכוי חלקי אלא ,יזוכה זיכוי מלא לאחר שהמוצר ייאסף על ידנו .`}
                                                    </li>
                                                    <li className="">
                                                        {`•	במקרים בה החבילה מגיעה פגומה מהספק, לא יבוצע זיכוי כלל.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	חברת רד בוקס לא תהיחה אחראית למשלוחים אשר הונחו מחוץ למחסן במהלך שבת או ראשון, או שנמסרו לכתובת שגויה.`}
                                                    </li>
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '245'} onChange={handleChange('245')} >
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '245' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן להזמין מאנגליה ושוויץ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`באופן עקרוני אין הגבלה בתנאי שהמוכרים שולחים להולנד. יחד עם זאת, מכיוון שמדינות אלה אינן שייכות לאיחוד האירופי, יידרש שחרור ממכס בהולנד (מיקום המרכז הלוגיסטי של רד בוקס באירופה), עניין זה כרוך בתשלום עלויות מקומיות ומיסים מקומיים.`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '246'} onChange={handleChange('246')} >
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '246' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם משתלם לבצע איחוד חבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`רד בוקס מאפשרת ביצוע של איחוד חבילות, אולם רק במקרים בודדים שרות זה משתלם.`}<br />
                                                    {`ברוב המכריע של המקרים, תהליך איחוד החבילות `}<a href="">{`אינו משתלם!`}</a><br />
                                                    {`הסיבות העיקריות לכך:`}
                                                </Typography>
                                                <Typography className='dir-rtl'>

                                                    <li className="">
                                                        {`•	כמעט ללא יוצא מהכלל, נפח החבילה המאוחדת גדול בצורה משמעותית מסך החבילות המאוחדות.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	מדיניות התמחור הזולה של רדבוקס מייתרת את הצורך באיחוד.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	ברד בוקס חיוב השילוח מדוייק ונאמן לגודל האמיתי של החבילה וזאת בזכות מדרגות משקל של 100`} <br />
                                                        {`גר' כלומר, אין הפסד נפח בחבילה הבודדת.`}
                                                    </li>
                                                    <li className="">
                                                        {`•	מיסים ומע"מ באיחוד חבילות סך הערך שלהן עולה על 75 דולר.`}
                                                    </li>
                                                </Typography>

                                                <Typography className='dir-rtl' style={{ color: "#d70007" }}>
                                                    <a href="https://redboxparcel.com/blog?p=package-consolidation">{`קרא עוד`}</a>
                                                </Typography>
                                                <Typography className='dir-rtl' style={{ color: "#d70007" }}>
                                                    {`בשל כך, אנו ממליצים להמנע מאיחוד חבילות!`}
                                                </Typography>
                                                */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>*/}

                        </Grid>
                    </Box>
                </Box>
                {<Grid container className='fillter-tabs' style={{ justifyContent: isMobile() ? "flex-end" : "center" }}>
                    <ul className='tabs-list sub_tabs_list' style={{ marginRight: "20px", border: "none", fontSize: "24px", color: "#d70007", fontWeight:"bold" }}>
                        <li className='cursor_pointer whitespace_nowrap' style={{ textAlign: isMobile() ? "right" : "center", listStyle: isMobile() ? "inside" : "none", textDecoration: isMobile() ? "underline" : "underline" }} onClick={() => { document.getElementById('more_info_41').scrollIntoView(); }}>מוצרים אסורים להטסה ושילוח</li>
                        <li className='cursor_pointer whitespace_nowrap' style={{ textAlign: isMobile() ? "right" : "center", listStyle: isMobile() ? "inside" : "none", textDecoration: isMobile() ? "underline" : "underline" }} onClick={() => { document.getElementById('more_info').scrollIntoView(); }}> יבוא אישי של חלקי חילוף </li>
                        <li className='cursor_pointer whitespace_nowrap' style={{ textAlign: isMobile() ? "right" : "center", listStyle: isMobile() ? "inside" : "none", textDecoration: isMobile() ? "underline" : "underline" }} onClick={() => { document.getElementById('more_info_2').scrollIntoView(); }}> מיסים בשילוח לישראל </li>
                        
                        <li className='cursor_pointer whitespace_nowrap' style={{ textAlign: isMobile() ? "right" : "center", listStyle: isMobile() ? "inside" : "none", textDecoration: isMobile() ? "underline" : "underline" }} onClick={() => { document.getElementById('more_info_3').scrollIntoView(); }}> חומרים ומוצרים אסורים בייבוא </li>
                        <li className='cursor_pointer whitespace_nowrap' style={{ textAlign: isMobile() ? "right" : "center", listStyle: isMobile() ? "inside" : "none", textDecoration: isMobile() ? "underline" : "underline" }} onClick={() => { document.getElementById('more_info_4').scrollIntoView(); }}> הגדרת ייבוא אישי </li>
                        
                        <li className='cursor_pointer whitespace_nowrap' style={{ textAlign: isMobile() ? "right" : "center", listStyle: isMobile() ? "inside" : "none", textDecoration: isMobile() ? "underline" : "underline" }} onClick={() => { document.getElementById('more_info_5').scrollIntoView(); }}> תנאי שימוש </li>
                    </ul>
                    <p id={'more_info_41'} >{``}</p>
                </Grid>}               
                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0' }}>
                    <Box className="container">

                        <Typography component={'h1'} className='section-title'>{`מוצרים אסורים להטסה ושילוח`}</Typography>
                        <p id="fromRightContentBelowPricing">{`בטריות Li-on (ליתיום איאון) ו/או סוללות נטענות שאינן מותקנות במכשיר.`}</p>
                        <p>{`מוצרים דליקים (שאינם עומדים בנהלי התעופה של מוצרים דליקים ומסוכנים).`}</p>
                        <p>{`גזים דחוסים (מוקפאים, דליקים, בלתי דליקים ורעילים).`}</p>
                        <p>{`חומרים מאכלים, כגון: חומצות, חומרי בסיס, סוללות מופעלות בתא רטוב ופריטים הכוללים כספית.`}</p>
                        <p>{`חומרי נפץ, תחמושת (כולל כדורים ריקים), אקדחים, זיקוקין, פיקות כדורי אקדח.`}</p>
                        <p>{`נוזלים וחומרים מוצקים דליקים, כגון בשמים, לקים, מצתים שיש להטות לפני שמציתים אותם, דלק למציתים, גפרורים, מציתים, צבעים וחומרים מדללים.`}</p>
                        <p>{`חומרים רדיו-אקטיביים.`}</p>
                        <p>{`תיקי מנהלים עם מנגנוני אזעקה.`}</p>
                        <p>{`חומרים מחמצנים, כגון אבקות הלבנה ומי חמצן.`}</p>
                        <p>{`רעלים וחומרים מדבקים, כגון קוטלי חרקים, קוטלי עשבים וחומרים המכילים וירוסים.`}</p>
                        <p>{`בהתאם לנהלי התעופה, עבור שילוח מוצרים דליקים נדרשים אישורי הטסה מיוחדים ואריזות בעלות תקן מיוחד, וגם במידה ויתקמו התנאים, לא ודאי שחברות התעופה יאשרו הטסה.`}</p>
                        <p>{`לפיכך, יש להמנע מיבוא אישי של פרטים אלה האסורים לשילוח בינלאומי והמוזכרים לעיל, כחומרים אשר אסורים לשילוח.`}</p>
                        <br />
                    </Box>
                </Box>
                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0' }} >
                    
                    <Box className="container">
                        <Typography component={'h1'} className='section-title'>{`יבוא אישי של חלקי חילוף לרכבים ואופנועים`}</Typography>
                        <p>עם רד בוקס ניתן לייבא חלקי חילוף לרכבים ואופנועים ובכך להוזיל את עלויות החלפים הגבוהות ברכישה מקומית.<br />
                        איתנו ניתן לייבא את מרבית חלקי החילוף בזול ובקלות ולחתוך את העלויות הבוהות ברכישה מקומית בארץ.<br />
                        וזאת מכיוון שאין יותר צורך בהנפקת אישורים ואישורי הניתנים בבדיקות מכס.<br />
                        ישנה רשימה נפרדת של חלקים (ברובם בטיחותיים) אשר נדרש בעבורם אישור משרד התחבורה.<br />
                        בקישור הבא הוספנו את <a className='link-underline' href={pdf} download>רשימת החלקים</a> לגביהם נדרש האישור</p>
                        {/* <p>בקישור הבא הוספנו את <a href='/file/list-of-spare-parts-for-which-is-required-for-approval.pdf' download>רשימת החלקים</a> לגביהם נדרש האישור</p> */}
                        <p> אם אתם מעוניינים לייבא חלקי חילוף בטיחותיים ניתן להוריד את טופס האישור בכתובת הבאה:
                        <br /> <a className='link-underline word_break' rel="noreferrer" href='http://media.mot.gov.il/PDF/HE_RECHEV/BakashaRisuiYevu.pdf' target="_blank">http://media.mot.gov.il/PDF/HE_RECHEV/BakashaRisuiYevu.pdf</a></p>
                        <p>את הטופס יש להגיש למשרד התחבורה לצורך קבלת האישור המתאים. <br /> בכל דרישה לסיוע, אנא צרו קשר </p>

                        <p>{``}</p><br />
                        <p id={'more_info_2'}>{``}</p><br />
                    </Box>
                </Box>
                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0' }} >
                    <Box className="container">
                        <Typography component={'h1'} className='section-title'>{`מיסים בשילוח לישראל`}</Typography>
                        { `יבוא אישי של טובין לישראל כרוך במיסוי ומוגדר בחוק הישראלי באופן הבא: "טובין המיובאים על ידי יחיד והמיועדים לשימושו האישי של האדם, או לשם מסירתם כמתנה לשימוש אישי, ובכמות סבירה לשימוש עצמי" (ההדגשות לא במקור). כלומר, החוק הישראלי משרטט הפרדה ברורה בין ייבוא אישי לייבוא מסחרי, והצרכנים הפרטיים נהנים מהקלות והטבות שחשוב להכיר.`}<br/>
                        {`שלושה מיסים עיקריים חלים על ייבוא אישי:`}
                        <ul>
                            <li>{`מס ערך מוסף – מיסוי שחל על כל חבילה בעלות של 75$ ומעלה (כולל). `} </li>
                            <li>{`מס קנייה – מס אשר הרשויות בישראל מטילות על מוצרים שונים לפי קטגוריה כמו מוצרי `}<br />{`טבק או אלכוהול (מטרתו לעודד רכישת תוצרת הארץ).`} </li>
                            <li>{`מכס – מס הנגבה בגין הכנסת טובין ומוצרים לישראל.`} </li>                            
                        </ul>
                        <b>{`הכלל הראשון לגבי מיסי ייבוא – פטור עד 75$`}</b>
                        <p>{`הכלל הראשון והחשוב ביותר הוא שחבילות שעלות המוצרים שבהן לא עולה על 75$ נהנות מפטור מלא ממיסי ייבוא (למעט מוצרי טבק ואלכוהול). כמו כן יש להדגיש כי החישוב של העלות לא כולל את דמי הביטוח והשילוח.`}</p>
                        <p>
                            { `לדוגמה, אם קניתם בגדים בעלות של 72$ ועלות המשלוח הינה 10$, תיהנו עדיין מפטור מוחלט ממסי ייבוא. במידה שהחבילה חייבת במיסוי, החישוב יכלול גם את דמי המשלוח והביטוח. למשל, הזמנתם חבילה בעלות של 80$ והמשלוח עמד על 10$? חישוב המיסוי יהיה על בסיס 90$.`}
                        </p>
                        <p style={{ border: "1px solid black", background:"#d4c857" }}><b>{`טיפ: `}</b>{`שמרו מרחק מסוים מהרף המקסימלי של 75 דולרים שכן די בחריגה של דולר אחד בכדי להביא לחיוב במס על המוצר בתוספת דמי משלוח וכו'. בדקו את שער הדולר ביום ההזמנה והשאירו "טווח ביטחון". `}</p>
                       
                        <b>{`מתי משלמים מע"מ, מכס או מס קנייה (במידה שחל)?`}</b>
                        <p>{`חבילות שהמוצרים שבהן הינם בעלות של 75$ עד 500$ חייבות במע"מ ולעתים גם במס קנייה. הבדיקה נעשית לפי ערך הטובין בנפרד מעלויות הביטוח וההובלה, אך החיוב יהיה בתוספת העלויות הללו. חבילות בעלות של 500$ ומעלה חייבות גם בתשלום מכס.`}</p>
                        <b>{`איחוד חבילות ומיסוי`}</b>
                        <p>{`אחת השיטות הטובות להוזלת ייבוא אישי היא איחוד חבילות. כלומר, הזמנת מוצרים ממספר חנויות שונות ושליחתם באריזה אחת (במקום לשלם שילוח לכל מוצר בנפרד). חישוב המס יהיה בהתאם לשווי הערך הכולל של המוצרים יחדיו. יודגש כי החישוב של עלויות הביטוח והמשלוח מצטרפות לסכום לטובת החיוב במס (וכך, כמובן, גם המס שייקבע). `}</p>


                        <b>{`קישורים נוספים:`}</b>
                        <p >{`✓ טבלת מיסים מרוכזים`}</p>
                        <b>{``}</b><br />
                        <p id={'more_info_3'}>{``}</p><br />

                    </Box>
                </Box>
                <p id={'more_info_4'}>{``}</p>
                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0' }}>
                    <Box className="container">
                        <Typography component={'h1'} className='section-title'>{`חומרים ומוצרים אסורים בייבוא`}</Typography>
                        <p>{ `הייבוא האישי לישראל איננו ניתן ללא הגבלה וישנה רשימה של מוצרים וחומרים אשר אסורים בייבוא אישי והם מפורטים בצו המכס (איסור יבוא), תשס"ה-2005.`}</p>
                        <p>{ `צו זה מפרט מספר רמות של איסור ייבוא טובין לישראל – לעתים בהתאם לטובין עצמם ולעתים לפי ייעודם האסור בייבוא. למשל (לא רשימה סגורה):`}</p>
                        <ul>
                            <li>{`מוצרים מגונים או פרוצים.`} </li>
                            <li>{`דברי פרסומת וכרטיסים להימורים או הגרלות, שאין היתר מראש לעריכתם.`} </li>
                            <li>{`מכונות משחק ומשחקי מזל. `} </li>
                            <li>{`טובין שיש בהם משום הטעיה (לפי סעיף 2 לחוק הגנת הצרכן).`} </li>
                            <li>{`זיופים וחיקויים של מסמכי העדפה, חשבון מכר או תעודת מקור.`} </li>
                            <li>{`חיקויים וזיופים של מסמכים, מטבעות, שטרי בנק ושטרות כסף. `} </li>
                            <li>{`שקים משומשים אשר שימשו לאריזת חומרים צמחיים.`} </li>
                            <li>{`סכינים כהגדרתם לפי סעיף 184 לחוק העונשין (למעט אולרים ולמעט סכינים שנועדו למלאכה, מקצוע, צורכי בית, עסק או מטרה כשרה).`} </li>
                            <li>{`טובין המשמשים לשיבוש מדי מהירות בלייזר. `} </li>
                            <li>{`כלי ירייה דמויי אקדח הזנקה, עט, כליי ירידה המופעלים בגז וכדומה. `} </li>
                            <li>{`מכלי גז מדמיע המעוצבים ככלי ירייה. `} </li>
                            <li>{`חומרים מתלקחים. `} </li>
                            <li>{`חומרי נפץ. `} </li>
                            <li>{`משחקים אסורים (לפי הגדרת המושג "משחק עסוק" בסעיף 224 לחוק העונשין). `} </li>
                            <li>{`צעצועים מסוכנים. `} </li>
                            <li>{`חומרים פורנוגרפיים. `} </li>
                            <li>{ `טלפונים אלחוטיים בתחומים של 900 מגה-הרץ או טלפונים אלחוטיים בשיטת שידור DECT6.`}</li>
                            <li>{ `נתבים אלחוטיים אשר חורגים מהתדרים שבין 51.50 ל-53.50 מגה-הרץ.`}</li>
                            <li>{ `ציוד משומש המיועד לטובת גידול דבורים.`}</li>
                            <li>{ `בשר טרי ומוצרי בשר טרי. דגים טריים ומוצרי דגים טריים. `}</li>
                            <li>{ `מוצרי חלב טריים.`}</li>
                            <li>{ `טובין שעלולים לסייע בהסתה לאלימות, טרור או גזענות (לפי סימן א'1 לפרק ח' בחוק `}</li>
                            <li>{ `העונשין) וכן טובין אשר יש בהם כדי לגלות הזדהות או אהדה לארגוני טרור 
טובין המשמשים לצריכה או הכנה של סמים מסוכנים. 
`}</li>
                        </ul>
                        
                        
                        <p>{`לצד הטובין האסורים בייבוא ישנם מוצרים שייבואם ארצה כרוך באישור ממשרד ממשלתי רלבנטי. למשל (לא רשימה סגורה):`}</p>
                        <ul>
                            <li>{`ייבוא חלקי חילוף לרכבים – אישור משרד התחבורה`}</li>
                            <li>{`ייבוא צמחים – אישור מטעם משרד החקלאות. `}</li>
                            <li>{`ייבוא מוצרים נלווים למכוניות ורכבים – אישור מעבדת רכבים מוסמכת. `}</li>
                            <li>{`ייבוא רחפנים ומוצרי תקשורת מסוימים – אישור משרד התקשורת. `}</li>
                            
                        </ul>
                        <br />
                    </Box>
                </Box>

                



                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0' }}>
                    <Box className="container">
                        <Typography component={'h1'} className='section-title'>{`הגדרת ייבוא אישי – מה ההבדל בין ייבוא אישי לייבוא מסחרי?`}</Typography>
                        <p>{ `ייבוא אישי הוא ייבוא של צרכן פרטי לצריכתו האישית וייבוא מסחרי הוא מהלך עסקי של ייבוא מוצרים לשיווק והפצה. שימו לב כי מדובר בסטאטוס משפטי המקפל בחובו משמעויות רבות מבחינת הכנסת המוצרים לישראל והמיסוי החל עליהם. להלן הקווים המרכזיים לגבי ההבדל בין ייבוא אישי לייבוא מסחרי. `}</p>
                        <p>{ `המושג "ייבוא אישי" מוגדר במסגרת צו ייבוא אישי, תשע"ט-2019 בתור ייבוא אישי של טובין (מוצרים) אשר מתקיימים במסגרתו כל התנאים הבאים:`}</p>
                        <ul>
                            <li>{`המוצרים נרכשו לטובת השימוש האישי או המשפחתי של הצרכן שקנה אותם. `} </li>
                            <li>{`הרכישה לא נעשית על ידי עוסק אלא על ידי אדם פרטי. `} </li>
                            <li>{`המוצרים לא מיועדים למתן שירותים, ייצור או אספקה. `} </li>
                            <li>{`המוצרים נרכשו ב"כמות סבירה" (מיד הסבר). `} </li>
                           
                        </ul>
                        
                        
                        <p><b>{ `מהי כמות סבירה? `}</b>{`על פי צו ייבוא אישי, כמות סבירה היא עד 30 יחידות במשלוח שעלותו 1,000 דולרים או עד 5 יחידות במשלוח שעלותו מעל 1,000 דולרים. יצוין כי חבילות שנשלחות למייבא מאותו ספק בהפרש של עד 72 שעות נחשבות כמשלוח אחד.` }</p>
                        <p><b>{`שימו לב:`}</b>{`מנהל רשות המיסים יכול לקבוע לגבי מוצרים מסוימים שהם אינם "ייבוא אישי" למרות עמידה לכאורה בתנאים שהוגדרו בצו. למשל, ייבוא של יותר מ-5 מוצרים בשנה שעלות כל אחד מהם הינה מעל 200 דולרים או ייבוא של יותר מ-30 יחידות בשנה של מוצרים שמחירם פחות מ-200 דולרים. ` }</p>
                        <p id="from_registration">{`חשוב להדגיש כי ישנם מוצרים שהכללים האלה אינם חלים עליהם ובעיקר מוצרים יקרים כמו כלי רכב, כלי טיס לא מאוישים וכדומה. מעבר לכך, כל המוצרים שמיובאים לארץ ואינם עונים על הקריטריונים בצו הם –`}<b>{`שימו לבייבוא מסחרי.`}</b></p>
                        
                        <p id={'more_info_5'}>{``}</p><br />
                    </Box>
                </Box>

                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0', fontSize:"20px" }}>
                    <Box className="container">
                        <p>{`לתנאי שימוש לחץ `}<span><a href="/TnC.pdf">{`כאן`}</a></span></p>
                    </Box>
                </Box>
                {/*<Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0' }}>
                    <Box className="container">
                        <Typography component={'h1'} className='section-title'>{`הסכם תנאי שימוש  –  רד-בוקס פרסל`}</Typography>
                        <Grid container style={{ direction: "ltr" }} sx={{ justifyContent: { sm: 'space-between', xs: 'center', maxHeight: "500px", overflow: "auto" } }}>
                            <Grid container style={{ direction: "rtl", marginRight: "20px" }} sx={{ justifyContent: { sm: 'space-between', xs: 'center' } }}>
                                {`1.`}&nbsp;&nbsp;&nbsp;&nbsp;{`אתר רד-בוקס פרסל (להלן:"רד-בוקס פרסל") פועל ומנוהל ע״י מנהלי חברת רד-בוקס פרסל ו/או מי מטעמה.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`2.`}&nbsp;&nbsp;&nbsp;&nbsp;{`האתר מאפשר שילוח מוצרים מאתרי צד ג׳ אשר אינם שולחים לישראל, כשאשר הגולש ו/או המשתמש(להלן: "המשתמש") מקבל כתובת הממוקמת במדינות להן אותם אתרי צד ג׳ מבצעים שילוח.`}<br />
                                {`3.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל דואגת לשילוח מכתובת המחסן בחו״ל לכתובת המשתמש בארץ.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`4.`}&nbsp;&nbsp;&nbsp;&nbsp;{`לאחר מילוי פרטים ופתיחת יוזר תינתן האפשרות להשתמש באתר, לקבל כתובת במדינה המבוקשת ולבדוק עלויות שילוח ומיסוי.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`5.`}&nbsp;&nbsp;&nbsp;&nbsp;{`תנאי השימוש והממשק באתר רד-בוקס פרסל מנוסחים בלשון זכר מטעמי נוחות בלבד, והם מתייחסים, כמובן, גם לנשים.`}<br />
                                {`6.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הסכם זה ומדיניות בעלי האתר, היא לסייע למשתמש להזמין מוצרים מאתרי צד ג׳ אשר אינם מבצעים שילוח לישראל ולסייע בשילוח מהמרכז הלוגיסטי לכתובת המשתמש בישראל.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל אינה אחראית לטיב המוצרים ולא תשא בנזק הצדדים זה לזה ככל ויהיו.`}<br />
                                {`7.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל רשאית לערוך שינויים ותיקונים בתנאי שימוש אלו, על פי שיקול דעתה הבלעדי ועל המשתמש האחריות להתעדכן מעת לעת.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`8.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל רשאית להסיר משתמש שעבר על הסכם זה באופן מיידי ו/או מכל סיבה שתראה לנכון ואינה מתחייבת למתן הסבר.`}<br />
                                {`9.`}&nbsp;&nbsp;&nbsp;&nbsp;{`גלישה ו/או שימוש באתר (להלן: "האתר") על ידך, שימוש במוצרים ו/או השירותים של האתר, ביקור באתר ללא הרשמה ("מבקר"), הרשמה לאתר ו/או פתיחת חשבון, מהווים, כל אחד מהם, הסכמה לתנאי השימוש שלהלן, שנקבעו על ידי רד-בוקס פרסל.`}<br />
                                {`10.`}&nbsp;&nbsp;&nbsp;&nbsp;{`עצם השימוש באתר או בכל שירות הקיים באתר, כולל ומבלי להגביל, ביקור וגלישה באתר, מהווה הסכמתך לתנאי הסכם זה.`}<br />
                                {`11.`}&nbsp;&nbsp;&nbsp;&nbsp;{`המשתמש ו/או המבצע כל פעולה באתר ו/או מבצע שימוש בשירות הקיים באתר מצהיר כי הינו מודע לתנאי השימוש באתר ומסכים לקבלם, וכי לא יהא לו או למי מטעמו כל טענה ו/או תביעה ו/או דרישה נגד האתר ו/או נגד החברה ו/או נגד מפעיל האתר, בעלי האתר, בעלי רד-בוקס פרסל, סוכניהם, שלוחיהם ו/או כל מי מטעמם, מלבד טענות הקשורות בהפרת התחייבויות רד-בוקס פרסל על פי תנאי השימוש.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`12.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רק הכלים שיפורסמו בתנאי שימוש אלו יחייבו את " טוטל קר לוג’יסטיקס" כלפי מבצעי הפעולות ו/או המשתמשים באתר.`}<br />
                                {`13.`}&nbsp;&nbsp;&nbsp;&nbsp;{`במקרה של תלונה, תביעה, מחלוקת,ו/או עימות שבו המשתמש מעורב עם צד שלישי, רד-בוקס פרסל רשאית למסור פרטים ו/או כל מידע נחוץ על מנת ליישב את העימות.`}<br />
                                {`14.`}&nbsp;&nbsp;&nbsp;&nbsp;{`האתר משמש כאמור להנגשת המשלוחים מאתרים בינלאומים אשר אינם מבצעים שילוח לישראל ועל כן רד-בוקס פרסל אינה אחראית לשירותים ותכנים של צד שלישי.`}&nbsp;&nbsp;&nbsp;&nbsp;{`אביזרים, מוצרים, תכנים ושירותים אלו אינם בשליטתה ואין לראות בה כמי שמוכרת אותם ועל כן לא תישא באחריות או בחבות עבור שירות שסופק על ידי צד ג׳.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יצוין כי חל איסור מוחלט לבצע עסקאות של מוצרים לא חוקיים לרבות נשקים ברישיון ורד-בוקס פרסל עלולה לחסום לצמיתות משתמש אשר יעבור על סעיף זה.`}<br />
                                {`15.`}&nbsp;&nbsp;&nbsp;&nbsp;{`זכות השימוש באתר הינה אישית למשתמש ולא ניתנת להעברה לכל צד ג'.`}<br />
                                {`16.`}&nbsp;&nbsp;&nbsp;&nbsp;{`המשתמש הינו האחראי הבלעדי להגנה על פרטי הגישה לאתר ו/או בכל הקשור בו.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`17.`}&nbsp;&nbsp;&nbsp;&nbsp;{`המשתמש מכיר בכך שהשימוש באינטרנט כרוך לעיתים בהפרעות, שאינן בשליטת רד-בוקס פרסל, ו רד-בוקס פרסל לא תהיה אחראית לכל שיבוש ולכל איבוד מידע בזמן העברת מידע על גבי האינטרנט.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ייתכן והאתר לא יהא זמין מעת לעת מכל סיבה שהיא, לרבות בשל תחזוקה שוטפת.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בשל נסיבות התלויות ברד-בוקס פרסל וכן בשל נסיבות אשר אינן תלויות ברד-בוקס פרסל, כמו כן, יתכן שגישה לאתר תופרע, תושעה ו/או תופסק, באופן זמני או לצמיתות.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הנך פוטר את רד-בוקס פרסל בגין כל נזק ו/או תשלום ו/או הפסד שייגרמו לך עקב האמור לעיל.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`18.`}&nbsp;&nbsp;&nbsp;&nbsp;{`לרד-בוקס פרסל תהיה הזכות, בכל עת, לשנות את מבנה המספר ו/או להפסיק כל מאפיין ו/או תכונה ו/או שימוש של האתר,לרבות תוכן, שעות פעילות וציוד הנדרש לצורך גישה ושימוש באתר.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל רשאית להפסיק הפצתו של כל מידע, רשאית לשנות או להפסיק כל שיטת העברת מידע ורשאית לשנות את מהירויות העברת המידע או כל מאפיין אותות ו/או מאפיין שידור אחרים.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`19.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בהרשמה לאתר הנך מאשר בזאת כי הנך מעל גיל 18,היות ועל פי חוק זהו הגיל המספק כשרות משפטית ליצירת הסכם והיות והאתראינה מיועדת לגולשים מתחת לגיל 18.`}&nbsp;&nbsp;&nbsp;&nbsp;{`במידה ויתברר כי המשתמש מתחת לגיל 18 חשבונו באתר רד-בוקס פרסל יושבת.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`20.`}&nbsp;&nbsp;&nbsp;&nbsp;{`על מנת לפתוח שם משתמש ב רד-בוקס פרסל יהיה עליך להזין שם מלא ומספר טלפון אשר אליו יישלח קוד אימות ולאחר הזנת קוד האימות יוקם חשבון המשתמש.`}<br />
                                {`21.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בעצם הזנת הפרטים האמורים הנך מצהיר כי אלו הפרטים שלך וכי הנם נכונים, מדויקים ומלאים ואשר הינם בבעלותך וככל וידרש כרטיס האשראי, הנך מצהיר כי הינו כרטיס האשראי האישי שלך- כרטיס אשראי, מכל סוג שהוא, הנמצא ברשותך ובבעלותך והנושא את שמך.`}<br />
                                {`22.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הנך מצהיר ומאשר כי ידוע לך שעל מנת להשתמש באתריש צורך בשיתוף פרטי אנשי הקשר שלך עם רד-בוקס פרסל.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הינך מצהיר ומאשר כי נתונים אלה שאתה מספק ומשתף עם אחרים אין באחריות רד-בוקס פרסל לוודא תקינותם ואין באחריות רד-בוקס פרסל לקבל אישורם ו/או לוודא כי הנך רשאי לעשות בהם שימוש ולפרסם פרטיהם והאחריות על שיתופם מוטלת עליך.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`23.`}&nbsp;&nbsp;&nbsp;&nbsp;{`האתרמאפשרת שימוש בסיסי ללא תשלום.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ככל ותבחר להשתמש בשירותים הכרוכים בתשלוםותדרש להסדיר אמצעי תשלום וככל והתשלום יבוצע ללא הפרטים המלאים שלהם, רד-בוקס פרסל תהיה רשאית לבטל את המנוי.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`24.`}&nbsp;&nbsp;&nbsp;&nbsp;{`על פי חוק אינך חייב למסור את הפרטים בטופס הרישום ומסירתם תלויה ברצונך בלבד.`}&nbsp;&nbsp;&nbsp;&nbsp;{`עם זאת, אם לא מסרת את כל הפרטים המבוקשים לא תוכל להשתמש באתרו/או בחלק מהשירותים הנתמכים באתר.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`25.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ל רד-בוקס פרסל שמורה הזכות לבטל את ההתקשרות בשל הגשת פרטים כוזבים, חלקיים או לא מדוייקים וכן מכל סיבה שהיא בכל עת ומבלי שתדרש לנמק.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הגשת פרטים כוזבים ביודעין הינה עבירה פלילית עפ''י חוק.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה ו/או היוצר יהיו רשאים לנקוט בצעדים משפטיים כנגד מגישי פרטים כוזבים כולל תביעות נזיקין בגין נזקים שנגרמו ו/או יגרמו ליוצר ו/או רד-בוקס פרסל ו/או מי מטעמם עקב שיבוש הליכים באתר.`}&nbsp;&nbsp;&nbsp;&nbsp;{`מועד קבלת ההצעה שלך כפי שנרשם במחשבי רד-בוקס פרסל הוא המועד הקובע לכל דבר ורישומו במחשבי רד-בוקס פרסל ישמש ראיה מכרעת בעניין זה .`}<br />
                                {``}<br />
                                {`אבטחה ושימוש`}<br />
                                {``}<br />
                                {`26.`}&nbsp;&nbsp;&nbsp;&nbsp;{`אתר רד-בוקס פרסל מאובטחת ע"י פרוטוקול SSL והכניסה תתאפשר בהזנת קוד אימות.`}<br />
                                {`27.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יובהר, כי אף שבעלי האתר נוקטים באמצעים לאבטחת תוכן חשבון המשתמש, הם אינם יכולים להבטיח בצורה מוחלטת את אי חשיפתו כתוצאה מחדירה לא מורשית, כשל, לא תהיה כל טענה כלפי בעלי האתר בגין נזקים אשר יגרמו כתוצאה מחדירה לא מורשית לחשבון המשתמש.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`28.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בעלי האתר שומרים על זכותם להשהות חשבון משתמש ולהתנות את מתן שירותי האתרבהצגת הוכחות כי ביצירת חשבון המשתמש ובשימוש בו לא הופרו הוראות הסכם זה.`}<br />
                                {`29.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הסכמה לתקנון ולתנאי השימוש של האתרמשמעה כי למשתמש לא תהיה כל טענה ו/או דרישה ו/או תביעה נגד החברה ו/או בעלי האתר ו/או מי מטעמם עקב פריצות, חדירות, תקלות, ו/או שיבושים כלשהם באתר ו/או באתר לרבות כשל במבנה האתר.`}<br />
                                {`30.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כמו״כ לא תהיה למשתמש טענה ו/או דרישה ו/או תביעה על כל פעולות שנעשות באתר ו/או באתרו/או בזמני התגובה שלה, וזאת, הן בשל עבודות המתחייבות לצורך תחזוקת האתרו/או עדכון הנתונים המופיעים בו והן בשל ליקויים או תקלות דומות לרבות בעיות במבנה האפליקציה.`}<br />
                                {`31.`}&nbsp;&nbsp;&nbsp;&nbsp;{`על מנת להעניק לך חוויה נוחה ומשופרת ורמת אבטחה גבוהה, אנו דואגים לעדכן אפליקציה מעת לעת והנך מאשר בזאת עדכונים אלו אף אם נעשו באופן אוטומטי.`}<br />
                                {``}<br />
                                {`השירותים המופיעים באתר`}<br />
                                {``}<br />
                                {`32.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל מתמחה באספקת שירותים לוגיסטיים מחוץ לישראל ומאפשרת קבלת כתובת בניו יורק, דלוור, אמסטרדם, וורשה ואינסטנבול וניתן לשלוח מוצרים מאתרים שאינם מבצעים שילוח ישיר לישראל למחסנים הלוגיסטים במיקומים האמורים, כאשר טוטל קר לוג’יסטיקס דואגת לשילוח מכתובת זו לישראל.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`33.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יש לבצע את ההזמנה אך ורק מאתרים הממוקמים ו/או מבצעים משלוח למדינות האמורות בסעיף לעיל.`}<br />
                                {`34.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל אינה אחראית על טיבם של המוצרים הניתנים על ידי צד ג'.`}<br />
                                {`35.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בטרם רכישת מוצר מאתר צד ג׳, רד-בוקס פרסל ממליצה לבדוק כל מוצר לגופו.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`36.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יצוין כי השימוש בשירותיה של טוטל קר לוג׳יסטיקס מאפשרים הזמנה בכמות סבירה לפי הנחיות המוכסים בישראל ואין לבצע הזמנות בכמויות מסחרית או בסכומים הגבוהים מ5,000$.`}<br />
                                {`37.`}&nbsp;&nbsp;&nbsp;&nbsp;{`לצורך קבלת השירות נדרש להרשם ולקבל יוזר באתר רד-בוקס פרסל ורק לאחר מכן יוכל המשתמש לקבל כתובת למשלוח הזמנות ולבצע תשלום עבור השילוח מכתובת זו לישראל.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יובהר, כי רד-בוקס פרסל לא מספקת שירות של איסוף מוצרים.`}<br />
                                {`38.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה תוכל להתאים ולספק לך שירותים נוספים בהתאם לשיקול דעתה.`}<br />
                                {`39.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל מוכנה לספק שירותי תמיכה עבור המשתמשים אך אינה מתחייבת לכך, וע"פ שיקול דעתה הבלעדי רשאית להגביל או להפסיק את שירותי התמיכה.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`40.`}&nbsp;&nbsp;&nbsp;&nbsp;{`השילוח הינו מוגבל למוצרים המותרים בשילוח אווירי ולישראל בלבד.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בחירת הכתובת לשילוח הינה באחריות המשתמש בלבד.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`41.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה אינה ספק ו/או יבואן של המוצר הנרכש וההתקשרות בין החברה למשתמש הינם בגין שירותי השילוח בלבד.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {``}<br />
                                {`חיובים, תשלומים ומדיניות ביטול`}<br />
                                {``}<br />
                                {`42.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל מנגישה הזמנות מאתרים שאינם מבצעים שילוח לישראל, כאשר עלות השילוח מכתובת המחסן נמדדת בהתאם למשקל וגודל החבילה.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`43.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כמו-כן, ניתן לבטח את החבילה בהתאם למחירון המצוי באתר.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`44.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החיוב יבוצע בשקל ועמלות ההמרה יהיו בהתאם לשער שנקבע על ידי בנק ישראל ביום העסקים שקדם ליום ההזמנה.`}<br />
                                {`45.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה תאפשר לקבל את החבילה שהוזמנה רק לאחר תשלום עמלת השחרור מהמכס וככל וידרש.`}<br />
                                {`46.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יצוין, כי העלות הכוללת עלולה להיות גבוהה מהאמור באתר והכל בהתאם להיטלים ו/או דמי אחסון ו/או כל תשלום אחר הכפוף לשיקול רשויות המכס ו/או רשויות המס בישראל ולאחר הסדרת תשלום זה ישלח המוצר לכתובת המשתמש.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`47.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יובהר כירד-בוקס פרסל שומרת לעצמה את הזכות לשנות זאת, ובכלל זה להפסיק, להשעות ו/או לבטל, את תנאי השימוש ו/או את מדיניות הפרטיות, בכל עת, על פי שיקול דעתה הבלעדי ואף ללא סיבה וכן ללא חובת נימוק וללא חובה למסור הודעה בנדון.`}&nbsp;&nbsp;&nbsp;&nbsp;{`שינויים אלו יכולים לכלול, בין השאר, הוספת עמלות ותשלומים.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`48.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ככל ומדיניות התמחור תשתנה, תוכל רד-בוקס פרסל, ע״פ שיקול דעתה הבלעדי, לבחור שלא לגבות עמלה, או לגבות עמלה מלאה ו/או עמלה חלקית וזה ללא כל מתן הסבר ו/או פירוט.`}<br />
                                {`49.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כמו״כ ככל ותחפוץ רשאית רד-בוקס פרסל לגבות עמלה שונה מכל אחד וכן לתת תנאים שונים לכל משתמשובהסכם נפרד המהווה חלק בלתי נפרד מההסכם המצוי באתר זה.`}<br />
                                {`50.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יודגש, כי המשתמש הינו האחראי הבלעדי לדיווח ותשלומי המיסים הקשורים למוצרים שהזמין מצד ג׳ ויתכן ותוטל עמלה ו/או חיוב מס בישראל בנוסף לעלות האמורה באתר.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`51.`}&nbsp;&nbsp;&nbsp;&nbsp;{`משתמש אשר יפר את תנאי השימוש, ו/או יעשה שימוש הנוגד לחוק, רשאית רד-בוקס פרסל להסיר את פרטי ההתקשרות עמו באתר ובמידה ותבחר רשאית אף לא להחזיר את התשלומים שהתקבלו ככל ונתקבלו.`}<br />
                                {`52.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יודגש, כי רד-בוקס פרסל מסירה כל אחריות מצידה במידה ויתברר כי משתמש ניסה לבצע הונאה ו/או התנהג שלא כדין.`}<br />
                                {`53.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יצוין, כי רד-בוקס פרסל לא תישא בהוצאות הכרוכות עקב ביטול עסקה ו/או ביטול ו/או הפרה של התנאים באתר/או עקב הפרת החוק, יחויב המשתמש בכל העלויות הנ״ל.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`54.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כמו״כ, רד-בוקס פרסל רשאית לבטל עסקה ו/או ביטול מהסיבות הבאות: אירעה תקלה שגרמה לביטול העסקה ומימושה ו/או המשתמש הפר את תנאי ההסכם, ו/או משתמש המבצע פעילות בלתי חוקית ו/או במקרה של טעות בתום לב בהזנת הנתונים.`}<br />
                                {`55.`}&nbsp;&nbsp;&nbsp;&nbsp;{`במידה ומשתמש ו/או נותן שירות הפר את הכללים ו/או התחזה לגורם אחר ו/או ביצע הונאה ו/או לא סיפק נתונים מלאים, ו/או יעשה שימוש הנוגד לחוק רד-בוקס פרסל רשאית לבטל בכל עת את העסקה.`}<br />
                                {``}<br />
                                {`היעדר אחריות`}<br />
                                {``}<br />
                                {`56.`}&nbsp;&nbsp;&nbsp;&nbsp;{`השימוש באתר הינו באחריות המשתמש בלבד.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`57.`}&nbsp;&nbsp;&nbsp;&nbsp;{`המשתמש אחראי עבור הטלפון ו/או המחשב ו/או המכשיר הנייד ו/או חומרה וכל ציוד אחר שעושה בו שימוש על מנת לגלוש באתר זה.`}<br />
                                {`58.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל לא תהיה אחראית לכל אלה ולנזקים שיגרמו כתוצאה מגלישה באתר זאת, הן לציוד ו/או לחומרה ו/או לתוכנה של המשתמש.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`59.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יודגש כי רד-בוקס פרסל לא תהיה אחראית לכל נזק ממוני ו/או נזק אישי שייגרם באופן ישיר או עקיף כתוצאה משימוש באתר.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ככל ונתקבל מוצר פגום יש לפנות לצד ג׳/אתר ממנו בוצעה ההזמנה.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`60.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה לא תישא באחריות בגין זמן אספקת המוצר ו/או עיכובים ו/או בעיות התלויות בצד ג׳.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יש לפנות לצד ג׳ הספק ו/או לאתר ממנו נרכש המוצר.`}<br />
                                {`61.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה מסירה אחריות בגין הזמנת מוצרים האסורים לייבוא לישראל (כגון: כלי ירייה, אוכל טרי, צעצועים מסוכנים, טלפונים בשיטות שידור שונות, זיופים וחיקויים וכד׳) ועל המשתמש לבצע את הבדיקות לפני ההזמנה.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`62.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כמו-כן החברה לא תשא באחריות בגין חוסר תאימות של מוצרי חשמל, מידות וכד׳ ועל המשתמש חלה החובה לבצע בדיקות אלו לפני ההזמנה מצד ג׳.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`63.`}&nbsp;&nbsp;&nbsp;&nbsp;{`החברה מסירה אחריות בגין חבילות שלא נדרשו ו/או לא נתקבל תשלום בגינם וזאת תוך פרק זמן של 21 ימים.`}&nbsp;&nbsp;&nbsp;&nbsp;{`המשתמש מצהיר כי הוא מוותר על החבילה ככל שילם עבור המשלוח תוך פרק הזמן האמור לעיל.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`64.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בפעילות שלא על פי תנאי השימוש, מסכים המשתמש בזאת להגן ולפצות את רד-בוקס פרסל מפני כל תביעה ו/או דרישה ו/או הוצאות שכר טרחת עורכי דין, הקשורים לפעילותו שלא כדין.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הן פגיעה בזכות היוצרים ו/או בפטנט והן בפעילות אחרת המפרה את התנאים וזאת מבלי להגביל.`}&nbsp;&nbsp;&nbsp;&nbsp;{`בהסכמה זאת משחרר המשתמש את רד-בוקס פרסל מכל אחריות לפעילותו שלא כדין.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`65.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל אינה אחראית על פרסומות וקישורים לאתרים חיצוניים המופיעים באתר ואין לראות בהופעתם באתר כעידוד או המלצה של רד-בוקס פרסל.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`66.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יובהר, כי לרד-בוקס פרסלאין כל שליטה על אתרים אלה אי לכך מסירה רד-בוקס פרסל כל אחריות למדיניות והתנהגות אתרים אלה.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כניסה לאתרים אלה הינם באחריות מלאה של המשתמש.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {``}<br />
                                {`זכות יוצרים קנין וסימני מסחר`}<br />
                                {``}<br />
                                {`67.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ביצוע אריזה מחדש של החבילות במחסן המקור יבוצע בכל מקרה של צורך באיתור מסמכי ייצוא (חשבונית ספק), בדיקה של המכס וצמצום של נפחים מיתרים הנובעים מאריזה לא תואמת.`}<br />
                                {`68.`}&nbsp;&nbsp;&nbsp;&nbsp;{`סימני המסחר, יצירה וכל אלמנט מאתר ״רד-בוקס פרסל״ הינם בבעלות רד-בוקס פרסלוכל שימוש בהם או בצורתם מהוות הפרה של זכויות היוצרים השמורות ל רד-בוקס פרסל.`}<br />
                                {`69.`}&nbsp;&nbsp;&nbsp;&nbsp;{`סעיף זה יישאר בתוקף גם אם תנאים אילו יאבדו מתוקפם.`}<br />
                                {`70.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל מאשרת שימוש באתרלמטרות האמורות בהסכם זה ואוסרת כל שימוש אחר לרבות שימוש בנתונים הטכניים וקוד האפליקציה.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {``}<br />
                                {`הסכמה לקבלת דיוור ע״י רד-בוקס פרסל ו/ או מי מטעמה`}<br />
                                {``}<br />
                                {`71.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל ו/או מי מטעמה רשאית לעדכן את הגולשים בפעילות המתבצעת באתרובהצעות העולות מעת לעת.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`72.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הנך מסכים בזאת לקבלת הודעות דואר אלקטרוני לכתובת אותה מסרת ברישומך לאתר רד-בוקס פרסל, ככל ותרצה לבטל הסכמה זו תוכל לעשות זאת באמצעות קישור להסרה שיצורף להודעת הדואר האלקטרוני.`}<br />
                                {`73.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הרישום לאתר מהווה הסכמה לאישור קבלת עדכונים ("הודעות פוש") מ רד-בוקס פרסל אשר עשויים להיחשב כדברי פרסומת, כקבוע בחוק סעיף 30א לחוק התקשורת (בזק ושידורים) התשס"ח 2008.`}&nbsp;&nbsp;&nbsp;&nbsp;{`באפשרותך להפסיק קבלת עדכונים וניוזלטרים כאמור בכל עת באמצעות הגדרות הטלפון ו/או הסרת האתר,יובהר שנקיטה בפעולות אלו יגרמו להפסקת קבלת ההודעות דרך האתרעצמה אך לא עבור הודעות דואר אלקטרוני שלשם הפסקתם יש לנקוט כאמור לעיל לגבי הודעות דוא"ל.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`74.`}&nbsp;&nbsp;&nbsp;&nbsp;{`יובהר, כי משתמש שיבחר לבטל הודעות ועידכוני פוש מצהיר כי הוא מודע לכך שלא יקבל הודעות ועדכונים אודות עדכון הסכם זה, וכן לא יקבל הודעות ועדכונים בנושאים אחרים ועשוי שלא לקבל עדכונים כלל כולל עדכוני נתונים של אנשי קשר והודעות אחרות שהינם חלק אינטגרלי משירותי האתרוהינו אחראי להתעדכן וככל ולא עשה זאת הינו מוותר על כל טענה בגין כך.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`75.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל שומרת לעצמה את הזכות לשלב הודעות פרסומת באתר בכל זמן, ברישום לאתר הנך מאשר זאת.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ככל ותרצה להסיר את הודעות הפרסומת תוכל להסיר את האתרממכשירך.`}<br />
                                {``}<br />
                                {`שינוי תנאי ההסכם וביטולו`}<br />
                                {``}<br />
                                {`76.`}&nbsp;&nbsp;&nbsp;&nbsp;{`ככל ותחפוץ רד-בוקס פרסל רשאית בכל עת לשנות את התנאים ו/או להחליט כי היא מסירה את האתר,או משנה את מהותה וזאת ללא כל סיבה או מחויבות למסור הודעה על כך למשתמש.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`77.`}&nbsp;&nbsp;&nbsp;&nbsp;{`שהות או הימנעות מפעולה על ידי רד-בוקס פרסל לא תחשב לוויתור זכויותיה על פי הסכם זה ו/או על פי כל דין.`}&nbsp;&nbsp;&nbsp;&nbsp;{`כל מקרה לגופו ואין להשליך ממקרה אחד למשנהו.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל רשאית לממש את זכויותיה בכל עת.`}<br />
                                {``}<br />
                                {`סמכות שיפוט `}<br />
                                {``}<br />
                                {`78.`}&nbsp;&nbsp;&nbsp;&nbsp;{`הדין החל עליך כמשתמש ו/או כמבצע פעולות באתר,הוא הדין הישראלי בלבד.`}<br />
                                {`79.`}&nbsp;&nbsp;&nbsp;&nbsp;{`סמכות השיפוט הייחודית נתונה לבתי המשפט המוסמכים בעיר תל-אביב, במדינת ישראל ולא תהיה תחולה לכללי ברירת הדין הבינלאומי הקבועים בהם.`}&nbsp;&nbsp;&nbsp;&nbsp;{``}<br />
                                {`80.`}&nbsp;&nbsp;&nbsp;&nbsp;{`מבלי לגרוע מכל האמור לעיל, המשתמש מסכים כי תקופת ההתיישנות לגבי כל דרישה ו/או תביעה נגד רד-בוקס פרסל ו/או מי משלוחיה, תוגבל לתקופה של 4 חודשים והצדדים יראו זאת כהסכם לגבי לתקופת ההתיישנות כמשתמע בחוק (חוק ההתיישנות, תשי״ח- 1958).`}<br />
                                {``}<br />
                                {`יצירת קשר `}<br />
                                {``}<br />
                                {`81.`}&nbsp;&nbsp;&nbsp;&nbsp;{`אנו עושים כל מאמץ כדי ליצור חוויית גלישה ושירות נעימים ויעילים באתר רד-בוקס פרסל, במקרה של אי שביעות רצון ו/או להצעת ייעול ו/או לכל שאלה, ניתן לפנות אל שירות הלקוחות שלנו בכתב באמצעות "צור קשר" באתר.`}<br />
                                {`82.`}&nbsp;&nbsp;&nbsp;&nbsp;{`רד-בוקס פרסל וצוות הניהול של האתר מאחלים לך גלישה מהנה ובהצלחה.`}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>*/}


                <Box component={'section'} className="faq-section bottom-section" sx={{ padding: { sm: '100px 0 0', xs: '0'} }}>
                    <Box className="container">
                        <Grid container className='dir-rtl box-wrapper'>
                            <Grid item sm={9} className='darkblue-rounded-box shadow_box'>
                                <Typography>לא מצאתם את מה שחיפשתם? נציגנו ישמחו לעזור לכם!</Typography>
                                <ActionButton textLabel={`הצג עוד`} className='underline-btn' onClick={() => {
                                    navigate("/contactUs")
                                }} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default FAQS;