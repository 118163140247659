/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, InputLabel, Select, MenuItem, Button, Table, TableBody, TableContainer, Paper, TablePagination, Grid, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './RedboxPricing.scss';
import { TextField, SelectField } from '../../style-guide';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getShipmentCost, listWarehouse, getExchangeRate } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, settingsRedbox, chWtMultiplier } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import account_banner from '../../images/homepage/account_banner.png';
import flag_tr_small from '../../images/homepage/flag_tr_small.png';
import down_arrow from '../../images/pricing_page/down_arrow.png';
import flag_po_small from '../../images/homepage/flag_po_small.png';
import flag_eu_small from '../../images/homepage/flag_eu_small.png';
import flag_us_small from '../../images/homepage/flag_us_small.png';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import smartphone from '../../images/pricing_page/smartphone.png';
import idea from '../../images/pricing_page/idea.png';
import laptop1 from '../../images/pricing_page/laptop1.png';
import lotion from '../../images/pricing_page/lotion.png';
import mannequin from '../../images/pricing_page/mannequin.png';
import amazonLogo from '../../images/pricing_page/amazon-logo.png';
import Trousers from '../../images/pricing_page/main/trousers.png';
import Shirt from '../../images/pricing_page/main/shirt.png';
import Shoe from '../../images/pricing_page/main/shoe.png';
import RainBoots from '../../images/pricing_page/main/rain-boots.png';
import Headphone from '../../images/pricing_page/main/headphone.png';
import Laptop from '../../images/pricing_page/major/laptop.png';
import Watch from '../../images/pricing_page/main/watch.png';
import Coat from '../../images/pricing_page/main/coat.png';
import Tablet from '../../images/pricing_page/main/tablet.png';
import Smartphone from '../../images/pricing_page/major/smartphone.png';
import Sunglasses from '../../images/pricing_page/main/sunglasses.png';
import Camera from '../../images/pricing_page/major/camera.png';
import Armchair from '../../images/pricing_page/major/armchair.png';
import Car from '../../images/pricing_page/major/car.png';
import motorcycle from '../../images/pricing_page/major/motorcycle.png';
import food from '../../images/pricing_page/major/food.png';
import Hammer from '../../images/pricing_page/major/hammer.png';
import Idea from '../../images/pricing_page/major/idea.png';
import Lotion from '../../images/pricing_page/major/lotion.png';
import Mannequin from '../../images/pricing_page/major/mannequin.png';
import RemoteControl from '../../images/pricing_page/major/remote-control.png';
import ShoppingBag from '../../images/pricing_page/major/shopping-bag.png';
import Toaster from '../../images/pricing_page/major/toaster.png';
import Trumpet from '../../images/pricing_page/major/trumpet.png';
import TV from '../../images/pricing_page/major/tv.png';
import Volleyball from '../../images/pricing_page/major/volleyball.png';
import $ from 'jquery';
import ActionButton from '../Common/ActionButton';
import MetaTags from 'react-meta-tags';

const data = {
    pricing_currency: "",
    category: "",
    price1: "",
    subCategory: "",
    pLength: "",
    pWidth: "",
    totalInvoice: 0,
    weight: 0,
    transportVal: 0,
    cif: 0,
    customsCharge: 0,
    insurence: 0,
    purchaseTax: 0,
    isInsurence: true,
    homeDelivary: true,
}
export const RedboxPricing = (props) => {
    const { setActiveScreenInreg, setIsRegister, calculateValues } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);

    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [value, setValue] = useState(data);

    const [numOfItems, setNumOfItems] = new useState([{ id: 1, name: "0" }, { id: 2, name: "1" }, { id: 3, name: "2" }, { id: 4, name: "3" }, { id: 5, name: "4" }, { id: 6, name: "5" }, { id: 7, name: "6" }, { id: 8, name: "7" }, { id: 9, name: "8" }, { id: 10, name: "9" }
        , { id: 11, name: "10" }, { id: 12, name: "11" }, { id: 13, name: "12" }, { id: 14, name: "13" }, { id: 15, name: "14" }, { id: 16, name: "15" }, { id: 17, name: "16" }, { id: 18, name: "17" }, { id: 19, name: "18" }, { id: 20, name: "19" }, { id: 21, name: "20" }
    ]);
    const [currList, setCurrList] = new useState([{ id: 1, name: "$ דולר" }, { id: 2, name: "€ אירו" }]);
    const [selectedNumOfItem, setSelectedNumOfItem] = new useState(1);
    const [selectedCur, setSelectedCur] = new useState(1);
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isLb, setIsLb] = new useState(false);
    const [isIN, setIsIN] = new useState(false);
    const [categoryName, setCategoryName] = new useState(null);
    const [isEnable, setEnabled] = new useState(true);
    const [exchangeRate, setExchangeRate] = new useState({});
    const [exchangeRateAmt, setExchangeRateAmt] = new useState(0);
    const [wtAndVals, setWtAndVals] = new useState([]);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const [selectedBasePriceList, setselectedBasePriceList] = new useState([]);
    const [selectedWh, setSelectedWh] = new useState(null);
    const [calcPrice, setcalcPrice] = new useState(null);

    const [selectedBasePrice, setSelectedBasePrice] = new useState(null);
    const [wareHouses, setWareHouses] = new useState([]);
    const [weightAndVal, setWeightAndVal] = new useState([]);
    const [discount, setDiscount] = new useState("");
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const goTo = (e) => {
    }
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (isExpanded) => {
        if (expanded === panel) {
            setExpanded(false);
        }
        else {
            setExpanded(isExpanded ? panel : false);
        }

    };
    const handleWarHWts = (w, len) => {
        var tV = w.params?.wtNval;
        var t = [];
        for (var i = 0; i < len; i++) {
            if (tV[i][0] && tV[i][1]) {
                t.push({
                    wt: Number(getFloatValue(tV[i][0])),
                    val: Number(getFloatValue(tV[i][1])),
                })
            }
        }
        // setWtAndVals(t)
        return t;
    }
    const getFlagByW = (w) => {
        switch (w) {
            case 'מערב אירופה':
                return flag_eu_small
            case 'ארה”ב':
                return flag_us_small
            case 'פולין':
                return flag_po_small
            case 'טורקיה':
                return flag_tr_small
        }
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }

    const [shipmentCost1, setShipmentCost1] = useState({ id: null });
    useEffect(() => {

        getShipmentCost(function (resp) {
            if (resp?.data)
                setShipmentCost1(resp?.data)
        });

        listWarehouse(function (data) {
            var ws = JSON.parse(JSON.stringify(data.data))
            var t = [];
            ws.map((w, index) => {
                if (w.params?.pricing && w.params?.active && data.data[index].params?.wtNval) {
                    w.wtNval = handleWarHWts(data.data[index], data.data[index].params?.wtNval.length);
                    t.push(w);
                }
                if (w.name === "מערב אירופה") {
                    if (!selectedWh?.id)
                        showPricingBlockA(w, true)
                }
            })
            setWareHouses(t);

        });
        getExchangeRate(function (res) {
            setExchangeRate(res);
            var e = 1.03;
            if (res.rates) {
                e = res?.rates?.USD;
            }
            setExchangeRateAmt(e);
        });
        /*var t = [];
        for (var i = 0; i < 7; i++) {
            t.push(weightAndVal[i])
        }*/

    }, [userInfo, categoryName]);


    const getBasePriceByName = function (product) {
        for (var i = 1; i < w?.params?.basePriceList?.length; i++) {

        }
    }
    const setBaseProducts = function (w, category) {
        var tBase = JSON.parse(JSON.stringify(w))
        var tbList = [];
        for (var i = 1; i < tBase.params?.basePriceList?.length; i++) {
            var tbBaseObj = {
            }
            var bAdd = false;
            for (var j = 0; j < tBase.params?.basePriceList[0].length; j++) {
                if (tBase.params?.basePriceList[i].length > 1) {
                    bAdd = true;
                    tbBaseObj[tBase.params?.basePriceList[0][j]?.trim()] = tBase.params?.basePriceList[i][j]
                }
            }
            tbBaseObj['numOfItems'] = numOfItems;
            tbBaseObj['selected'] = numOfItems[0];
            /* tBase.params?.basePriceList[i].push(numOfItems)
             tBase.params?.basePriceList[i].push(numOfItems[0])*/
            //if (bAdd && category.name == tbBaseObj.category) {
            tbList.push(tbBaseObj);
            //}
        }
        //setselectedBasePriceList(tBase.params?.basePriceList)
        //  setselectedCateGory(categories[0].id)
        setselectedBasePriceList(tbList)
    }
    const showPricingBlockA = function (w, bScroll) {

        if (w) {
            setSelectedWh(w)
            setDiscount(w.params?.discount ? w.params?.discount : "");
            var t = []
            var t2 = []
            setWeightAndVal(w.wtNval);
            w.wtNval.map((wv, index) => {
                /*if (index < 7) {
                    t.push(wv)
                }*/
                t.push(wv)
            })
            setWtAndVals(t)
            var tBase = JSON.parse(JSON.stringify(w))
            var tbList = [];
            var firstCat = "";
            var categoriesTemp = [];
            for (var i = 1; i < tBase.params?.basePriceList?.length; i++) {
                var tbBaseObj = {
                }
                var bAdd = false;
                for (var j = 0; j < tBase.params?.basePriceList[0].length; j++) {
                    if (tBase.params?.basePriceList[i].length > 1) {
                        bAdd = true;
                        tbBaseObj[tBase.params?.basePriceList[0][j]?.trim()] = tBase.params?.basePriceList[i][j]
                    }
                }
                tbBaseObj['numOfItems'] = numOfItems;
                tbBaseObj['selected'] = numOfItems[0];
                /* tBase.params?.basePriceList[i].push(numOfItems)
                 tBase.params?.basePriceList[i].push(numOfItems[0])*/
                var bExist = false;
                for (var k = 0; k < categoriesTemp.length; k++) {
                    if (categoriesTemp[k].name == tbBaseObj.category) {
                        bExist = true;
                        break;
                    }
                }
                if (!bExist && tbBaseObj.category) {
                    categoriesTemp.push({
                        id: i,
                        name: tbBaseObj.category
                    })
                }

                //if (bAdd && (firstCat == '' || firstCat == tbBaseObj.category)) {
                firstCat = tbBaseObj.category;
                if (tbBaseObj.category) {
                    tbList.push(tbBaseObj);
                }

                //}
            }
            //setselectedBasePriceList(tBase.params?.basePriceList)
            setcategoriesNew(categoriesTemp)
            setselectedCateGory(categoriesTemp[0]?.id)

            setselectedBasePriceList(tbList)
            // setSelectedWh(tBase)
        }

        $('#pricing_block_B').addClass('selected_show');
        $('#pricing_block_C').addClass('selected_show');
        $('#pricing_block_D').addClass('selected_show');
        $('#pricing_block_D1').addClass('selected_show');
        $('#pricing_block_E').addClass('selected_show');
        $('#pricing_block_F').addClass('selected_show');
        $('#pricing_block_A').removeClass('selected_show');
        if (!bScroll) {
            //window.setTimeout(function () { document.getElementById('pricing_block_A').scrollIntoView(); }, 500);
        }

    }
    var getFloatValue = function (str) {
        var regex = /[+-]?\d+(\.\d+)?/g;

        var floats = str?.toString().match(regex)?.map(function (v) { return parseFloat(v); });
        console.log(floats)
        return floats;
    }
    const showPricingBlockB = function () {
        $('#pricing_block_A').addClass('selected_show');
        $('#pricing_block_C').addClass('selected_show');
        $('#pricing_block_D').addClass('selected_show');
        $('#pricing_block_D1').addClass('selected_show');
        $('#pricing_block_E').addClass('selected_show');
        $('#pricing_block_F').addClass('selected_show');
        $('#pricing_block_B').removeClass('selected_show');
    }


    const priorShowPricingBlockC = function (value1) {


        setCategoryName(value1)

        var t = settingsRedbox[value1.id - 1].subCategory.category1
        var t2 = t.map((setting, index) => ({
            id: index + 1,
            name: setting
        }));
        setSubcategories(t2);
        setSubcategories3(settingsRedbox[value1.id - 1].subCategory.category3);
        setSubcategories4(settingsRedbox[value1.id - 1].subCategory.category4);
        if (settingsRedbox[value1.id - 1].subCategory?.category5)
            setSubcategories5(settingsRedbox[value1.id - 1].subCategory.category5);
        else
            setSubcategories5([]);
        // }
    }
    const showPricingBlockC = function (id) {

        $('#pricing_block_A').addClass('selected_show');
        $('#pricing_block_B').addClass('selected_show');
        $('#pricing_block_D').addClass('selected_show');
        $('#pricing_block_D1').addClass('selected_show');
        $('#pricing_block_E').addClass('selected_show');
        $('#pricing_block_F').addClass('selected_show');
        $('#pricing_block_C').removeClass('selected_show');


    }

    const showPricingBlockD = function () {
        //

        $('#pricing_block_A').addClass('selected_show');
        $('#pricing_block_B').addClass('selected_show');
        $('#pricing_block_C').addClass('selected_show');
        $('#pricing_block_E').addClass('selected_show');
        $('#pricing_block_F').addClass('selected_show');
        $('#pricing_block_D').removeClass('selected_show');
        $('#pricing_block_D1').removeClass('selected_show');
        window.setTimeout(function () {
            document.getElementById('productDetails').scrollIntoView();

        }, 300);
    }

    const checkWeight = function (h, l, w, wt) {
        if (isLb) {
            wt = wt / 2.2
        }
        var h = Number(value.pHeight)
        var l = Number(value.pLength)
        var w = Number(value.pWidth)
        if (isIN) {
            h = h * 2.54;
            l = l * 2.54;
            w = w * 2.54;

        }
        return wt > 10 || (w * h * l / 1000000) > 0.02
    }

    const getChargiableWeight = function (h, l, w, wt) {
        if (isLb) {
            wt = wt / 2.2
        }
        var h = Number(value.pHeight || 0)
        var l = Number(value.pLength || 0)
        var w = Number(value.pWidth || 0)
        if (isIN) {
            h = h * 2.54;
            l = l * 2.54;
            w = w * 2.54;

        }
        var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;

        var twt = chargWt > wt ? chargWt : wt;
        if (!twt) {
            twt = 0;
        }
        return Number(twt)?.toFixed(2);
    }
    const showPricingBlockE = function () {
        $('#pricing_block_A').addClass('selected_show');
        $('#pricing_block_B').addClass('selected_show');
        $('#pricing_block_C').addClass('selected_show');
        $('#pricing_block_D1').addClass('selected_show');
        $('#pricing_block_F').addClass('selected_show');
        $('#pricing_block_D').removeClass('selected_show');
        $('#pricing_block_E').removeClass('selected_show');
    }
    const [isTogglePrice, setIsTogglePrice] = new useState(true);
    const togglePricingDetails = function () {
        $('#pricing_details_div').toggleClass('selected_show');
        if (isTogglePrice) {
            setIsTogglePrice(true);
        } else {
            setIsTogglePrice(true);
        }
    }

    const categories = settingsRedbox.map((setting, index) => ({
        id: index + 1,
        name: setting.name
    }));/**/


    const [categoriesNew, setcategoriesNew] = new useState([]);
    const [selectedCateGory, setselectedCateGory] = new useState({});
    const getCategoryName = function (id) {
        for (var i = 0; i < categories.length; i++) {
            if (id == categories[i].id) {
                return categories[i].name;
            }
        }
        return ""
    }
    const [totalBaseCost, setTotalBaseCost] = useState(0);
    const [totalBaseWeight, setTotalBaseWeight] = useState(0);
    const [totalShippingCost, setTotalShippingCost] = useState(0);
    const [globalBase, setGlobalBase] = useState(0);
    const [subcategories, setSubcategories] = useState([]);
    const [subcategories3, setSubcategories3] = useState([]);
    const [subcategories4, setSubcategories4] = useState([]);
    const [subcategories5, setSubcategories5] = useState([]);
    var getValue = function (w, kg) {
        var v = 12
        w.wtNval.map((wv) => {

            if (wv.wt == kg) {
                v = wv.val
            }
        })
        return v
    }
    var getImageByName = function (name) {
        var img = ""
        categories.map((c, index) => {
            if (c.name == name) {
                switch (index) {
                    case 4:
                        img = Smartphone
                        break;
                    case 3:
                        img = Idea
                        break;
                    case 2:
                        img = Laptop
                        break;
                    case 1:
                        img = Lotion
                        break;
                    case 0:
                        img = Mannequin
                        break;
                    case 9:
                        img = Toaster
                        break;
                    case 8:
                        img = Volleyball
                        break;
                    case 7:
                        img = Armchair
                        break;
                    case 6:
                        img = RemoteControl
                        break;
                    case 5:
                        img = Hammer
                        break;
                    case 13:
                        img = Car
                        break;
                    case 14:
                        img = food;
                        break;
                    case 15:
                        img = motorcycle;
                        break;
                    case 16:
                        img = food;
                        break;
                    case 18:
                        img = food;
                        break;
                    case 13:
                        img = Car
                        break;
                    case 12:
                        img = Trumpet
                        break;
                    case 11:
                        img = Camera
                        break;
                    case 10:
                        img = TV
                        break;

                }
            }
        })

        return img;
    }
    var getTotalHousedeliveryStatus = function () {
        var wt = Number(value.weight)
        if (isLb) {
            wt = wt / 2.2
        }
        var h = Number(value.pHeight)
        var l = Number(value.pLength)
        var w = Number(value.pWidth)
        if (isIN) {
            h = h * 2.54;
            l = l * 2.54;
            w = w * 2.54;

        }

        var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;

        var twt = Number(chargWt > wt ? chargWt : wt);
        if (twt > 4) return false;
        return true;
    }
    var getTotal = function () {
        var wt = Number(value.weight)
        if (isLb) {
            wt = wt / 2.2
        }
        var h = Number(value.pHeight)
        var l = Number(value.pLength)
        var w = Number(value.pWidth)
        if (isIN) {
            h = h * 2.54;
            l = l * 2.54;
            w = w * 2.54;

        }

        var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;

        var twt = Number(chargWt > wt ? chargWt : wt);
        var ins = value.isInsurence ? Number(value.insurence) : 0;
        var hmD = value.homeDelivary ? 4 : 0;
        if (twt > 4) hmD = 0;
        return Number(Number(Number(value.totalInvoice).toFixed(1)) + ins + hmD).toFixed(1);
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <MetaTags id="metaCalculation">
                <title>Redbox Calculation </title>
                <meta id="meta-description" name="Calculation" content="מחשבון שילוח" />
                <meta id="meta-description-title" property="og:title" content="Redbox parcel" />
            </MetaTags>
            <Box component={'section'} className="page-title dark_image_filter">
                <Box className="title-section">
                    <Box className="container" style={{ direction: "rtl" }}>
                        <Typography component={'h1'} className="page-main-title center_text rtl_text bold_text">{`מחשבון שילוח`}</Typography>
                        <Typography className='page_sub_title' > {`רוצים להזמין מוצר מחו"ל ולא יודעים כמה יעלה לכם המשלוח?`}</Typography>
                        <Typography className='page_sub_title'> {`מהיום לא צריך לנחש, בוחרים מאיפה להזמין, מזינים את פרטי המשלוח ומקבלים את עלות המשלוח`}</Typography>
                    </Box>
                </Box>
            </Box>
            <main id="main-content">

                {/*<p className="right_text rtl_text margin_right_833">דף הבית   טיפים ומידע שימושי
                    <span className="bold_text rtl_text">מחירון</span>
                </p>*/}
                <h1 className="rtl_text full_width center_text bold_text darkgrey_text top_space_40">לחצו על הדגל מהאזור בו תרצו לקנות</h1>

                <p className="rtl_text full_width center_text bold_text darkgrey_text" style={{ fontSize: "18px" }}>בחרו מדינה</p>

                <Grid container className="container" style={{ justifyContent: 'space-evenly', flexDirection: "row-reverse" }}>
                    {wareHouses.map((w, i) => (<Grid item md={2.8} sm={5.8} xs={12} key={i} onClick={function () {
                        showPricingBlockA(w)
                    }} className="relative_container cursor_pointer flag_box" style={{ border: selectedWh.id == w.id ? '2px solid #c30707' : '', boxShadow: selectedWh.id == w.id ? '2px 2px 4px 0px #ff0000' : '', }}>
                        <div className="row_flex full_width_container pad-10 flag_box_inner" style={{ justifyContent: "center" }}>
                            {/*<div className="row_flex row_space_evenly full_width_container">
                                <div className="col_flex">
                                    <p className="rtl_text center_text">{w.wtNval[2]?.wt} ק"ג</p>
                                    <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-{w.wtNval[2]?.val} $</p>
                                </div>
                                <div className="col_flex">
                                    <p className="rtl_text center_text">{w.wtNval[1]?.wt} ק"ג</p>
                                    <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-{w.wtNval[1]?.val} $</p>
                                </div>
                                <div className="col_flex">
                                    <p className=" rtl_text center_text">{w.wtNval[0]?.wt} ק"ג</p>
                                    <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-{w.wtNval[0]?.val} $</p>
                                </div>
                            </div>*/}
                            <div className='col_flex flag_img_box'>
                                <span style={{ marginTop: '10px', position: 'relative' }}>
                                    <img src={getFlagByW(w.name)} className="flag_img" />
                                    {/*{getFlagByW(w.name) === flag_us_small && <label className='flag_label'>{`בקרוב`}</label>}
                                    {getFlagByW(w.name) === flag_po_small && <label className='flag_label'>{`בקרוב`}</label>}*/}
                                </span>

                                {w.name == 'פולין' && <p className="bold_text darkgrey_text rtl_text center_text">{'אנגליה'}</p>}
                                {w.name != 'פולין' && <p className="bold_text darkgrey_text rtl_text center_text">{w.name}</p>}

                            </div>
                        </div>
                        <div className="bottom_red_container_for_pricing cursor_pointer" >
                            <img alt="" src={down_arrow} className="down_arrow_sign" /> <span>למחירון המלא</span>
                        </div>
                    </Grid>
                    ))}
                    {/*<div className="col-md-3 small_container relative_container">
                        <img alt="" src={flag_po_small} className="flag_img top_space_20 bot_space_20" />
                        <p className="bold_text darkgrey_text rtl_text center_text">פולין</p>
                        <div className="row_flex row_space_evenly full_width_container bot_space_20">
                            <div className="col_flex">
                                <p className=" rtl_text center_text">7 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-86 $</p>
                            </div>
                            <div className="col_flex">
                                <p className=" rtl_text center_text">3 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-42 $</p>
                            </div>
                            <div className="col_flex">
                                <p className=" rtl_text center_text">1 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-25 $</p>
                            </div>
                        </div>
                        <div className="bottom_red_container_for_pricing cursor_pointer" onClick={function () {
                            showPricingBlockA()
                        }}>
                            <img alt="" src={down_arrow} className="down_arrow_sign" /> <span>למחירון המלא</span>
                        </div>
                    </div>
                    <div className="col-md-3 small_container relative_container">
                        <img alt="" src={flag_eu_small} className="flag_img top_space_20 bot_space_20" />
                        <p className="bold_text darkgrey_text rtl_text center_text">מערב אירופה</p>
                        <div className="row_flex row_space_evenly full_width_container bot_space_20">
                            <div className="col_flex">
                                <p className=" rtl_text center_text">7 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-36 $</p>
                            </div>
                            <div className="col_flex">
                                <p className="rtl_text center_text">3 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-20 $</p>
                            </div>
                            <div className="col_flex">
                                <p className="rtl_text center_text">1 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-12 $</p>
                            </div>
                        </div>
                        <div className="bottom_red_container_for_pricing cursor_pointer" onClick={function () {
                            showPricingBlockA()
                        }}>
                            <img alt="" src={down_arrow} className="down_arrow_sign" /> <span>למחירון המלא</span>
                        </div>
                    </div>
                    <div className="col-md-3 small_container relative_container">
                        <img alt="" src={flag_us_small} className="flag_img top_space_20 bot_space_20" />
                        <p className="bold_text darkgrey_text rtl_text center_text">ארה"ב</p>
                        <div className="row_flex row_space_evenly full_width_container bot_space_20">
                            <div className="col_flex">
                                <p className="rtl_text center_text">7 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-86 $</p>
                            </div>
                            <div className="col_flex">
                                <p className="rtl_text center_text">3 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-42 $</p>
                            </div>
                            <div className="col_flex">
                                <p className="rtl_text center_text">1 ק"ג</p>
                                <p className="small_text darkgrey_text bold_text rtl_text center_text">ב-25 $</p>
                            </div>
                        </div>
                        <div className="bottom_red_container_for_pricing cursor_pointer" onClick={function () {
                            showPricingBlockA()
                        }}>
                            <img alt="" src={down_arrow} className="down_arrow_sign" /> <span>למחירון המלא</span>
                        </div>
                    </div>*/}
                </Grid>
                <div className="container">

                    <div className="pricing_block full_width_container selected_show" id="pricing_block_A">
                        {/*<div className="full_width_container center_text rtl_text">
                            <div>
                                <span className="underscored_text darkgrey_text bold_text mid_text">חישוב מהיר</span> &nbsp;&nbsp;&nbsp;
                                <span className="underscored_text mid_text cursor_pointer" onClick={function () {
                                    showPricingBlockB();

                                }} >
                                    חישוב מפורט
                                </span>
                            </div>
                            <div className="pricing_block_A_tid_bit" >
                                <div onClick={function () {
                                    showPricingBlockB()
                                }} className="cursor_pointer pricing_block_A_tid_bit_left rtl_text white_text small_text col_flex center_text">חשבו לי</div>
                                <div className="pricing_block_A_tid_bit_right rtl_text small_text col_flex center_text">משקל המוצר</div>
                            </div>
                        </div>*/}
                        <Grid container className='pricing_block_A_group' alignItems="flex-start">
                            {isMobile() && <div className="pricing_block_A_3">
                                <Grid container style={{ height: '100%', padding: '24px 0' }} alignItems="flex-start">
                                    <Grid container className="pricing_block_A_3_container" alignItems="flex-start">
                                        {(discount && discount!="" && Number(discount)!=0) ?
                                            < p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">{ `מחיר מבצע`}</p> :
                                            < p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">משלוח עד הבית</p>
                                            
                                        }
                                        <p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">משלוח לנקודת איסוף</p>
                                        <p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">משקל </p>
                                    </Grid>
                                    <Grid container className="pricing_block_A_3_container" alignItems="center">
                                        <p className="rtl_text bold_text center_text small_text zero_margin">21-28 יום (זמני)</p>
                                        <p className="rtl_text bold_text center_text small_text zero_margin">21-28 יום (זמני)</p>
                                        <p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">{`משקל פיזי/נפחי`} <br /> {`הגבוה מבין השניים`} </p>
                                    </Grid>
                                    <Grid container style={{ maxHeight: "50vh", overflowY: "auto" }} alignItems="flex-start">
                                        {wtAndVals.map((wV, index) => <Grid container className="pricing_block_A_3_container">
                                            {(wV.wt != 0.3 && (Number(wV.wt) ? wV.wt : getFloatValue(wV)) != 0.4) && <>
                                                {(discount && discount != "" && Number(discount) != 0) ?
                                                    <p> {Number((wV.val * (100 - Number(discount)) / 100)).toFixed(2) + ' $ '} </p> :
                                                    <p> {Number(wV.wt) <= 4 ? (wV.val + 4) + ' $ ' : (wV.val) + ' $ '} </p>
                                                }
                                                <p> {Number(wV.wt) <= 4 ? (wV.val + '$') : "-"} </p>
                                                <p>עד {Number(wV.wt) ? wV.wt : getFloatValue(wV)} ק"ג</p></>}
                                        </Grid>)}
                                    </Grid>

                                    <Grid container  style={{ alignItems: 'flex-end' }}>
                                        <Grid items container lg={4.5} justifyContent="center">
                                            <div className="red_button white_text top_space_20"  onClick={function () {
                                                //showPricingBlockB()
                                                if (userInfo.session) {
                                                    setActiveScreenInreg('PersonalData');
                                                } else {
                                                    setActiveScreenInreg('Registeration');
                                                }
                                                setIsRegister(true);
                                            }}><p>לקבלת כתובת בחינם</p></div>
                                        </Grid>
                                        <Grid items container lg={3.5} justifyContent="center">
                                            <div className="whiteoutline_button top_space_20" style={{ textAlign: "center" }} onClick={() => {
                                                showPricingBlockB()
                                            }}><p>חישוב מפורט</p></div>
                                        </Grid>
                                        {wtAndVals.length <= 7 ?
                                            <Grid items container lg={4} justifyContent="center">
                                                <div className="whiteoutline_button top_space_20" onClick={() => {
                                                    var t = []
                                                    selectedWh?.wtNval.map((wv, index) => {
                                                        t.push(wv)
                                                    })
                                                    setWtAndVals(t)
                                                }}><p>לכל המשקלים</p></div>
                                            </Grid> :
                                            <Grid items container lg={4} justifyContent="center">
                                                <div style={{ textAlign: "center" }} className="whiteoutline_button top_space_20" onClick={() => {
                                                    var t = []
                                                    selectedWh?.wtNval.map((wv, index) => {
                                                        if (index < 7) {
                                                            t.push(wv)
                                                        }
                                                    })
                                                    setWtAndVals(t)
                                                }}><p>משקלים סטנדרטים</p></div>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </div>}
                            <div className="pricing_block_A_2 flex-box">
                                <Grid container >

                                    {/*<Grid container item lg={6}>

                                        {categoriesNew.length > 0 && < SelectField
                                            className='text-field33'
                                            required
                                            label=""
                                            value={selectedCateGory}
                                            onChange={event => {
                                                setselectedCateGory(event.target.value)
                                                var cat = categoriesNew.find(u => u.id === event.target.value)
                                                setBaseProducts(selectedWh, cat)

                                            }}
                                            dir="rtl"
                                            options={categoriesNew}
                                            islabelAnimate={false}
                                            isNumeric={true}
                                            parentClassName={'select-field32 width-100 mar_10'}
                                            sx={{ mr: "0" }}
                                        />}
                                    </Grid>*/}
                                    <Grid container item lg={12}>
                                        <p className="full_width_container center_text bold_text small_text rtl_text darkgrey_text top_space_20">מחשבון מפורט ושירותים מיוחדים</p>
                                        {/*{JSON.stringify(selectedBasePriceList)}*/}
                                    </Grid>
                                </Grid>

                                <Grid container flexDirection="row-reverse" justifyContent="center" >
                                    <div className="red_button white_text bold_text top_space_20" onClick={() => {
                                        showPricingBlockB()
                                    }} ><p style={{ margin: "5px 40px" }}>מחשבון מיסים מפורט</p></div>
                                </Grid>
                                <Grid container flexDirection="row-reverse" style={{ borderBottom: "1px solid #f0f0f2" }}>
                                    <Grid container item lg={3} alignItems="center" flexDirection="column" style={{ border: "1px solid #f0f0f2", margin: "20px" }}>
                                        <img alt="" src={Shoe} className="pricing_example_items_img3  rtl_text" />
                                        <p className="rtl_text center_text bold_text" > נעליים בקופסא</p>
                                    </Grid>
                                    <Grid container item lg={8} style={{ direction: "rtl", alignItems: "center", alignContent: "center" }}>
                                        <div style={{ width: "100%", fontSize: "16px" }}><b> { `עלות שילוח נעליים ידועה מראש! רק 17.9 דולר לזוג נעליים בקופסא מקורית!`}</b></div>

                                        <div style={{ width: "100%" }}> { `-   המחיר מתייחס לשילוח זוג נעליים אחד לנקודת איסוף. כל זוג נוסף יחוייב באותה עלות לקופסא.`}</div>

                                        <div style={{ width: "100%" }}> { `-   גודל קופסא מקסימלי: 3.5 ק"ג (נפחי או פיזי, הגבוה מביניהם), ללא אריזת מגן  המגדילה את הנפח. `}</div>

                                        <div style={{ width: "100%" }}> {`-   במידה וקיבלתם חיוב לסכום אחר בעבור קופסת נעליים, אנא פנו לשירות הלקוחות שלנו לצורך בירור.`}</div>
                                        <div style={{ width: "100%" }}> {`-   המחיר למשלוח נעליים אינו מתעדכן אוטומטית, לקבלת ההטבה יש ליצור קשר עם שרות הלקוחות לפני התשלום.`}</div>

                                    </Grid>
                                </Grid>
                                <Grid container className="homePage rightContentBelowPricing" flexDirection="row-reverse" sx={{ marginLeft: "-7%", direction: "rtl", marginTop: "25px", textWrap: "balance" }}>
                                    <Typography className='section-secTitle' component={'h3'} style={{ textAlign: "center", fontSize: "16px" }}>{ `מחירון - חשוב לדעת`}</Typography>
                                    <Typography component={'p'} className='section-descrition' sx={{ mb: '20px', lineBreak: 1.2 }} style={{ textAlign: "right", fontSize: "14px" }}>


                                        <ul class="">
                                            {/*<li>&nbsp;{``} </li>*/}

                                            <li>&nbsp;{`המחירים המוצגים בטבלת המחירון הינם מחירי המשלוח בלבד , ואינם כוללים מסים או אגרות.`} </li>
                                            <li>&nbsp;<b>{`חישוב עלות המשלוח יבוצע לפי משקל פיזי או נפחי - הגבוה מביניהם. אופן חישוב נפח החבילה: אורך*רוחב*גובה חלקי 5000.`} </b></li>
                                            <li>&nbsp;{`תוצאת המחשבון המפורט הינה הערכה. מחיר המשלוח הסופי יקבע על פי מידות ומשקל החבילה בפועל עם הגעתה למרכז הלוגיסטי.`} <br /><br />
                                                <b> {`שירותים נוספים:`}</b></li>
                                            <li>&nbsp;{`רדבוקס מציעה את השירותים הבאים בתוספת עלות:`}</li>
                                            <li>{`איחוד חבילות השירות כרוך בתשלום של 6$ עבור 2 החבילות הראשונות , ו-3.00 $ עבור כל חבילה נוספת.`}
                                                <br />
                                                {`איחוד הזמנה שפוצלה על ידי הספק תעשה ללא עלות, במקרה זה ינתן זיכוי לאחר פניה לשירות לקוחות.`}
                                            </li>
                                            <li> {`צמצום נפח החבילה יעשה ללא תוספת עלות עבור מוצרי אופנה,בכל מקרה אחר עלות השירות הינה 5$ עבור כל חבילה.`}</li>
                                            <li>&nbsp; {` צילום תוכן החבילה – השירות כרוך בתוספת תשלום של 4$ עבור כל חבילה.`}</li>
                                            <li>&nbsp; { `•	אחסון חבילה במחסני רדבוקס, הינו ללא עלות ב-21 הימים הראשונים, ולאחר מכן בעלות של 4$ לשבוע (7 ימים) או לחלק מהשבוע. חבילה שתמצא במחסנים באירופה/בארה"ב למעלה מ-60 ימים תוגדר כ"חבילה נטושה" ותימסר לתרומה.`}
                                                <br /><br />
                                                <b>{`מיסים ואגרות:`}</b>
                                            </li>
                                            <li>&nbsp;{`קנייה בעלות של 75$ ומעלה תחויב במע"מ של 17%, גם עבור המוצרים וגם עבור המשלוח מחו"ל לארץ.`} </li>
                                            <li>&nbsp;{`יש להצהיר על ערך המוצרים על פי הפירוט הבא:`}
                                                <ul class="">
                                                    <li>&nbsp;{`מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}</li>
                                                    <li>&nbsp;{`מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}</li>
                                                </ul>
                                            </li>

                                            <li>&nbsp;{`בשילוח מוצרים מעל 100$ חל חיוב נוסף של המדינה עבור אגרות רשימון, כמפורט:`}
                                                <ul class="">
                                                    <li>&nbsp;{`עד 100 דולר ...........................0 דולר`} </li>
                                                    <li>&nbsp;{`בין 100 דולר ל 500 דולר...........6 דולר`} </li>
                                                    <li>&nbsp;{`בין 500 דולר ל 1000 דולר ........20 דולר`} </li>
                                                    <li>&nbsp;{`מעל 1000 דולר .......................25 דולר`} </li>
                                                </ul>
                                            </li>
                                            <li>&nbsp;{`חישובי מכס, מע"מ ואגרות רשימון יבוצעו לפי הגדרות חוקיות היבוא של מכס ישראל.`} <br />
                                                <br /><b>{`תקנות מכס ואישורי יבוא:`}</b>
                                            </li>
                                            <li>&nbsp;{`ביבוא לארץ, יש להציג למכס חשבונית קנייה תקינה הכוללת את שם הספק, עלות המוצר `}<br />
                                                {`ותיאור המוצר. בהעדר חשבונית (חבילות שנשלחות ע"י קרובי משפחה או יד שניה וכו') יש `}<br />
                                                {`למלא`}&nbsp;<span><a target="_blank" href="/CustomsForm130.pdf">{`טופס מכס 130-יבוא אישי`}</a></span>&nbsp;{`ולהעלותו במקום החשבונית.`}
                                            </li>
                                            <li>&nbsp;{`במקרה של עיכוב בשחרור ממכס בשל מחסור בחשבונית, אישורי יבוא ו/או בדיקה פיזית של `}<br />
                                                {`המכס, התהליך יועבר לטיפול פרטני ויחולו עלויות עמלה 45 $ ואחסנה לפי חיובי מסוף `}<br />
                                                {`המטענים ודמי טיפול.`}
                                            </li>
                                            <li>&nbsp;{`ייבוא לישראל אינו מותר לקטינים, מתחת לגיל 18, על פי חוקי והנחיות המכס.`}<br />
                                                {`עמלה ואחסנה נוספות, במעמד התשלום יש להעלות, בנוסף לחשבונית, צילום תעודת זהות `}<br />
                                                {`של אחד ההורים `}&nbsp;<span><a target="_blank" href="/parentAproval.pdf">{`וייפוי כוח`}</a></span>&nbsp; {`מההורה.`}
                                            </li>
                                            <li>&nbsp;{`ביבוא מוצרי מזון יש למלא הצהרה עבור משרד הבריאות ולהעלותה יחד עם החשבונית. להורדת הטופס `} <span><a target="_blank" href="/affidavit.pdf">{`לחץ כאן .`}</a></span></li>
                                            
                                            <li>&nbsp;{`שחרור חלפים לרכב: לעיתים נדרשים אישורי תקינה ומשרד התחבורה. חלקם כולל החרגה לרכבי אספנות בלבד.`}</li>
                                            <li>&nbsp;{`פריטים האסורים בהטסה:`} <br />{`חומרים מסוכנים (דליקים, אלכוהול, נפצים וכו') וסוללות ליתיום אסורים להטסה. לחץ כאן לרשימת החומרים האסורים להטסה`}
                                                <br /><br /> <b>{`הפצת החבילות בארץ:`}</b></li>
                                            <li>&nbsp;{`חבילות גדולות ישלחו עם שליח עד הבית בלבד, כמפורט:`} </li>
                                            <li>&nbsp;{`חבילות במשקל פיזי או נפחי מעל 4 ק"ג ישלחו עם שליח ללא תוספת עלות`}</li>
                                            <li>{`חבילה שמידותיה עולות על 30*30*40 ס"מ תשלח עם שליח עד הבית ללא תוספת עלות`}</li>
                                            <li>{`חבילה אשר אחת מצלעותיה עולה על 40 ס"מ תחוייב במשלוח עד הבית. במידה ואינה עונה`}
                                                <br />{`על אחד מהתנאים הקודמים המשלוח יחוייב בתוספת של 4$`}</li>
                                            <li>{`ניתן לבחור גם עבור חבילות קטנות באופציה של משלוח עד הבית. השירות כרוך בתוספת של 4$.`}</li>
                                            <li>{`בחבילה שמידותיה חריגות (אחת מצלעות החבילה מעל 110 ס"מ) תתווסף עלות משלוח של 40$.`}</li>
                                            <li>{`חבילות שמידת אחת מצלעותיה עולה על  100 אינץ' (254 ס"מ) יש לפנות לשירות לקוחות לתמחור המשלוח.`}</li>
                                        </ul>
                                        <br />
                                        {/*<ul class="dashedW">
                                            <li>
                                                <b>{`REDBOX מחירי השילוח הזולים במדינה!`}</b>
                                            </li>
                                        </ul>*/}

                                    </Typography>

                                </Grid>
                                {/*<Box component={'section'} className="faq-section1" style={{ padding: '30px 0' }} >
                                    <Box className="container">
                                        <Grid container item sm={11.3} className="shadow_box" direction="rtl" style={{ padding: '0px 24px' }}>
                                            <Accordion className='accordion' expanded={expanded === '1'} onChange={handleChange('1')} style={{ width: "100%" }}>
                                                <AccordionSummary
                                                    className='accordion-summary'
                                                    expandIcon={expanded === '1' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ margin: "0 12px" }}
                                                >
                                                    <Typography className='dir-rtl' >{`שירות אריזה מחדש `}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className='accordion-details'>
                                                    <Box className='dir-rtl accordion-content'>
                                                        <Typography className='dir-rtl'>
                                                            {`לאחר קבלת הודעה על קליטת החבילה במחסן ונראה כי האריזה גדולה מהצפוי ניתן לבקש`}<br></br>
                                                            {`בפניה ל info@redboxparcel.com `}<br></br>
                                                            {`ניתן לבקש שירות אריזה מחדש במקרים בהם נפח החבילה גדול משמעותית ממשקלה הפיזי.`}<br></br>
                                                            {`השירות זמין לחבילות ממשקל 1 ק"ג לפחות (משקל פיזי או נפחי) עלות השירות 5$, חשוב לציין שאת `}<br></br>
                                                            {`המוצרים אנו לא מוציאים מאריזות המקור.`}<br></br>
                                                            {`לאחר ביצוע הפעולה אנחנו נשלח אליכם עדכון במייל שהחבילה מוכנה לתשלום`}<br></br>
                                                        </Typography>
                                                        */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion className='accordion' expanded={expanded === '2'} onChange={handleChange('2')} style={{ width: "100%" }}>
                                                <AccordionSummary
                                                    className='accordion-summary'
                                                    expandIcon={expanded === '2' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                                    aria-controls="panel2bh-content"
                                                    id="panel2bh-header"
                                                    style={{ margin: "0 12px" }}
                                                >
                                                    <Typography className='dir-rtl'>{`צילום תכולת חבילה`}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className='accordion-details'>
                                                    <Box className='dir-rtl accordion-content'>
                                                        <Typography className='dir-rtl'>
                                                            {`שירות צילום חבילה נועד בין היתר למקרים בהם:`}<br></br>
                                                            <ul class="">
                                                                <li>&nbsp;{`אין התאמה בין מספר המעקב ששלח הספק למספר המעקב של החבילה שנקלטה `} </li>
                                                                <li>&nbsp;{`בדיקה של פריט מסוים - יש לציין בפני שירות הלקוחות בדיוק מה נדרש לצלם.`} </li>

                                                            </ul>
                                                            {`עלות השירות 5$ לתמונה`}
                                                            {`לביצוע הפעולה אנא פנו במייל לצוות שירות הלקוחות למייל :info@redboxparcel.com `}


                                                        </Typography>
                                                        */}{/* <Link className='link-underline' to={'#'}>{`לכל הפרטים של איך עובד השירות`}</Link>*/}{/*
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Box>
                                </Box>*/}
                            </div>
                            {!isMobile() && <div className="pricing_block_A_3">
                                <Grid container style={{ height: '100%', padding: '24px 0' }} alignItems="flex-start">
                                    <Grid container className="pricing_block_A_3_container" alignItems="flex-start">
                                        {(discount && discount != "" && Number(discount) != 0) ?
                                            < p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">{`מחיר מבצע`}</p> :
                                            < p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">משלוח עד הבית</p>

                                        }
                                        <p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">משלוח לנקודת איסוף</p>
                                        <p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">משקל </p>
                                    </Grid>
                                    {/*//susheel*/}
                                    <Grid container className="pricing_block_A_3_container" alignItems="center">
                                        <p className="rtl_text bold_text center_text small_text zero_margin">21-28 יום (זמני)</p>
                                        <p className="rtl_text bold_text center_text small_text zero_margin">21-28 יום (זמני)</p>
                                        <p className="rtl_text bold_text center_text zero_margin top_space_40 table_head">{`משקל פיזי/נפחי`} <br /> {`הגבוה מבין השניים`} </p>
                                    </Grid>
                                    <Grid container style={{ maxHeight: "50vh", overflowY: "auto" }} alignItems="flex-start">
                                        {wtAndVals.map((wV, index) => <Grid container className="pricing_block_A_3_container">
                                            {(wV.wt != 0.3 && (Number(wV.wt) ? wV.wt : getFloatValue(wV)) != 0.4) && <>
                                                {(discount && discount != "" && Number(discount) != 0) ?
                                                    <p> {Number((wV.val * (100 - Number(discount)) / 100)).toFixed(2) + ' $ '} </p> :
                                                    <p> {Number(wV.wt) <= 4 ? (wV.val + 4) + ' $ ' : (wV.val) + ' $ '} </p>

                                                }
                                                
                                                <p> {Number(wV.wt) <= 4 ? (wV.val + '$') : "-"} </p>
                                                <p>עד {Number(wV.wt) ? wV.wt : getFloatValue(wV)} ק"ג</p></>}
                                        </Grid>)}
                                    </Grid>

                                    <Grid container style={{ alignItems: 'flex-end' }}>
                                        <Grid items container lg={4.5} justifyContent="center">
                                            <div className="red_button white_text top_space_20" onClick={function () {
                                                //showPricingBlockB()
                                                if (userInfo.session) {
                                                    setActiveScreenInreg('PersonalData');
                                                } else {
                                                    setActiveScreenInreg('Registeration');
                                                }
                                                setIsRegister(true);
                                            }}><p>לקבלת כתובת בחינם</p></div>
                                        </Grid>
                                        <Grid items container lg={3.5} justifyContent="center">
                                            <div className="whiteoutline_button top_space_20" style={{ textAlign: "center" }} onClick={() => {
                                                showPricingBlockB()
                                            }}><p>חישוב מפורט</p></div>
                                        </Grid>
                                        {wtAndVals.length <= 7 ?
                                            <Grid items container lg={4} justifyContent="center">
                                                <div className="whiteoutline_button top_space_20" onClick={() => {
                                                    var t = []
                                                    selectedWh?.wtNval.map((wv, index) => {
                                                        t.push(wv)
                                                    })
                                                    setWtAndVals(t)
                                                }}><p>לכל המשקלים</p></div>
                                            </Grid> :
                                            <Grid items container lg={4} justifyContent="center">
                                                <div style={{ textAlign: "center" }} className="whiteoutline_button top_space_20" onClick={() => {
                                                    var t = []
                                                    selectedWh?.wtNval.map((wv, index) => {
                                                        if (index < 7) {
                                                            t.push(wv)
                                                        }
                                                    })
                                                    setWtAndVals(t)
                                                }}><p>משקלים סטנדרטים</p></div>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </div>}
                        </Grid>


                    </div>

                    <div className="pricing_block full_width_container selected_show" id="pricing_block_B">
                        <div className="full_width_container center_text rtl_text top_space_40">
                            <span className="underscored_text cursor_pointer mid_text" onClick={function () {
                                showPricingBlockA()
                            }}>חישוב מהיר</span> &nbsp;&nbsp;&nbsp;
                            <span className="underscored_text darkgrey_text bold_text mid_text">חישוב מפורט</span>
                        </div>
                        <div className="pricing_block_B_content">
                            {/*< div className="pricing_block_B_search">
                                <img alt="" src={search} className="pricing_search_icon" style={{ float: "left" }} />
                                <span className="rtl_text darkgrey_text small_text" style={{ float: "right", marginRight: "20px", marginTop: "10px" }}>חפשו מוצר או קטגוריה</span>
                            </div>*/}
                            <p className="rtl_text bold_text darkgrey_text center_text top_space_40 midx_text">או בחרו קטגוריה</p>

                            <div className="full_width_container col_flex bot_space_40 top_space_40" >
                                <Grid container item lg={12} className='label-wrapper' alignItems="center" justifyContent="center">
                                    {categories?.map((val, ind) => <>
                                        {val.name !== 'רכב ואביזרים' && <Grid onClick={function () {
                                            if (val.name === 'רכב ואביזרים') {
                                                dispatch(snackbarToggle({ type: 'error', message: 'נא לפנות לשרות לקוחות לקבלת תמחור וחוקיות יבוא' }));
                                                return;
                                            }

                                            priorShowPricingBlockC(val); showPricingBlockC(val.id);
                                        }} key={uuidv4()} className="img_text_container" >
                                            {
                                                <Fragment>

                                                    <img alt="" src={getImageByName(val.name)} className="cursor_pointer img_icon" />
                                                    <p className="rtl_text center_text cursor_pointer" >{`${val.name}>`}</p>
                                                </Fragment>
                                            }
                                            {/*{val.id % categories?.length == 1 &&
                                                <Fragment>
                                                    <img alt="" src={Idea} className="cursor_pointer img_icon" />
                                                    <p className="rtl_text center_text cursor_pointer" >{val.name}  {'>'}</p>
                                                </Fragment>
                                            }
                                            {val.id % categories?.length == 2 &&
                                                <Fragment>
                                                    <img alt="" src={Laptop} className="cursor_pointer img_icon" />
                                                    <p className="rtl_text center_text cursor_pointer" >{val.name} {'>'}</p>
                                                </Fragment>
                                            }
                                            {val.id % categories?.length == 3 &&
                                                <Fragment>
                                                    <img alt="" src={Lotion} className="cursor_pointer img_icon" />
                                                    <p className="rtl_text center_text cursor_pointer" >{val.name} {'>'}</p>
                                                </Fragment>
                                            }
                                            {val.id % categories?.length == 4 &&
                                                <Fragment>
                                                    <img alt="" src={Mannequin} className="cursor_pointer img_icon" />
                                                    <p className="rtl_text center_text cursor_pointer" >{val.name} {'>'}</p>
                                                </Fragment>
                                            }*/}
                                        </Grid>}</>
                                    )}

                                </Grid>

                            </div>
                        </div>
                    </div>

                    <div className="pricing_block full_width_container selected_show" id="pricing_block_C">
                        <div className="full_width_container center_text rtl_text top_space_40">
                            <span className="underscored_text cursor_pointer mid_text" onClick={function () {
                                showPricingBlockA()
                            }} >חישוב מהיר</span> &nbsp;&nbsp;&nbsp;
                            <span className="underscored_text darkgrey_text bold_text mid_text">חישוב מפורט</span>
                        </div>
                        <div className="pricing_block_B_content">

                            <div className="row_flex row_spread_full full_width_container top_space_40 padd" >
                                <span></span>
                                <span className="rtl_text bold_text darkgrey_text center_text smallx_text"> קטגוריה:{categoryName?.name}</span>
                                <span className="rtl_text red_text right_text smallx_text bold_text cursor_pointer" onClick={function () {
                                    showPricingBlockB();

                                }} >
                                    חזור
                                </span>
                            </div>
                            <div className="col_flex full_width_container bot_space_20">
                                <Grid container item lg={12} className='label-wrapper' justifyContent="center">
                                    {subcategories.map((sub, index) =>
                                        <Grid key={uuidv4()} className="img_text_container red_text" onClick={function () {

                                            setValue(prev => ({ ...prev, subCategory: index + 1, price: 0, weight: 0, pLength: 0, pWidth: 0, pHeight: 0 }));
                                            showPricingBlockD()
                                        }} sx={{ border: "1px solid #f0f0f2", m: '18px', borderRadius: '5px' }}>{sub.name} </Grid>
                                    )}
                                </Grid>
                                {
                                    /*
                                    <div className="row_flex row_spread_full full_width_container">
                                    <div className="rtl_text col_flex pricing_block_C_items smallx_text" onClick={function () {
                                        showPricingBlockD()
                                    }}>שלט רחוק</div>
                                    <div className="rtl_text col_flex pricing_block_C_items smallx_text" onClick={function () {
                                        showPricingBlockD()
                                    }}>רשמקול</div>
                                    <div className="rtl_text col_flex pricing_block_C_items smallx_text" onClick={function () {
                                        showPricingBlockD()
                                    }}>רדיו</div>
                                    <div className="rtl_text col_flex pricing_block_C_items smallx_text" onClick={function () {
                                        showPricingBlockD()
                                    }}>קלטות אודיו ריקות</div>
                                    <div className="rtl_text col_flex pricing_block_C_items smallx_text" onClick={function () {
                                        showPricingBlockD()
                                    }}>רמקולים</div>
                                    <div className="rtl_text col_flex pricing_block_C_items smallx_text" onClick={function () {
                                        showPricingBlockD()
                                    }}>מקרן וידיאו</div>
                                </div>
                                */
                                }
                            </div>
                            {/* <div className="row_flex row_space_center full_width_container bot_space_40 cursor_pointer">
                                <img alt="" src={down_arrow_red} className="down_arrow_sign" />
                                <span className="red_text rtl_text smallx_text bold_text">הצג עוד</span>
                            </div>*/
                            }
                        </div>
                    </div>

                    <div className="pricing_block full_width_container selected_show" id="pricing_block_D">
                        <div className="full_width_container center_text rtl_text top_space_40">
                            <span className="underscored_text cursor_pointer mid_text" onClick={function () {
                                showPricingBlockA()
                            }}>חישוב מהיר</span> &nbsp;&nbsp;&nbsp;
                            <span className="underscored_text darkgrey_text bold_text mid_text">חישוב מפורט</span>
                        </div>
                        <div className="pricing_block_B_content">
                            {/* <div className="pricing_block_B_search">
                                <img alt="" src={search} className="pricing_search_icon" style={{ float: "left" }} />
                                <span className="rtl_text darkgrey_text small_text" style={{ float: "right", marginRight: "20px", marginTop: "10px" }}>חפשו מוצר או קטגוריה</span>
                            </div>*/}
                            <div className="row_flex row_spread_full full_width_container top_space_40 padding_10_px">
                                <span></span>
                                <span className="rtl_text bold_text darkgrey_text center_text smallx_text">קטגוריה: {categoryName?.name}</span>
                                <span className="rtl_text red_text right_text smallx_text bold_text cursor_pointer" onClick={function () {
                                    showPricingBlockC()
                                }}>
                                    חזור
                                </span>
                            </div>

                            <Grid container item lg={12} justifyContent="center" className='label-wrapper'>
                                {subcategories.map((sub, index) =>
                                    <Grid key={uuidv4()} className="img_text_container red_text" id={index == subcategories.length - 1 ? "productDetails" : ""} onClick={function () {
                                        setValue(prev => ({ ...prev, subCategory: index + 1 }));
                                        showPricingBlockD()
                                    }} sx={{ m: '18px', borderRadius: '5px', color: index + 1 == value.subCategory ? '#0f587b' : '', border: index + 1 === value.subCategory ? '1px solid #0f587b' : '1px solid #f0f0f2', fontWeight: index + 1 == value.subCategory ? 'bold' : '' }}>{sub.name}</Grid>
                                )}
                            </Grid>
                            {/* id="productDetails"  */}
                            {/* <p className="bold_text red_text full_width_container top_space_20 bot_border right_text rtl_text smallx_text margin_10_px">מוצרים פופולאריים בקטגוריה</p>*/}
                            <Grid container className="rtl_text full_width_container zero_margin right_text margin_right_10 selected_show" id="pricing_block_D1">
                                { /* <div className="full_width_container black_text">
                                    <p className="float_right pricing_block_D_items underscored_text">אוזניות Galaxy Buds</p>
                                    <p className="float_right pricing_block_D_items underscored_text">אוזניות AirPods Apple</p>
                                    <p className="float_right pricing_block_D_items underscored_text">אוזניות Beats Solo3</p>
                                    <p className="float_right pricing_block_D_items underscored_text">אוזניות JBL Tune</p>
                                    <p className="float_right pricing_block_D_items underscored_text">רמקול נייד Sony</p>
                                    <p className="float_right pricing_block_D_items underscored_text">רמקול נייד Sonos</p>
                                    <p className="float_right pricing_block_D_items underscored_text">רמקול נייד JBL PartyBox</p>
                                    <p className="float_right pricing_block_D_items underscored_text">רמקול נייד Sony SRS</p>
                                </div>*/}
                                <Typography className="full_width_container bold_text red_text top_space_20 bot_border right_text smallx_text margin_10_px">הזינו את פרטי המוצר</Typography>

                                <Typography className="full_width_container right_text dir-rtl bold_text form-text">עלות המוצר</Typography>

                                <div className="price_form_data">

                                    <Box className="full_width_container row" style={{ display: "flex" }}>



                                        <Box className='col-md-3 col-sm-6 col-xs-6'>
                                            <TextField
                                                className='text-field short'
                                                value={value.price}
                                                onChange={event => setValue(prev => ({ ...prev, price: event.target.value }))}
                                                onBlur={event => setValue(prev => ({ ...prev, price: Number(value.price) }))}
                                                label="עלות"
                                                type="number"
                                                dir='rtl'
                                            />
                                        </Box>
                                        <Box className='col-md-3  col-sm-6 col-xs-6'>
                                            <SelectField
                                                className='text-field_big select'
                                                required
                                                value={selectedCur}
                                                lg={12}
                                                isNumeric={true}
                                                onChange={event => {
                                                    setSelectedCur(event.target.value);

                                                }}
                                                options={currList}
                                                islabelAnimate={false}
                                                parentClassName={'full_width_container'}
                                                label="Currency"
                                                dir='rtl'
                                            />
                                        </Box>
                                        <Box className='col-md-3 col-sm-6 col-xs-6'>
                                            <TextField
                                                className='text-field short'
                                                value={value.weight}
                                                onChange={event => setValue(prev => ({ ...prev, weight: event.target.value }))}
                                                label="משקל"
                                                type="number"
                                                dir='rtl'
                                            />
                                        </Box>
                                        <Box className='col-md-3 col-sm-6 col-xs-6 radio_btn'>
                                            <Button className={!isLb ? 'btn-radio active' : 'btn-radio'} onClick={() => setIsLb(!isLb)}>
                                                KG
                                            </Button>
                                            <Button className={isLb ? 'btn-radio active' : 'btn-radio'} onClick={() => setIsLb(!isLb)}>
                                                LB
                                            </Button>
                                        </Box>
                                    </Box >


                                    <Typography className="full_width_container rtl_text right_text bold_text form-text">מידות המוצר</Typography>

                                    {false && <Typography className="full_width_container center_text small_text top_space_20">לא בטוחים כמה המוצר שוקל? משקלי מוצרים פופולאריים</Typography>}

                                    <Box className="full_width_container row" style={{ display: "flex" }}>



                                        <Box className='col-md-3 col-sm-6 col-xs-6'>
                                            <TextField
                                                lg={4}
                                                className='text-field'
                                                value={value.pLength}
                                                onChange={event => setValue(prev => ({ ...prev, pLength: event.target.value }))}
                                                label="אורך"
                                                type="number"
                                                onBlur={event => setValue(prev => ({ ...prev, pLength: Number(value.pLength) }))}
                                                dir='rtl'
                                            />
                                        </Box>
                                        <Box className='col-md-3 col-sm-6 col-xs-6'>
                                            <TextField
                                                className='text-field'
                                                value={value.pWidth}
                                                onChange={event => setValue(prev => ({ ...prev, pWidth: event.target.value }))}
                                                label="רוחב"
                                                type="number"
                                                onBlur={event => setValue(prev => ({ ...prev, pWidth: Number(value.pWidth) }))}
                                                dir='rtl'
                                            />
                                        </Box>
                                        <Box className='col-md-3 col-sm-6 col-xs-6'>
                                            <TextField
                                                className='text-field'
                                                value={value.pHeight}
                                                onChange={event => setValue(prev => ({ ...prev, pHeight: event.target.value }))}
                                                label="גובה"
                                                type="number"
                                                onBlur={event => setValue(prev => ({ ...prev, pHeight: Number(value.pHeight) }))}
                                                dir='rtl'
                                            />
                                        </Box>
                                        <Box className='col-md-3 col-sm-6 col-xs-6 radio_btn'>
                                            <Button className={!isIN ? 'btn-radio active' : 'btn-radio'} onClick={() => setIsIN(!isIN)}>
                                                CM
                                            </Button>
                                            <Button className={isIN ? 'btn-radio active' : 'btn-radio'} onClick={() => setIsIN(!isIN)}>
                                                IN
                                            </Button>
                                        </Box>

                                    </Box>

                                    <div className="full_width_container row_flex row_space_center">
                                        {(Number(value.weight) > weightAndVal[weightAndVal.length - 1]?.wt || (Number(value.price) > 5000) || ((Number(value.pWidth) > 250 || Number(value.pLength) > 250 || Number(value.pHeight) > 250))) && <div className="row_flex row_space_center small_text cursor_pointer" style={{ width: "50%", direction: "rtl", color: "blue", textDecoration: "underline" }} onClick={() => { navigate("/contactUs") }}>
                                            { `אנא צרו קשר עם שרות לקוחות`}
                                        </div>}
                                    </div>
                                    <div className="full_width_container row_flex row_space_center">

                                        <div className="darkgreen_button row_flex row_space_center white_text small_text cursor_pointer" style={{ background: Number(value.weight) > weightAndVal[weightAndVal.length - 1]?.wt ? 'gray' : '' }} onClick={function () {


                                            if (!Number(value.price)) {
                                                dispatch(snackbarToggle({ type: 'error', message: `נא להזין מחיר.` }));
                                                return;
                                            }
                                            if (Number(value.price) > 5000) {
                                                dispatch(snackbarToggle({ type: 'error', message: `אנא צרו קשר עם שרות לקוחות` }));
                                                return;
                                            }
                                            if (!value.weight) {
                                                dispatch(snackbarToggle({ type: 'error', message: `נא להזין משקל` }));
                                                return;
                                            } else {
                                                if (weightAndVal[weightAndVal.length - 1]?.wt && value.weight > weightAndVal[weightAndVal.length - 1]?.wt) {
                                                    dispatch(snackbarToggle({ type: 'error', message: `אנא צרו קשר עם שרות לקוחות` }));
                                                    return;
                                                }
                                            }
                                            if (!value.pLength) {
                                                dispatch(snackbarToggle({ type: 'error', message: `נא להזין אורך` }));
                                                return;
                                            }
                                            if (!value.pWidth) {
                                                dispatch(snackbarToggle({ type: 'error', message: `נא להזין רוחב` }));
                                                return;
                                            }
                                            if (!value.pHeight) {
                                                dispatch(snackbarToggle({ type: 'error', message: `נא להזין גובה` }));
                                                return;
                                            }
                                            if (((Number(value.pWidth) > 250 || Number(value.pLength) > 250 || Number(value.pHeight) > 250))) {
                                                dispatch(snackbarToggle({ type: 'error', message: `אנא צרו קשר עם שרות לקוחות` }));
                                                return;
                                            }
                                            var price = Number(value.price)
                                            if (selectedCur == 2) {
                                                price = Number(value.price) * exchangeRateAmt

                                            }


                                            var wt = Number(value.weight)
                                            if (isLb) {
                                                wt = wt / 2.2
                                            }
                                            var h = Number(value.pHeight)
                                            var l = Number(value.pLength)
                                            var w = Number(value.pWidth)
                                            if (isIN) {
                                                h = h * 2.54;
                                                l = l * 2.54;
                                                w = w * 2.54;

                                            }
                                            var ind = value.subCategory - 1;
                                            calculateValues(h, l, w, wt, weightAndVal, ind, subcategories3, subcategories4, [], price, 0, setValue, 0, 0, 0, shipmentCost1?.price);
                                            console.log(value)
                                            setValue(prev => ({ ...prev, homeDelivary: checkWeight(value.pHeight, value.pLength, value.pWidth, value.weight) }))

                                            //togglePricingDetails()
                                            /* var line66 = false;
                                            
                                            var chargWt = Number(h) * Number(l) * Number(w) / 5000;

                                            var twt = chargWt > wt ? chargWt : wt;
                                            twt = Number(twt).toFixed(1) < 1 ? 1 : (Number(twt).toFixed(1) > 26.9 ? 26.9 : Number(twt).toFixed(1));
                                            var transportVal = 0;
                                            weightAndVal.forEach((weightVal) => {
                                                if (weightVal.wt == twt ) {
                                                    transportVal = weightVal.val;
                                                }
                                                if (!weightVal.wt && twt ==1) {
                                                    transportVal = weightVal.val;
                                                }
                                            })
                                            if (subcategories5.length) {
                                                line66 = true;
                                            }
                                            var cif = Number(transportVal) + Number(price);
                                            var cCharge = 0;
                                            var cifX = 0;
                                            var pTax = 0;
                                            var pTotal = cif;
                                            if (Number(price)>75) {
                                                cCharge = cif * Number(subcategories3[value.subCategory - 1]) / 100;
                                                cifX = cif * (1 + Number(subcategories3[value.subCategory - 1]) / 100);
                                                if (line66) {
                                                    cifX *= 1.6;
                                                }
                                                pTax = cifX * Number(subcategories4[value.subCategory - 1]) / 100;
                                                pTotal = cifX * (1 + Number(subcategories4[value.subCategory - 1]) / 100);
                                            }
                                            // 
                                            var vat = pTotal * 0.17;
                                            var addCharges = 0;
                                            if (line66) {
                                                addCharges = 75;
                                            } else {
                                                if (Number(price) > 75 && Number(price) < 500) {
                                                    addCharges = 6;
                                                } else if (Number(price) >= 500 && Number(price) < 1000) {
                                                    addCharges = 20;
                                                } else if (Number(price) >= 1000) {
                                                    addCharges = 75;
                                                }
                                            }
                                            if (Number(h) > 120 || Number(l) > 120 || Number(w) > 120) {
                                                addCharges = addCharges + 35;
                                            }
                                           
                                            var insurence = (Number(transportVal) * 3 / 100) < 2 ? 2 : (Number(transportVal) * 3 / 100);
                                            


                                            var totalInvoice = Number(Number(transportVal).toFixed(1)) + Number(Number(pTax).toFixed(1)) + Number(Number(cCharge).toFixed(1)) + Number(Number(vat).toFixed(1)) + Number(Number(addCharges).toFixed(1));

                                            setValue(prev => ({ ...prev, price1: price }));
                                            setValue(prev => ({ ...prev, transportVal: transportVal }));
                                            setValue(prev => ({ ...prev, totalInvoice: totalInvoice }));
                                            setValue(prev => ({ ...prev, cif: cif }));
                                            setValue(prev => ({ ...prev, customsCharge: cCharge }));
                                            setValue(prev => ({ ...prev, vat: vat }));
                                            setValue(prev => ({ ...prev, purchaseTax: pTax }));
                                            setValue(prev => ({ ...prev, insurence: insurence }));
                                            setValue(prev => ({ ...prev, addCharges: addCharges }));*/
                                            showPricingBlockE()
                                        }} ><p>חשבו לי</p></div>
                                    </div>
                                </div >
                            </Grid >

                            <div className="full_width_container margin_right_10 pricing_block_E" id="pricing_block_E">
                                {/*<div className="full_width_container small_text rtl_text" style={{ height: "40px" }}>
                                    <div className="float_left whiteoutline_button bold_text"><p>הוספת מוצר נוסף למשלוח</p></div>
                                    <div className="float_left whiteoutline_button bold_text"><p>איפוס תוצאות</p></div>
                                    {false && <div className="float_right right_text bold_text" style={{ marginTop: "10px" }} >עבור אוזניות Galaxy Buds מארה"ב:</div>}
                                </div>*/}
                                <div className="full_width_container rtl_text white_text zero_margin" style={{ height: "10px" }}></div>
                                <div className="full_width_container darkgreen_gb rtl_text white_text zero_margin" style={{ height: "40px", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} >
                                    <div className="float_left bold_text margin_8_px">{getTotal()} $</div>
                                    <div className="float_right right_text bold_text margin_8_px"><span className="bold_text">עלות המשלוח ומיסים</span></div>
                                </div>
                                <div className="zero_margin full_width_container  dir-rtl" style={{ background: "#f0f0f2", display: 'flex', padding: '15px 0', flexFlow: 'wrap' }} id="pricing_details_div">
                                    <div className="col-md-6 col-sm-12 col-xs-12" >
                                        <Box style={{ background: "white", height: '100%' }}>
                                            <div className="rtl_text bold_text padding_10_px">פרטי המשלוח</div>
                                            <div className="rtl_text bold_text font_0_7 padding_10_px">סוג משלוח</div>
                                            {/* <div className="full_width_container row_flex row_space_center padding_10_px">
                                                <div className="whiteoutline_button force_border_5 zero_margin font_0_7 center_text"
                                                 onClick={() => setValue(prev => ({ ...prev, homeDelivary: false }))} 
                                                 style={{ background: value.homeDelivary ? "#f0f0f2" : '', fontWeight: !value.homeDelivary ? "bold" : '' }}></div>
                                                <div className="whiteoutline_button  force_border_5 zero_margin center_text font_0_7" onClick={() => setValue(prev => ({ ...prev, homeDelivary: true }))} style={{ background: !value.homeDelivary ? "#f0f0f2" : '', fontWeight: value.homeDelivary ? "bold" : '' }}><p>משלוח עד הבית</p></div>

                                            </div> */}
                                            <Box className='radio_btn padding_10_px'>
                                                <Button disabled={checkWeight(value.pHeight, value.pLength, value.pWidth, value.weight)} className={!value.homeDelivary ? 'btn-radio active' : 'btn-radio'} onClick={() => setValue(prev => ({ ...prev, homeDelivary: false }))}>
                                                    {`משלוח לנקודת איסוף`}
                                                </Button>
                                                <Button className={value.homeDelivary ? 'btn-radio active' : 'btn-radio'} onClick={() => setValue(prev => ({ ...prev, homeDelivary: true }))}>
                                                    {`משלוח עד הבית`}
                                                </Button>
                                            </Box>
                                            <div className="rtl_text bold_text font_0_7 padding_10_px">ביטוח משלוח</div>
                                            {/*<div className="rtl_text bold_text font_0_7 padding_10_px">חבילות בערך כולל העולה על  250$ חייבות בביטוח משלוח</div>*/}
                                            {/* <div className="full_width_container row_flex row_space_center padding_10_px">
                                                <div className="whiteoutline_button force_border_5 zero_margin font_0_7 center_text" onClick={() => setValue(prev => ({ ...prev, isInsurence: false }))} style={{ background: value.isInsurence ? "#f0f0f2" : '', fontWeight: !value.isInsurence ? "bold" : '' }}><p>ללא</p></div>
                                                <div className="whiteoutline_button force_border_5 zero_margin center_text font_0_7" onClick={() => setValue(prev => ({ ...prev, isInsurence: true }))} style={{ background: !value.isInsurence ? "#f0f0f2" : '', fontWeight: value.isInsurence ? "bold" : '' }}><p>כולל</p></div>
                                            </div>  */}
                                            <Box className='radio_btn padding_10_px'>
                                                {/*disabled={value.price > 250}*/}
                                                <Button className={!value.isInsurence ? 'btn-radio active' : 'btn-radio'} onClick={() => {

                                                    setValue(prev => ({ ...prev, isInsurence: false }))
                                                }}>
                                                    {`ללא`}
                                                </Button>
                                                <Button className={value.isInsurence ? 'btn-radio active' : 'btn-radio'} onClick={() => {

                                                    setValue(prev => ({ ...prev, isInsurence: true }))
                                                }}>
                                                    {`כולל`}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <Box style={{ background: "white", height: '100%' }}>
                                            <table cellSpacing="0" cellPadding="0" className="price_block_table full_width_container rtl_text right_text" style={{ border: "none" }}>
                                                <tbody>

                                                    <tr style={{ border: "none" }}>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>מחיר המוצר</td>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>{Number(value.price1).toFixed(1)} $</td>
                                                    </tr>
                                                    <tr style={{ border: "none" }}>
                                                        <td className="black_text  font_0_7" style={{ border: "none" }}>משקל לחיוב</td>
                                                        <td className="black_text  font_0_7" style={{ border: "none", direction: "initial" }}>{getChargiableWeight(value.pHeight, value.pLength, value.pWidth, value.weight)} KG</td>
                                                    </tr>
                                                    {/*<tr style={{ border: "none" }}>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>עלויות שילוח ומיסים:</td>
                                                        <td style={{ border: "none" }}></td>
                                                    </tr>*/}
                                                    <tr style={{ border: "none" }}>
                                                        <td className=" font_0_7" style={{ border: "none", color: "#d70707", fontWeight: "bold" }}>מחיר משלוח</td>
                                                        <td className=" font_0_7" style={{ border: "none", color: "#d70707", fontWeight: "bold" }}>{Number(value.transportVal).toFixed(1)} $</td>
                                                    </tr>
                                                    {<tr style={{ border: "none" }}>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>משלוח עד הבית</td>
                                                        <td style={{ border: "none" }}>${value.homeDelivary ? getTotalHousedeliveryStatus()?4: 0 : 0}</td>
                                                    </tr>
                                                    }


                                                    <tr style={{ border: "none" }}>
                                                        <td className="black_text font_0_7" style={{ border: "none" }}>מע"מ , עמלת שחרור ואגרת מכס</td>
                                                        {/*<td className="black_text font_0_7" style={{ border: "none" }}>מע"מ , עמלת שחרור/משרד התחבורה ואגרת מכס</td>*/}
                                                        {/*<td className="black_text font_0_7" style={{ border: "none" }}>מע"מ</td>*/}
                                                        <td className="black_text font_0_7" style={{ border: "none" }}>{Number(Number(value.vat) + Number(value.customsCharge) + Number(value.purchaseTax) + Number(value.addCharges) + Number(value.fixedFee)).toFixed(1)} $</td>
                                                    </tr>
                                                    {/* <tr style={{ border: "none" }}>
                                                        <td className="black_text font_0_7" style={{ border: "none" }}>עמלת שחרור/משרד התחבורה ואגרת מכס</td>
                                                        <td className="black_text font_0_7" style={{ border: "none" }}>{Number(Number(value.purchaseTax) + Number(value.customsCharge) + Number(value.addCharges)).toFixed(1)} $</td>
                                                    </tr>*/}
                                                    {(Number(value.pHeight) > 110 || Number(value.pLength) > 110 || Number(value.pWidth) > 110) && <tr style={{ border: "none" }}>

                                                        <td className="black_text font_0_7" style={{ border: "none", fontWeight: "normal" }} colSpan={2}>מידות חריגות של אחת מצלעות החבילה (מעל 110 ס"מ) תתווסף עלות של 40$.</td>
                                                    </tr>}


                                                    <tr style={{ border: "none" }}>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>ביטוח</td>
                                                        <td style={{ border: "none" }}>${value.isInsurence ? Number(value.insurence).toFixed(1) : "0"}</td>
                                                    </tr>
                                                    {<tr>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>סה"כ שילוח ומיסים</td>
                                                        <td className="black_text bold_text font_0_7" style={{ border: "none" }}>{getTotal()} $</td>
                                                    </tr>/**/}
                                                </tbody>
                                            </table>
                                        </Box>
                                    </div>
                                </div>
                                {/*<div onClick={function () {
                                    togglePricingDetails()
                                }} className="full_width_container bot_space_20 small_text white_text rtl_text right_text" style={{ background: "#d70007", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", padding: "3px", fontSize: "14px", paddingRight: "15px", cursor: 'pointer' }}>{isTogglePrice ? '- סגור פירוט' : '+ פירוט'}</div>
                                */}{/*<div className="full_width_container darkgreen_gb rtl_text white_text zero_margin" style={{ height: "40px", borderRadius: "5px" }}>
                                    */}{/*<div className="float_left bold_text margin_8_px">{Number(Number(getTotal()) + Number(value.price1)).toFixed(1)} $</div>*/}{/*
                                    <div className="float_left bold_text margin_8_px">{Number(Number(getTotal()) ).toFixed(1)} $</div>

                                    <div className="float_right right_text bold_text margin_8_px">סה"כ שילוח ומיסים</div>
                                </div>*/}
                                <div className="full_width_container" style={{ direction: "rtl" }}>
                                    {/*<ul className="full_width_container rtl_text right_text small_text pricing_bullets black_text">
                                        <li>חישוב המחיר במחשבון כולל את מחיר המוצר , מיסים (במידה ונדרשים) ועלות השילוח לארץ, שחרור ממכס והפצה לנקודת מסירה או לבית הלקוח (לפי בחירה). </li>
                                        <li>תוצאת חישוב המחיר במחשבון, הינה הערכה בלבד. המחיר הסופי יקבע על פי מידות ומשקל החבילה לפי הנתונים בפועל לאחר הקבלה במרכז הלוגיסטי במדינת המקור.</li>
                                        <li>מידות חריגות של אחת מצלעות החבילה (מעל 120 ס"מ) תתווסף עלות של 35$.</li>
                                        <li>חישוב המיסים יבוצע לפי תקנות המכס הישראלי. חישוב המיסים יבוצע לפי הערך המצטבר של עלות הסחורה  ועלות השילוח.</li>
                                        <li>חבילות אשר ערכן גבוה מ1000 דולר, יידרשו לשחרור פרטני (לפי תקנות המכס). עמלת שחרור פרטני עבור חבילה הינה 40 דולר.</li>
                                        <li>אין לשלוח בשרות זה, חפצים ייקרי ערך (מעל 5000 $) , יצירות אומנות,תכישיטים אבני חן וכדומה או חפצים בעלי ערך סנטימנטלי ייחודי לבעלים.</li>
                                    </ul>*/}

                                    <Typography className='section-secTitle' component={'h3'} style={{ textAlign: "center", fontSize: "16px" }}>{`מחירון  - חשוב לדעת`}</Typography>
                                    <Typography component={'p'} className='section-descrition' sx={{ mb: '20px', lineBreak: 1.2 }} style={{ textAlign: "right", fontSize: "14px" }}>

                                        <ul class="">
                                            {/*<li>&nbsp;{``} </li>*/}

                                            <li>&nbsp;{`המחירים המוצגים בטבלת המחירון הינם מחירי המשלוח בלבד , ואינם כוללים מסים או אגרות.`} </li>
                                            <li>&nbsp;<b>{`חישוב עלות המשלוח יבוצע לפי משקל פיזי או נפחי - הגבוה מביניהם. אופן חישוב נפח החבילה: אורך*רוחב*גובה חלקי 5000.`} </b></li>
                                            <li>&nbsp;{`תוצאת המחשבון המפורט הינה הערכה. מחיר המשלוח הסופי יקבע על פי מידות ומשקל החבילה בפועל עם הגעתה למרכז הלוגיסטי.`} <br /><br />
                                                <b> {`שירותים נוספים:`}</b></li>
                                            <li>&nbsp;{`רדבוקס מציעה את השירותים הבאים בתוספת עלות:`}</li>
                                            <li>{`איחוד חבילות השירות כרוך בתשלום של 6$ עבור 2 החבילות הראשונות , ו-3.00 $ עבור כל חבילה נוספת.`}
                                                <br />
                                                {`איחוד הזמנה שפוצלה על ידי הספק תעשה ללא עלות, במקרה זה ינתן זיכוי לאחר פניה לשירות לקוחות.`}
                                            </li>
                                            <li> {`צמצום נפח החבילה יעשה ללא תוספת עלות עבור מוצרי אופנה,בכל מקרה אחר עלות השירות הינה 5$ עבור כל חבילה.`}</li>
                                            <li>&nbsp; {` צילום תוכן החבילה – השירות כרוך בתוספת תשלום של 4$ עבור כל חבילה.`}</li>
                                            <li>&nbsp; {`•	אחסון חבילה במחסני רדבוקס, הינו ללא עלות ב-21 הימים הראשונים, ולאחר מכן בעלות של 4$ לשבוע (7 ימים) או לחלק מהשבוע. חבילה שתמצא במחסנים באירופה/בארה"ב למעלה מ-60 ימים תוגדר כ"חבילה נטושה" ותימסר לתרומה.`}
                                                <br /><br />
                                                <b>{`מיסים ואגרות:`}</b>
                                            </li>
                                            <li>&nbsp;{`קנייה בעלות של 75$ ומעלה תחויב במע"מ של 17%, גם עבור המוצרים וגם עבור המשלוח מחו"ל לארץ.`} </li>
                                            <li>&nbsp;{`יש להצהיר על ערך המוצרים על פי הפירוט הבא:`}
                                                <ul class="">
                                                    <li>&nbsp;{`מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}</li>
                                                    <li>&nbsp;{`מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}</li>
                                                </ul>
                                            </li>

                                            <li>&nbsp;{`בשילוח מוצרים מעל 100$ חל חיוב נוסף של המדינה עבור אגרות רשימון, כמפורט:`}
                                                <ul class="">
                                                    <li>&nbsp;{`עד 100 דולר ...........................0 דולר`} </li>
                                                    <li>&nbsp;{`בין 100 דולר ל 500 דולר...........6 דולר`} </li>
                                                    <li>&nbsp;{`בין 500 דולר ל 1000 דולר ........20 דולר`} </li>
                                                    <li>&nbsp;{`מעל 1000 דולר .......................25 דולר`} </li>
                                                </ul>
                                            </li>
                                            <li>&nbsp;{`חישובי מכס, מע"מ ואגרות רשימון יבוצעו לפי הגדרות חוקיות היבוא של מכס ישראל.`} <br />
                                                <br /><b>{`תקנות מכס ואישורי יבוא:`}</b>
                                            </li>
                                            <li>&nbsp;{`ביבוא לארץ, יש להציג למכס חשבונית קנייה תקינה הכוללת את שם הספק, עלות המוצר `}<br />
                                                {`ותיאור המוצר. בהעדר חשבונית (חבילות שנשלחות ע"י קרובי משפחה או יד שניה וכו') יש `}<br />
                                                {`למלא`}&nbsp;<span><a target="_blank" href="/CustomsForm130.pdf">{`טופס מכס 130-יבוא אישי`}</a></span>&nbsp;{`ולהעלותו במקום החשבונית.`}
                                            </li>
                                            <li>&nbsp;{`במקרה של עיכוב בשחרור ממכס בשל מחסור בחשבונית, אישורי יבוא ו/או בדיקה פיזית של `}<br />
                                                {`המכס, התהליך יועבר לטיפול פרטני ויחולו עלויות עמלה 45 $ ואחסנה לפי חיובי מסוף `}<br />
                                                {`המטענים ודמי טיפול.`}
                                            </li>
                                            <li>&nbsp;{`ייבוא לישראל אינו מותר לקטינים, מתחת לגיל 18, על פי חוקי והנחיות המכס.`}<br />
                                                {`עמלה ואחסנה נוספות, במעמד התשלום יש להעלות, בנוסף לחשבונית, צילום תעודת זהות `}<br />
                                                {`של אחד ההורים `}&nbsp;<span><a target="_blank" href="/parentAproval.pdf">{`וייפוי כוח`}</a></span>&nbsp; {`מההורה.`}
                                            </li>
                                            <li>&nbsp;{`ביבוא מוצרי מזון יש למלא הצהרה עבור משרד הבריאות ולהעלותה יחד עם החשבונית. להורדת הטופס `} <span><a target="_blank" href="/affidavit.pdf">{`לחץ כאן .`}</a></span></li>

                                            <li>&nbsp;{`שחרור חלפים לרכב: לעיתים נדרשים אישורי תקינה ומשרד התחבורה. חלקם כולל החרגה לרכבי אספנות בלבד.`}</li>
                                            <li>&nbsp;{`פריטים האסורים בהטסה:`} <br />{`חומרים מסוכנים (דליקים, אלכוהול, נפצים וכו') וסוללות ליתיום אסורים להטסה. לחץ כאן לרשימת החומרים האסורים להטסה`}
                                                <br /><br /> <b>{`הפצת החבילות בארץ:`}</b></li>
                                            <li>&nbsp;{`חבילות גדולות ישלחו עם שליח עד הבית בלבד, כמפורט:`} </li>
                                            <li>&nbsp;{`חבילות במשקל פיזי או נפחי מעל 4 ק"ג ישלחו עם שליח ללא תוספת עלות`}</li>
                                            <li>{`חבילה שמידותיה עולות על 30*30*40 ס"מ תשלח עם שליח עד הבית ללא תוספת עלות`}</li>
                                            <li>{`חבילה אשר אחת מצלעותיה עולה על 40 ס"מ תחוייב במשלוח עד הבית. במידה ואינה עונה`}
                                                <br />{`על אחד מהתנאים הקודמים המשלוח יחוייב בתוספת של 4$`}</li>
                                            <li>{`ניתן לבחור גם עבור חבילות קטנות באופציה של משלוח עד הבית. השירות כרוך בתוספת של 4$.`}</li>
                                            <li>{`בחבילה שמידותיה חריגות (אחת מצלעות החבילה מעל 110 ס"מ) תתווסף עלות משלוח של 40$.`}</li>
                                            <li>{`חבילות שמידת אחת מצלעותיה עולה על  100 אינץ' (254 ס"מ) יש לפנות לשירות לקוחות לתמחור המשלוח.`}</li>
                                        </ul>
                                        <br />
                                        {/*<ul class="dashedW">
                                            <li>
                                                <b>{`REDBOX מחירי השילוח הזולים במדינה!`}</b>
                                            </li>
                                        </ul>*/}

                                    </Typography>
                                </div>
                            </div>

                            {/*<div className="col_flex zero_margin full_width_container">
                                <div className="row_flex full_width_container small_text rtl_text">
                                    <div className="col_flex pricing_block_C_items">שלט רחוק</div>
                                    <div className="col_flex pricing_block_C_items">רשמקול</div>
                                    <div className="col_flex pricing_block_C_items">רדיו</div>
                                    <div className="col_flex pricing_block_C_items">קלטות אודיו ריקות</div>
                                    <div className="col_flex pricing_block_C_items">רמקולים</div>
                                    <div className="col_flex pricing_block_C_items">מקרן וידיאו</div>
                                </div>
                            </div>
                            <div className="row_flex row_space_center full_width_container bot_space_40 cursor_pointer">
                                <img alt="" src={down_arrow_red} className="down_arrow_sign" />
                                <span className="red_text rtl_text smallx_text bold_text">הצג עוד</span>
                            </div>
                            */}
                        </div >
                    </div >
                </div >

                {/*<Box component={'section'} className="faq-section pt-sm-30" sx={{ padding: { sm: '50px 0 0', xs: '30px 0 0' } }}>
                    <Box className="container">
                        <Grid container className='box-wrapper'>
                            <Grid item sm={6} className='blue-rounded-box right-align'>
                                <img src={amazonLogo} alt='' />
                            </Grid>
                            <Grid item sm={8} className='darkblue-rounded-box shadow_box dir-rtl right-align'>
                                <Typography>רוצים לקנות מוצר מ- Amazon? לחישוב מהיר של מחיר המשלוח</Typography>
                                <Grid container className='actions_container'>
                                    <ActionButton textLabel={`לקבלת כתובת חינם בחו"ל`} className='underline-btn white_line' onClick={() => {
                                        setActiveScreenInreg("Registeration");
                                        setIsRegister(true);
                                    }} />
                                    <ActionButton textLabel={`חשבו לי`} className='underline-btn' onClick={() => {
                                        document.getElementById('main-content').scrollIntoView();
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>*/}

            </main >
        </Paper >
    );
}

export default RedboxPricing;